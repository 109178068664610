import React from "react"
import { useState } from "react"
import { Button, Input } from "../"
import styles from "./Footer.module.scss"
import { useHistory } from "react-router-dom"

const Footer = () => {
  const history = useHistory()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)

  const validateEmail = (input) => {
    if (/^\S+@\S+\.\S+$/.test(input)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }
  const subscribe = async () => {
    if (validEmail) {
      await fetch("https://formspree.io/f/myyqyvpg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
        }),
      })
    }
  }

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerConnect}>
          <h3>Connect with Us</h3>
          <a
            href="https://t.me/+-eyZj-pB7qMwZTdh"
            target="_blank"
            rel="noreferrer"
            className={styles.socialLink}
          >
            <img
              src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Telegram+XP+Glow+1.png"
              alt="Telegram"
            />
          </a>
          <a
            className={styles.socialLink}
            href="https://twitter.com/xpticket"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/twitter.png"
              alt="Twitter"
            />
          </a>
        </div>
        <div className={styles.footerLinkContainer}>
          <div
            className={styles.footerLink}
            onClick={() => {
              history.push("/events")
            }}
          >
            Find Tickets
          </div>
          <a
            href="https://xpsupport.zendesk.com"
            target="_blank"
            rel="noreferrer"
          >
            Help Center
          </a>
          <a href="http://tamperproof.xyz" target="_blank" rel="noreferrer">
            Tamperproof
          </a>
          <a href="http://gocaptain.com" target="_blank" rel="noreferrer">
            Captain Labs
          </a>
          <a
            href="https://xpsupport.zendesk.com/hc/en-us/requests/new"
            target="_blank"
            rel="noreferrer"
          >
            Contact Us
          </a>
          <a
            href="https://cdn.hngr.co/xptc.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
        </div>
        <div className={styles.footerLogoContainer}>
          <div className={styles.logo}>
            <img
              src={
                process.env.REACT_APP_SKIN == "ticketnetwork"
                  ? "https://cdn.hngr.co/tamperproof/goldcoast-powered-by-xp-logo.png"
                  : "https://hngr-icons.s3.amazonaws.com/ticketdex/XP+Logo+Footer+1.png"
              }
              alt="XP"
            />
            <a href="http://gocaptain.com">XP by Captain / © 2024</a>
          </div>
          <div className={styles.footerForm}>
            <h3>Join Us For Updates</h3>
            <div className={styles.inputContainer}>
              <div className={styles.label}>Name</div>
              <Input
                type="text"
                name="name"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.label}>Email</div>
              <Input
                type="text"
                name="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  validateEmail(e.target.value)
                }}
              />
            </div>
            <Button onClick={() => subscribe()} fullWidth variant="gray">
              Sign Up
            </Button>
            <p className={styles.disclaimer}>
              By signing up, you acknowledge and accept our privacy policy and
              consent to receiving emails.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
