import { useEffect } from "react"
import styles from "./HomeCarousels.module.scss"
import { useSelector, useDispatch } from "react-redux"
import { EventCarousel } from "../components"
import {
  getNearbyMusicEvents,
  getNearbySportsEvents,
  getFeaturedEvents
} from "../reducers/eventSlice"

const HomeCarousels = (props) => {
  const dispatch = useDispatch()

  const nearbyMusicEvents = useSelector(
    (state) => state.events.nearbyMusicEvents
  )
  const nearbySportsEvents = useSelector(
    (state) => state.events.nearbySportsEvents
  )

  const featuredEvents = useSelector((state) => state.events.featuredEvents)

  useEffect(() => {
    dispatch(getNearbyMusicEvents({ nearby: true, event_type: "concert" }))
    dispatch(getNearbySportsEvents({ nearby: true, event_type: "sport" }))
    dispatch(getFeaturedEvents({ featured: true }))
  }, []) // eslint-disable-line

  // now we do the featured
  return (
    <div className={styles.container}>
      {featuredEvents?.length > 0 && (
        <EventCarousel
          title={"Our Top Picks"}
          events={featuredEvents}
          link="/events?featured=true&nearby=true"
          buttonCta="Browse Featured"
        />
      )}
      {nearbyMusicEvents?.length > 0 && (
        <EventCarousel
          title={"Music"}
          events={nearbyMusicEvents}
          link="/events?eventType=concerts&nearby=true"
          buttonCta="Browse Concerts"
        />
      )}
      {nearbySportsEvents?.length > 0 && (
        <EventCarousel
          title={"Sports"}
          events={nearbySportsEvents}
          link="/events?eventType=sports&nearby=true"
          buttonCta="Browse Sports"
        />
      )}
    </div>
  )
}

export default HomeCarousels
