import classNames from "classnames"
import { useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useWallet } from "@solana/wallet-adapter-react"
import LogRocket from "logrocket"
import styles from "./Header.module.scss"
import Search from "../Search/Search"
import Button from "../Button/Button"
import {
  ContactCircle,
  Gear,
  HamburgerNav,
  Key,
  LogOut,
  Search as SearchIcon,
  Ticket,
} from "../../css/icons"
import SignInModal from "../../Authentication/SignInModal"
import {
  setUser,
  signOut,
  setUSDCBalance,
  setNumTickets,
  setNumRewards,
} from "../../reducers/userSlice"
import {
  getCoupons,
  getTokenAccountsForUser,
} from "../../helpers/getTokenAccounts"
import { PublicKey } from "@solana/web3.js" // eslint-disable-line no-unused-vars
import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  PDA,
} from "@captainxyz/solana-core"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"
import MobileSearch from "../MobileSearch/MobileSearch"

// todo add show gold back here
const Header = () => {
  const { publicKey } = useWallet()
  const adapter = useWallet()

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)

  const dispatch = useDispatch()
  const history = useHistory()
  const [showSigninModal, setShowSigninModal] = useState(
    searchParams.get("ssi") || false
  )
  const [showCongestion , setShowCongestion] = useState(false)
  const USDCBalance = useSelector((state) => state.user.usdc)
  const numTickets = useSelector((state) => state.user.numTickets)
  const numRewards = useSelector((state) => state.user.numRewards)
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const mobileNavRef = useRef()
  const [showSearch, setShowSearch] = useState(false)
  const [performers, setPerformers] = useState({})
  const [showNHL, setShowNHL] = useState(false)
  const [showMLB, setShowMLB] = useState(false)
  const [showNBA, setShowNBA] = useState(false)
  const [showNFL, setShowNFL] = useState(false)
  const [showConcerts, setShowConcerts] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const mlbRef = useRef()
  const nhlRef = useRef()
  const nbaRef = useRef()
  const nflRef = useRef()
  const concertsRef = useRef()

  useDetectClickOutside(mobileNavRef, () => {
    setMobileNavOpen(false)
    document.body.style.position = "unset"
  })

  useDetectClickOutside(mlbRef, () => {
    setShowMLB(false)
  })

  useDetectClickOutside(nhlRef, () => {
    setShowNHL(false)
  })

  useDetectClickOutside(nbaRef, () => {
    setShowNBA(false)
  })

  useDetectClickOutside(nflRef, () => {
    setShowNFL(false)
  })

  useDetectClickOutside(concertsRef, () => {
    setShowConcerts(false)
  })

  const getTokenBalance = async (adapter, user) => {
    let operator
    let publicKey
    if (adapter?.publicKey) {
      const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
      operator = new Operator("mainnet-beta", walletAdapterIdentity)
      publicKey = adapter.publicKey
    } else if (user?.publicKey) {
      operator = new Operator("mainnet-beta")
      publicKey = new PublicKey(user.publicKey)
    } else {
      return { decryptList: [], decryptListPast: [] }
    }

    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })

    let mint = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
    let tokenAddress = PDA.token(new PublicKey(mint), publicKey)
    try {
      let token = await TokenAccount.init(operator, tokenAddress)
      if (USDCBalance === null) {
        if (token.balance.qty) {
          dispatch(
            setUSDCBalance(parseInt(parseInt(token.balance.qty) / 1000000))
          )
        } else {
          dispatch(setUSDCBalance(0))
        }
      }
    } catch (err) {
      if (USDCBalance === null) {
        dispatch(setUSDCBalance(0))
      }
    }
  }

  const user = useSelector((state) => state.user.user)

  const loadWallet = async () => {
    if (!user && !adapter?.publicKey) {
      let u = localStorage.getItem("user")
      if (u) {
        let _user = JSON.parse(u)
        let { decryptList } = await getTokenAccountsForUser(adapter, _user)
        dispatch(setNumTickets(decryptList.length))
        getTokenBalance(adapter, _user, "USDC")
      }
    } else {
      let { decryptList } = await getTokenAccountsForUser(adapter, user)
      dispatch(setNumTickets(decryptList.length))
      getTokenBalance(adapter, user, "USDC")
    }
  }

  const loadCoupons = async () => {
    const coupons = await getCoupons(user)
    dispatch(setNumRewards(coupons?.length))
  }

  useEffect(() => {
    if (user?.publicKey) {
      loadCoupons()
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (publicKey) {
      if (window.publicKey && window.publicKey !== publicKey.toString()) {
        window.location.reload()
      } else {
        window.publicKey = publicKey.toString()
      }
    }
  }, [publicKey]) // eslint-disable-line react-hooks/exhaustive-deps

  // try to load user from localstorage... probably belongs somewhere else
  useEffect(() => {
    let u = localStorage.getItem("user")
    if (u) {
      let _user = JSON.parse(u)
      if (
        localStorage.getItem("campaign_name") ||
        localStorage.getItem("phantom_browser")
      ) {
        _user.authenticated = true
      }
      dispatch(setUser(_user))

      loadWallet()
    }
    
    if(window.location.href.indexOf('redeem') > -1){
      setShowCongestion(true)
    }

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // try to load user from localstorage... probably belongs somewhere else
  useEffect(() => {
    if (user?.loginMethod === "phone") {
      recordPhoneUser()
    }
    getPerformers()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (publicKey) {
      // user is loggged in with wallet
      document.body.style.removeProperty("overflow")

      let authenticated = false
      if (
        localStorage.getItem(
          "campaign_name" || localStorage.getItem("phantom_browser")
        )
      ) {
        authenticated = true
      }
      let u
      if (authenticated) {
        u = {
          publicKey: publicKey.toString(),
          loginMethod: "wallet",
          authenticated: authenticated,
        }
        dispatch(setUser(u))
      } else {
        u = {
          publicKey: publicKey.toString(),
          loginMethod: "wallet",
        }
        dispatch(setUser(u))
      }
      loadWallet()
      LogRocket.track(publicKey.toString())
      recordUser(u)
    } else {
      if (user?.loginMethod === "wallet") {
        dispatch(signOut())
      }
    }
  }, [publicKey]) // eslint-disable-line react-hooks/exhaustive-deps

  // pass u directly into here since its not always ready from
  // redux at this time
  const recordUser = async (u) => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/record-user`
    let headers = {
      "Content-Type": "application/json",
    }
    let data = {
      wallet: publicKey.toString(),
      wallet_type: adapter?.wallet.adapter.name,
    }

    if (window.INITIAL_CAMPAIGN) {
      data["initial_campaign"] = window.INITIAL_CAMPAIGN
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      let json = await resp.json()
      let u2 = JSON.parse(JSON.stringify(u))
      u2.first_name = json.first_name
      u2.last_name = json.last_name
      u2.email = json.email
      u2.uuid = json.uuid

      if (
        localStorage.getItem("campaign_name") ||
        localStorage.getItem("phantom_browser")
      ) {
        u2.authenticated = true
      }

      dispatch(setUser(u2))
    } catch (err) {
      console.log("Error recording user", err)
    }
  }

  const recordPhoneUser = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/record-user`
    let headers = {
      "Content-Type": "application/json",
    }
    let data = {
      wallet: user.publicKey.toString(),
      wallet_type: "phone",
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      let json = await resp.json()
      let u2 = JSON.parse(JSON.stringify(user))
      u2.first_name = json.first_name
      u2.last_name = json.last_name
      u2.email = json.email
      u2.uuid = json.uuid

      if (
        localStorage.getItem("campaign_name") ||
        localStorage.getItem("phantom_browser")
      ) {
        u2.authenticated = true
      }

      dispatch(setUser(u2))
      localStorage.setItem("user", JSON.stringify(u2))
    } catch (err) {
      console.log("Error recording user", err)
    }
  }

  const getPerformers = async () => {
    const leagues = ["MLB", "NHL", "NBA", "NFL"]
    const performerLists = {}

    leagues.forEach(async (league) => {
      const perfs = await getPerformersByLeague({ league })
      performerLists[league] = perfs
    })

    const concerts = await getArtistsForFeaturedEvents()
    performerLists["concerts"] = concerts

    setPerformers(performerLists)
  }

  const getPerformersByLeague = async ({ league }) => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/performers-by-league`
    let headers = {
      "Content-Type": "application/json",
    }
    let data = {
      league,
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      let json = await resp.json()
      return json.performers
    } catch (err) {
      console.log("Error getting performers by league", err)
    }
  }

  const getArtistsForFeaturedEvents = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/featured-performers`
    let headers = {
      "Content-Type": "application/json",
    }
    let data = {
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      let json = await resp.json()
      return json
    } catch (err) {
      console.log("Error getting performers by league", err)
    }
  }

  const ref = useRef()

  const publicKeyTruncated = () => {
    if (user?.publicKey) {
      return `${user.publicKey.slice(0, 4)}...${user.publicKey.slice(-4)}`
    }
  }

  return (
    <>
      {process.env.REACT_APP_SKIN !== 'ticketnetwork' && !showCongestion && (
        <div className={styles.disclaimerBanner}>
          XP is a resale marketplace. Prices may be above or below face value.
        </div>
      )}
      
      {showCongestion && (
        <div className={styles.disclaimerBanner} style={{color:'red'}}>
          Pardon our latency, XP is experiencing high transaction volume. It may take multiple attempts to complete your transaction.
        </div>
      )}

      <div className={styles.blackHeader} id="header" ref={ref}>
        <div className={styles.headerLeft}>
          <img
            className={styles.headerLogo}
            alt="logo"
            style={
              process.env.REACT_APP_SKIN == "ticketnetwork"
                ? { curosr: "pointer", width: "unset", height: "45px" }
                : { cursor: "pointer" }
            }
            onClick={() => history.push("/")}
            src={
              process.env.REACT_APP_SKIN == "ticketnetwork"
                ? "https://cdn.hngr.co/tamperproof/goldcoast-powered-by-xp-logo.png"
                : "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Logo.png"
            }
          />
          {window.innerWidth > 960 && history.location.pathname !== "/" && (
            <Search inHeader={true} />
          )}
        </div>
        {window.innerWidth > 960 && (
          <>
            <div className={styles.headerRight}>
              <div
                onMouseEnter={() => setShowMLB(true)}
                onMouseLeave={() => setShowMLB(false)}
                className={styles.headerLink}
              >
                <div
                  onClick={() => {
                    setShowMLB(!showMLB)
                  }}
                >
                  MLB
                </div>
                {showMLB && (
                  <div className={styles.listMenu} ref={mlbRef}>
                    <h2>MLB</h2>
                    <div className={styles.list}>
                      {performers &&
                        performers?.MLB?.map((performer, i) => {
                          if (performer.name.includes("MLB")) return null
                          return (
                            <div
                              className={styles.listMenuItem}
                              onClick={() => {
                                history.push(
                                  `/artist/${performer.performer_id}`
                                )
                              }}
                            >
                              {performer.name}
                            </div>
                          )
                        })}
                    </div>
                    <div className={styles.listButtonContainer}>
                      <Button
                        onClick={() => {
                          history.push("/events?league=MLB")
                          setShowMLB(false)
                        }}
                        variant="blue"
                        fullWidth
                      >
                        View all MLB tickets
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div
                onMouseEnter={() => setShowNHL(true)}
                onMouseLeave={() => setShowNHL(false)}
                className={styles.headerLink}
              >
                <div
                  onClick={() => {
                    setShowNHL(!showNHL)
                  }}
                >
                  NHL
                </div>
                {showNHL && (
                  <div
                    className={classNames(
                      styles.listMenu,
                      styles.listMenuOffset1
                    )}
                    ref={nhlRef}
                  >
                    <h2>NHL</h2>
                    <div className={styles.list}>
                      {performers &&
                        performers?.NHL?.map((performer) => {
                          if (
                            performer.name.includes("NHL") ||
                            performer.name.includes("Skills")
                          )
                            return null
                          return (
                            <div
                              className={styles.listMenuItem}
                              onClick={() => {
                                history.push(
                                  `/artist/${performer.performer_id}`
                                )
                              }}
                            >
                              {performer.name}
                            </div>
                          )
                        })}
                    </div>
                    <div className={styles.listButtonContainer}>
                      <Button
                        onClick={() => {
                          history.push("/events?league=NHL")
                          setShowNHL(false)
                        }}
                        variant="blue"
                        fullWidth
                      >
                        View all NHL tickets
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div
                onMouseEnter={() => setShowNBA(true)}
                onMouseLeave={() => setShowNBA(false)}
                className={styles.headerLink}
              >
                <div
                  onClick={() => {
                    setShowNBA(!showNBA)
                  }}
                >
                  NBA
                </div>
                {showNBA && (
                  <div
                    className={classNames(
                      styles.listMenu,
                      styles.listMenuOffset2
                    )}
                    ref={nbaRef}
                  >
                    <h2>NBA</h2>
                    <div className={styles.list}>
                      {performers &&
                        performers?.NBA?.map((performer) => {
                          if (performer.name.includes("NBA")) return null
                          return (
                            <div
                              className={styles.listMenuItem}
                              onClick={() => {
                                history.push(
                                  `/artist/${performer.performer_id}`
                                )
                              }}
                            >
                              {performer.name}
                            </div>
                          )
                        })}
                    </div>
                    <div className={styles.listButtonContainer}>
                      <Button
                        onClick={() => {
                          history.push("/events?league=NBA")
                          setShowNBA(false)
                        }}
                        variant="blue"
                        fullWidth
                      >
                        View all NBA tickets
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div
                onMouseEnter={() => setShowNFL(true)}
                onMouseLeave={() => setShowNFL(false)}
                className={styles.headerLink}
              >
                <div
                  onClick={() => {
                    setShowNFL(!showNFL)
                  }}
                >
                  NFL
                </div>
                {showNFL && (
                  <div
                    className={classNames(
                      styles.listMenu,
                      styles.listMenuOffset3
                    )}
                    ref={nflRef}
                  >
                    <h2>NFL</h2>
                    <div className={styles.list}>
                      {performers &&
                        performers?.NFL?.map((performer) => {
                          if (
                            performer.name.includes("NFL") ||
                            performer.name.includes("AFC") ||
                            performer.name.includes("NFC") ||
                            performer.name.includes("Super Bowl") ||
                            performer.name.includes("Tailgate")
                          )
                            return null

                          return (
                            <div
                              className={styles.listMenuItem}
                              onClick={() => {
                                history.push(
                                  `/artist/${performer.performer_id}`
                                )
                              }}
                            >
                              {performer.name}
                            </div>
                          )
                        })}
                    </div>
                    <div className={styles.listButtonContainer}>
                      <Button
                        onClick={() => {
                          history.push("/events?league=NFL")
                          setShowNFL(false)
                        }}
                        variant="blue"
                        fullWidth
                      >
                        View all NFL tickets
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div
                onMouseEnter={() => setShowConcerts(true)}
                onMouseLeave={() => setShowConcerts(false)}
                className={styles.headerLink}
              >
                <div
                  onClick={() => {
                    setShowConcerts(!showConcerts)
                  }}
                >
                  Concerts
                </div>
                {showConcerts && (
                  <div
                    className={classNames(
                      styles.listMenu,
                      styles.listMenuOffset4
                    )}
                    ref={concertsRef}
                  >
                    <h2>Concerts</h2>
                    <div className={styles.list}>
                      {performers &&
                        performers?.concerts?.map((performer, i) => {
                          if (i > 30) return null
                          return (
                            <div
                              className={styles.listMenuItem}
                              onClick={() => {
                                history.push(
                                  `/artist/${performer.performer_id}`
                                )
                              }}
                            >
                              {performer.name}
                            </div>
                          )
                        })}
                    </div>
                    <div className={styles.listButtonContainer}>
                      <Button
                        variant="blue"
                        fullWidth
                        onClick={() => {
                          history.push("/events?eventType=concerts")
                          setShowConcerts(false)
                        }}
                      >
                        View all concert tickets
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={classNames(
                  styles.menuContainer,
                  showMenu && styles.menuContainerOpen
                )}
                onMouseEnter={() => {
                  if (user?.publicKey) {
                    setShowMenu(true)
                  }
                }}
                onMouseLeave={() => {
                  if (user?.publicKey) {
                    setShowMenu(false)
                  }
                }}
              >
                {user?.publicKey ? (
                  <div className={styles.userMenu}>
                    <ContactCircle />
                    {user?.loginMethod === "phone"
                      ? user?.phone
                      : publicKeyTruncated()}
                  </div>
                ) : (
                  <div
                    className={styles.userMenu}
                    onClick={() => setShowSigninModal(true)}
                  >
                    {" "}
                    Sign Up / Log In
                  </div>
                )}
                {showMenu && (
                  <div className={styles.userMenuContainer}>
                    <div>
                      {/* <div className={styles.usdcButton}>
                        <span>USDC Balance</span>
                        <span className={styles.usdcBalance}>
                          ${USDCBalance}
                        </span>
                      </div> */}
                      <div
                        className={styles.menuButton}
                        onClick={() => history.push("/holder/tickets")}
                      >
                        <span>My Tickets</span>
                        <div className={styles.count}>
                          <span className={styles.number}>{numTickets}</span>{" "}
                          <Ticket />
                        </div>
                      </div>
                      <div
                        className={styles.menuButton}
                        onClick={() => history.push("/account/rewards")}
                      >
                        <span>My Rewards</span>
                        <div className={styles.count}>
                          <span className={styles.number}>{numRewards}</span>{" "}
                          <Ticket />
                        </div>
                      </div>
                      <div
                        className={styles.menuButton}
                        onClick={() => history.push("/account")}
                      >
                        <span>Manage Account</span>
                        <Gear />
                      </div>
                      <div
                        className={classNames(styles.menuButton, styles.logOut)}
                        onClick={() => {
                          setShowMenu(false)
                          adapter?.disconnect()
                          if (user?.loginMethod === "phone") {
                            dispatch(signOut())
                          }
                          if (history.path !== "/") {
                            history.push("/")
                          }
                        }}
                      >
                        <span>Log Out</span>
                        <LogOut />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {window.innerWidth <= 960 && (
          <>
            <div
              className={classNames(styles.headerRight, styles.mobileNavClosed)}
            >
              <>
                {showSearch ? (
                  <MobileSearch close={() => setShowSearch(false)} />
                ) : (
                  <SearchIcon
                    className={styles.search}
                    onClick={() => {
                      setShowSearch(true)
                    }}
                  />
                )}
              </>
              <HamburgerNav
                className={styles.hamburger}
                onClick={() => {
                  setMobileNavOpen(true)
                  document.body.style.position = "fixed"
                }}
              />
            </div>
          </>
        )}
      </div>
      <div
        ref={mobileNavRef}
        className={classNames(
          styles.mobileNavContainer,
          mobileNavOpen && styles.mobileNavContainerOpen
        )}
      >
        <div
          className={classNames(
            styles.menuContainer,
            showMenu && styles.menuContainerOpen
          )}
        >
          {user?.publicKey ? (
            <div>
              <div className={styles.userMenu}>
                <ContactCircle />
                {user?.loginMethod === "phone"
                  ? user?.phone
                  : publicKeyTruncated()}
              </div>
              <div className={styles.userMenuContainer}>
                <div>
                  {/* <div className={styles.usdcButton}>
                    <span>USDC Balance</span>
                    <span className={styles.usdcBalance}>${USDCBalance}</span>
                  </div> */}
                  <div
                    className={styles.menuButton}
                    onClick={() => history.push("/holder/tickets")}
                  >
                    <span>My Tickets</span>
                    <div className={styles.count}>
                      <span className={styles.number}>{numTickets}</span>{" "}
                      <Ticket />
                    </div>
                  </div>
                  {/* <div
                    className={styles.menuButton}
                    onClick={() => history.push("/account")}
                  >
                    <span>My Rewards</span>
                    <div className={styles.count}>
                      <span className={styles.number}>0</span> <Ticket />
                    </div>
                  </div> */}
                  <div
                    className={styles.menuButton}
                    onClick={() => history.push("/account")}
                  >
                    <span>Manage Account</span>
                    <Gear />
                  </div>
                  <div
                    className={classNames(styles.menuButton, styles.logOut)}
                    onClick={() => {
                      setShowMenu(false)
                      adapter?.disconnect()
                      if (user?.loginMethod === "phone") {
                        dispatch(signOut())
                      }
                      if (history.path !== "/") {
                        history.push("/")
                      }
                    }}
                  >
                    <span>Log Out</span>
                    <LogOut />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                className={classNames(styles.userMenu, styles.userMenuMobile)}
                onClick={() => {
                  setShowSigninModal(true)
                  setMobileNavOpen(false)
                }}
              >
                Sign Up / Log In
              </div>
              <div className={styles.loggedOutInfo}>
                <Key />
                <div>Sign up or log in to access full features</div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showSigninModal && (
        <SignInModal onClose={() => setShowSigninModal(false)} />
      )}
    </>
  )
}

export default Header
