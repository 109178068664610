import { useEffect, useState, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"
import { CloseCircle, Info } from "../css/icons"
import styles from "./SelectDeliveryMethod.module.scss"
import { Button, Input, Modal } from "../components"
import { useDispatch, useSelector } from "react-redux"
import { updateUser } from "../reducers/userSlice"
import postToSlack from "../postToSlack"
import ToggleSwitch from "../components/ToggleSwitch/ToggleSwitch"

const SelectDeliveryMethod = ({ minting, revision, setDeliveryMethod }) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState("instant")
  const user = useSelector((state) => state.user.user)
  const firstName = useSelector((state) => state.user.user?.first_name)
  const lastName = useSelector((state) => state.user.user?.last_name)
  const email = useSelector((state) => state.user.user?.email)
  const [validEmail, setValidEmail] = useState(false)
  const history = useHistory()
  const params = useParams()

  const [showGuaranteeModal, setShowGuaranteeModal] = useState(false)
  const [showTradableModal, setShowTradableModal] = useState(false)
  const [hasSeenTradableModal, setHasSeenTradableModal] = useState(false)

  useEffect(() => {
    if (email) {
      validateEmail(email)
    }
  }, [email])

  const [first, setFirst] = useState(firstName || "")
  const [last, setLast] = useState(lastName || "")
  const [newEmail, setNewEmail] = useState(email || "")

  const validateEmail = (input) => {
    if (/^\S+@\S+\.\S+$/.test(input)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }

  const handleSubmit = () => {
    if (validEmail) {
      postToSlack("is heading to payment", "firehose", user)
      setDeliveryMethod(selected)
      history.push(
        `/checkout/${params.event_id}/${params.ticket_group_id}/${revision}/payment`
      )
      // save their email and info so we can get it in the unseal flow

      dispatch(
        updateUser({
          first_name: first || firstName,
          last_name: last || lastName,
          email: newEmail || email,
          wallet: user?.publicKey?.toString(),
        })
      )
    }
  }

  const formFilled = validEmail && !!first && !!last && !!newEmail

  const formRef = useRef()

  useEffect(() => {
    if (showGuaranteeModal || showTradableModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [showGuaranteeModal, showTradableModal])

  return (
    <div className={styles.deliveryContainer}>
      {showGuaranteeModal && (
        <Modal
          className={styles.modal}
          onClose={() => setShowGuaranteeModal(false)}
          containerClass={styles.modalContainer}
        >
          <CloseCircle className={styles.close} />
          <h1>Ticket Delivery Guaranteed</h1>
          <div className={styles.modalContent}>
            <p>
              Tickets purchased on XP are automatically sent to you as soon as
              venues and artists allow us.
            </p>
            <p>
              While some tickets are delivered immediately after purchase,
              others may be held until close to the event date. Rest assured,
              you'll receive your tickets at least 48 hours before the event,
              guaranteed.
            </p>
          </div>
          <Button
            variant="blue"
            fullWidth
            onClick={() => setShowGuaranteeModal(false)}
          >
            Close
          </Button>
        </Modal>
      )}
      {showTradableModal && (
        <Modal
          className={styles.modal}
          onClose={() => setShowTradableModal(false)}
          width="medium"
          containerClass={styles.modalContainer}
        >
          <CloseCircle
            className={styles.close}
            onClick={() => setShowTradableModal(false)}
          />
          <h1>Tradable Tickets</h1>
          <p className={styles.tagline}>
            Only possible on <span className={styles.xp}>XP</span> with{" "}
            <span className={styles.solana}>Solana</span>
          </p>
          <div className={styles.modalContent}>
            <p>With tradable tickets, you’re in control.</p>
            <p>
              Easily send your tickets to anyone with a Solana wallet or resell
              them securely on marketplaces such as{" "}
              <a
                href="https://www.tensor.trade/"
                target="_blank"
                rel="noreferrer"
              >
                Tensor
              </a>{" "}
              and{" "}
              <a href="https://magiceden.io/" target="_blank" rel="noreferrer">
                Magic Eden
              </a>
              .
            </p>
            <p>Learn more about tradable tickets here.</p>
          </div>
          <div className={styles.buttonRow}>
            <Button variant="gray" onClick={() => setShowTradableModal(false)}>
              Close
            </Button>
            <Button
              variant="blue"
              onClick={() => {
                setShowTradableModal(false)
                setSelected("transferrable")
              }}
            >
              Make my tickets tradable
            </Button>
          </div>
        </Modal>
      )}
      <div
        className={styles.deliveryDetails}
        id="deliveryDetails"
        ref={formRef}
      >
        <div className={styles.deliveryHeader}>
          <h1>Ticket Delivery Details</h1>
          <p>Tickets will be sent to the email you enter below.</p>
        </div>
        <div className={styles.deliveryForm}>
          <div className={styles.inputRow}>
            <div className={styles.inputContainer}>
              <div className={styles.inputLabel}>First Name</div>
              <Input
                name="firstName"
                value={first}
                onChange={(e) => setFirst(e.target.value)}
                placeholder="First Name"
              />
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.inputLabel}>Last Name</div>
              <Input
                name="lastName"
                value={last}
                onChange={(e) => setLast(e.target.value)}
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputLabel}>Email Address</div>
            <Input
              name="email"
              value={newEmail}
              onChange={(e) => {
                setNewEmail(e.target.value)
                validateEmail(e.target.value)
              }}
              placeholder="Enter your email address"
            />
          </div>
        </div>
        <div className={styles.guarantee}>
          Ticket Delivery Guaranteed{" "}
          <Info onClick={() => setShowGuaranteeModal(true)} />{" "}
        </div>
      </div>

      <div className={styles.tradableContainer}>
        <span>Make my tickets tradable</span>

        <div className={styles.toggleContainer}>
          <div className={styles.info}>
            <Info onClick={() => setShowTradableModal(true)} />
          </div>
          <ToggleSwitch
            value={selected === "transferrable"}
            onChange={() => {
              if (selected === "instant") {
                if (!hasSeenTradableModal) {
                  setShowTradableModal(true)
                  setHasSeenTradableModal(true)
                } else {
                  setSelected("transferrable")
                }
              } else {
                setSelected("instant")
              }
            }}
          />
        </div>
      </div>
      <div className={styles.help}>
        Need help? <a href="mailto:support@xp.xyz">Contact Support</a>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          disabled={!formFilled}
          variant="blue"
          className={styles.button}
          onClick={() => handleSubmit()}
          loading={false}
          fullWidth
        >
          Continue to Checkout
        </Button>
      </div>
    </div>
  )
}

export default SelectDeliveryMethod
