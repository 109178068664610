import React, { useEffect, useState } from "react"
import styles from "./Promo.module.scss"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { getCoupon } from "../../helpers/getTokenAccounts"
import postToSlack from "../../postToSlack"
import Button from "../Button/Button"
import { RedeemModal } from "../"
import { X } from "../../css/icons"

const { sleep } = require("../../helpers/sleep")
const { formatCents } = require("../../helpers/money")

const Promo = (props) => {
  const [coupon, setCoupon] = useState(false)
  const [showRedeemModal, setShowRedeemModal] = useState(false)
  const [applyDiscountClicked, setApplyDiscountClicked] = useState(false)
  const [minimumNotMet, setMinimumNotMet] = useState(false)
  const [minimum, setMinimum] = useState(null)
  const [discountApplied, setDiscountApplied] = useState(false)
  const user = useSelector((state) => state.user.user)

  useEffect(() => {
    return () => (window.checkedForCoupon = false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!user?.publicKey) return
    if (!window.checkedForCoupon) checkForCoupon()
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkForCoupon = async () => {
    if (window.checkedForCoupon) return
    if (!props.total) {
      setTimeout(() => checkForCoupon(), 100)
      return
    }
    let { coupon, minimumIsMet } = await getCoupon(user, props.total)
    window.checkedForCoupon = true
    if (coupon && minimumIsMet) {
      setCoupon(coupon)
      return coupon
    } else if (coupon && !minimumIsMet) {
      setMinimumNotMet(true)
      setMinimum(formatCents(coupon?.metadata?._json.minimum))
    }
  }

  const checkForCouponAfterRedeem = async () => {
    let { coupon, minimumIsMet } = await getCoupon(user, props.total)
    console.log("checking for  coupon after redeem")
    if (!coupon) {
      console.log("no coupon after redeem")
      await sleep(1000)
      return await checkForCouponAfterRedeem()
    } else {
      console.log("yes coupon after redeem")
      return {
        coupon:coupon,
        minimumIsMet:minimumIsMet
      }
    }
  }

  const applyDiscount = async (c) => {
    setApplyDiscountClicked(true)
    let coupon_ = c || coupon
    let success = await props.setCoupon(coupon_)
    if (success) {
      setDiscountApplied(true)
      let msg = `applied promo ${coupon_.mint.address.toString()} during checkout`
      postToSlack(msg, "firehose", user)
    } else {
      let msg = `had error applying promo ${coupon_.mint.address.toString()} during checkout`
      postToSlack(msg, "firehose", user)
    }
  }

  // on success, apply the coupon and close the modal
  const onRedeemSucces = async () => {
    await checkForCouponAfterRedeem()
    window.checkedForCoupon = false
    let result  = await getCoupon(user, props.total)
    let minimumIsMet = result.minimumIsMet
    let c = result.coupon
    if(minimumIsMet){
      applyDiscount(c)
      setApplyDiscountClicked(true) 
    } else {
      setApplyDiscountClicked(true) 
      setMinimumNotMet(true)
      setMinimum(formatCents(c?.metadata?._json.minimum))
    }
    setShowRedeemModal(false)
  }

  const removeDiscount = async () => {
    setApplyDiscountClicked(false)
    setDiscountApplied(false)
    props.removeCoupon()
  }

  //if (!coupon) return null

  if (discountApplied) {
    return (
      <div
        {...props}
        className={classNames(styles.container, styles.containerApplied)}
      >
        <div className={styles.containerLeft}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={classNames(styles.leftTopText, styles.appliedText)}>
              Rewards Applied ✅
            </div>
          </div>
        </div>

        <Button
          variant="beigeOutline"
          compact
          className={styles.removeButton}
          onClick={removeDiscount}
        >
          <div>Remove</div>
        </Button>
      </div>
    )
  }

  if (minimumNotMet && applyDiscountClicked) {
    return (
      <div className={styles.minimumNotMet}>
        <div className={styles.minimumNotMetLeft}>
          <div className={styles.minimumNotMetTop}>
            Minimum Spend Required ⚠️
          </div>
          <div className={styles.minimumNotMetBottom}>
            Hit a <b>{minimum}</b> minimum to apply reward
          </div>
        </div>
        <X
          style={{ cursor: "pointer" }}
          onClick={() => {
            setApplyDiscountClicked(false)
          }}
        />
      </div>
    )
  }
  return (
    <>
      {showRedeemModal && (
        <RedeemModal
          onRedeemSucces={onRedeemSucces}
          onClose={() => setShowRedeemModal(false)}
        />
      )}

      <div {...props} className={styles.container}>
        <div className={styles.containerLeft}>
          <div className={styles.leftText}>
            {coupon && (
              <div className={styles.leftTopText}>Reward Detected 🎁</div>
            )}
          </div>
        </div>

        <Button
          variant="blue"
          compact
          className={styles.applyDiscountButton}
          onClick={() => {
            if(minimumNotMet) {
              setApplyDiscountClicked(true) 
            } else if (coupon) {
              applyDiscount()
            } else {
              setShowRedeemModal(true)
            }
          }}
        >
          <div>Apply Rewards</div>
        </Button>
      </div>
    </>
  )
}

export default Promo
