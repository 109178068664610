import { AccountInfo, Keypair, PublicKey } from "@solana/web3.js";
import * as SPLCompession from "@solana/spl-account-compression";
import * as MPLBubblegum from "../programs/mpl-bubblegum";
import bs58 from "bs58";
import BN from "bn.js";

import { Operator, TransactionResult } from "../operator";
import { Transactions } from "../transactions";
import { Amount, Currency } from "../currency";
import { getAssetResponse } from "../helius/types";
import { AssetSortBy, AssetSortDirection } from "../helius/enums";
import { PDA } from "../pda";
import { BaseAccount } from "./base";
import { JSONMetadata } from "./metadata";
import { TokenAccount } from "./token";

/**
 * @group Accounts
 */
export class MerkelTreeAuthAccount extends BaseAccount {
  public readonly tree: MerkelTreeAccount;

  private _account: MPLBubblegum.TreeConfig;

  public get mintAuthority(): PublicKey {
    return this._account.treeDelegate;
  }

  public get capacity(): bigint {
    return this._getCached("capacity", () =>
      BigInt(this._account.totalMintCapacity.toString()),
    );
  }

  public get minted(): bigint {
    return this._getCached("minted", () =>
      BigInt(this._account.numMinted.toString()),
    );
  }

  protected constructor(params: {
    operator: Operator;
    address: PublicKey;
    lamports: number | bigint;
    account: MPLBubblegum.TreeConfig;
    tree: MerkelTreeAccount;
  }) {
    super(params);
    this._account = params.account;
    this.tree = params.tree;
  }

  /**
   * Creates new Merkel Tree and its authority account.
   */
  public static async create(
    operator: Operator,
    params: {
      capacity: number;
    },
  ): Promise<[MerkelTreeAuthAccount, TransactionResult]> {
    if (!operator.identity) throw new Error("Operator is in read-only mode");

    let maxDepth = 1;
    let maxCapacity = 2;
    let maxBufferSize = 0;
    let canopy = 0;
    while (params.capacity > maxCapacity) {
      maxDepth++;
      maxCapacity *= 2;
    }
    for (let pair of SPLCompession.ALL_DEPTH_SIZE_PAIRS) {
      if (pair.maxDepth >= maxDepth) {
        maxDepth = pair.maxDepth;
        maxBufferSize = pair.maxBufferSize;
        break;
      }
    }
    if (maxDepth >= 14) canopy = 5; // TODO: find out how to calculate canopy

    const space = SPLCompession.getConcurrentMerkleTreeAccountSize(
      maxDepth,
      maxBufferSize,
      canopy,
    );
    const lamports =
      await operator.connection.getMinimumBalanceForRentExemption(space);

    const merkleTreeKeypair = Keypair.generate();
    const merkleTreeAuth = PDA.merkleTreeAuthority(merkleTreeKeypair.publicKey);

    try {
      const result = await operator.execute(
        Transactions.createMerkelTree({
          owner: operator.identity.publicKey,
          keypair: merkleTreeKeypair,
          auth: merkleTreeAuth,
          lamports,
          space,
          maxDepth,
          maxBufferSize,
        }),
      );
      const account = await this.init(
        operator,
        merkleTreeAuth,
        result.accounts.get(merkleTreeAuth.toString()),
        merkleTreeKeypair.publicKey,
        result.accounts.get(merkleTreeKeypair.publicKey.toString()),
      );
      return [account, result];
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  public static async init(
    operator: Operator,
    address: PublicKey,
    accountInfo?: AccountInfo<Buffer> | null,
    treeAddress?: PublicKey,
    treeInfo?: AccountInfo<Buffer> | null,
  ): Promise<MerkelTreeAuthAccount> {
    const existent = await this._checkExistent<MerkelTreeAuthAccount>(
      operator,
      address,
      accountInfo,
    );
    if (existent) return existent;

    accountInfo ??= await operator.connection.getAccountInfo(address);
    if (!accountInfo) throw new Error(`Account not found ${address}`);

    const lamports = accountInfo.lamports;
    const account = MPLBubblegum.TreeConfig.decode(accountInfo.data);

    if (!treeAddress) {
      const accounts = await operator.connection.getProgramAccounts(
        SPLCompession.SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
        {
          filters: [
            {
              memcmp: {
                offset:
                  SPLCompession.compressionAccountTypeBeet.byteSize +
                  1 + // ??? TODO: find out what is it
                  4 + // maxBufferSize: u32
                  4, //  maxDepth: u32
                bytes: address.toBase58(),
              },
            },
          ],
        },
      );
      if (accounts.length !== 1) {
        throw new Error(`Tree account not found for authority ${address}`);
      }
      treeAddress = accounts[0].pubkey;
      treeInfo = accounts[0].account;
    }
    const tree = await MerkelTreeAccount.init(operator, treeAddress, treeInfo);

    const result = new this({
      operator,
      address,
      lamports,
      account,
      tree,
    });
    return await result._init();
  }

  public static async find(
    operator: Operator,
    delegate?: PublicKey,
  ): Promise<MerkelTreeAuthAccount[]> {
    if (!delegate) {
      if (!operator.identity) throw new Error("Undefined delegate address");
      delegate = operator.identity.publicKey;
    }
    const accounts = await operator.connection.getProgramAccounts(
      MPLBubblegum.PROGRAM_ID,
      {
        filters: [
          {
            memcmp: {
              offset:
                8 + // account discriminator
                32, // creator: PublicKey
              bytes: delegate.toBase58(),
            },
          },
        ],
      },
    );
    return await Promise.all(
      accounts.map((res) => this.init(operator, res.pubkey, res.account)),
    );
  }

  /**
   * Checks whether the {@link operator}
   * can perform {@link mint} action on the account.
   */
  public get canMint(): boolean {
    return this._getCached("canMint", () =>
      Boolean(
        this.mintAuthority &&
          this.operator.identity?.publicKey.equals(this.mintAuthority),
      ),
    );
  }

  /**
   * Mints new compressed NFT.
   */
  public async mint(params: {
    to?: PublicKey;
    currency: Currency;
    json?: JSONMetadata;
    collectionMint?: PublicKey;
  }): Promise<[CNFT, TransactionResult]> {
    if (!this.canMint) {
      throw new Error(`Operator has no mint authority on ${this}`);
    }
    const payer = this.operator.identity!.publicKey;

    if (params.currency.decimals != 0) {
      throw new Error("Invalid decimals for NFT");
    }
    const json: JSONMetadata = {
      ...params.json,
      name: params.currency.name,
      symbol: params.currency.symbol,
      image: params.currency.logoURI!,
    };
    const jsonURI = await this.operator.storage.upload({ json });

    const result = await this.operator.execute(
      Transactions.mintCNFT({
        payer,
        tree: this.tree.address,
        auth: this.address,
        to: params.to,
        name: params.currency.name,
        symbol: params.currency.symbol,
        uri: jsonURI,
        collectionMint: params.collectionMint,
      }),
    );

    let transaction;
    for (let i = 0; i < 10; i++) {
      transaction = await this.operator.connection.getTransaction(
        result.signature,
        {
          maxSupportedTransactionVersion: 0,
        },
      );
      if (transaction) break;
      console.warn("Transaction not found, retrying...");
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    if (!transaction) throw new Error("Transaction not found");

    const staticAccounts =
      transaction.transaction.message.getAccountKeys().staticAccountKeys;

    for (const inst of transaction.meta?.innerInstructions ?? []) {
      for (const subInst of inst.instructions) {
        const programId = staticAccounts[subInst.programIdIndex];
        if (programId && programId.equals(SPLCompession.SPL_NOOP_PROGRAM_ID)) {
          const data = Buffer.from(bs58.decode(subInst.data));
          const leaf = MPLBubblegum.LeafSchema.fromDecoded(
            MPLBubblegum.LeafSchema.layout().decode(data, 8),
          );
          const cnft = await CNFT.init(this.operator, {
            address: leaf.value.id,
            owner: params.to ?? payer,
            delegate: null,
            treeAuth: this,
            json,
            jsonURI,
            currency: params.currency,
            collectionMint: params.collectionMint ?? null,
          });

          return [cnft, result];
        }
      }
    }
    throw new Error("Unable to init CNFT");
  }

  /**
   * @inheritDoc
   */
  protected async _refresh(accountInfo: AccountInfo<Buffer>): Promise<void> {
    super._refresh(accountInfo);
    if (!accountInfo.data.length) return;

    const account = MPLBubblegum.TreeConfig.decode(accountInfo.data);
    this._account = account;
  }
}

/**
 * @group Accounts
 */
export class MerkelTreeAccount extends BaseAccount {
  private _account: SPLCompession.ConcurrentMerkleTreeAccount;

  public get authority(): PublicKey {
    return this._account.getAuthority();
  }

  public get maxDepth(): number {
    return this._account.getMaxDepth();
  }

  public get maxBufferSize(): number {
    return this._account.getMaxBufferSize();
  }

  public get canopyDepth(): number {
    return this._account.getCanopyDepth();
  }

  protected constructor(params: {
    operator: Operator;
    address: PublicKey;
    lamports: number | bigint;
    account: SPLCompession.ConcurrentMerkleTreeAccount;
  }) {
    super(params);
    this._account = params.account;
  }

  public static async init(
    operator: Operator,
    address: PublicKey,
    accountInfo?: AccountInfo<Buffer> | null,
  ): Promise<MerkelTreeAccount> {
    const existent = await this._checkExistent<MerkelTreeAccount>(
      operator,
      address,
      accountInfo,
    );
    if (existent) return existent;

    accountInfo ??= await operator.connection.getAccountInfo(address);
    if (!accountInfo) throw new Error(`Account not found ${address}`);

    const lamports = accountInfo.lamports;
    const account = SPLCompession.ConcurrentMerkleTreeAccount.fromBuffer(
      accountInfo.data,
    );

    const result = new this({
      operator,
      address,
      lamports,
      account,
    });
    return await result._init();
  }

  /**
   * @inheritDoc
   */
  protected async _refresh(accountInfo: AccountInfo<Buffer>): Promise<void> {
    super._refresh(accountInfo);
    if (!accountInfo.data.length) return;

    const account = SPLCompession.ConcurrentMerkleTreeAccount.fromBuffer(
      accountInfo.data,
    );
    this._account = account;
  }
}

export class CNFT {
  public readonly operator: Operator;
  public readonly address: PublicKey;
  public owner: PublicKey;
  public delegate: PublicKey | null;
  public readonly collectionMint: PublicKey | null;
  public readonly treeAuth: MerkelTreeAuthAccount;
  public json: JSONMetadata;
  public jsonURI: string;
  public readonly balance: Amount;

  public constructor(
    operator: Operator,
    params: {
      address: PublicKey;
      owner: PublicKey;
      delegate: PublicKey | null;
      treeAuth: MerkelTreeAuthAccount;
      json: JSONMetadata;
      jsonURI: string;
      currency: Currency;
      collectionMint: PublicKey | null;
    },
  ) {
    this.operator = operator;
    this.address = params.address;
    this.owner = params.owner;
    this.delegate = params.delegate;
    this.collectionMint = params.collectionMint;
    this.treeAuth = params.treeAuth;
    this.json = params.json;
    this.jsonURI = params.jsonURI;
    this.balance = params.currency.amountFromQty(1);
  }

  public static async init(
    operator: Operator,
    params: {
      address: PublicKey;
      asset?: getAssetResponse;
      owner?: PublicKey;
      delegate?: PublicKey | null;
      treeAuth?: MerkelTreeAuthAccount;
      json?: JSONMetadata;
      jsonURI?: string;
      currency?: Currency;
      collectionMint?: PublicKey | null;
    },
  ): Promise<CNFT> {
    let {
      address,
      asset,
      owner,
      delegate,
      treeAuth,
      json,
      jsonURI,
      currency,
      collectionMint,
    } = params;

    if (
      typeof owner === "undefined" ||
      typeof delegate === "undefined" ||
      typeof treeAuth === "undefined" ||
      typeof json === "undefined" ||
      typeof jsonURI === "undefined" ||
      typeof currency === "undefined" ||
      typeof collectionMint === "undefined"
    ) {
      asset ??= await operator.rpc.getAsset({ id: address.toString() });
      if (!asset) {
        throw new Error(`CNFT not found ${address}`);
      }
      if (!asset.compression || !asset.ownership || !asset.content) {
        throw new Error(`Invalid CNFT ${address}`);
      }

      if (!treeAuth) {
        const treeAddress = new PublicKey(asset.compression.tree);
        const treeAuthAddress = PDA.merkleTreeAuthority(treeAddress);
        treeAuth = await MerkelTreeAuthAccount.init(
          operator,
          treeAuthAddress,
          null,
          treeAddress,
          null,
        );
      }
      owner ??= new PublicKey(asset.ownership.owner);
      delegate ??= asset.ownership.delegate
        ? new PublicKey(asset.ownership.delegate)
        : null;

      if (!collectionMint && asset.grouping) {
        for (const group of asset.grouping) {
          if (group.group_key === "collection") {
            collectionMint = new PublicKey(group.group_value);
            break;
          }
        }
      }
      collectionMint ??= null;

      jsonURI ??= asset.content.json_uri;
      if (!json) {
        try {
          const jsonFile = await operator.storage.get(jsonURI);
          json ??= jsonFile.json as JSONMetadata;
        } catch (e) {
          console.warn(`Unable to handle JSON metadata of ${address}`);
          console.warn(e);
        }
        json ??= asset.content.metadata as JSONMetadata;
      }

      currency ??= new Currency(
        asset.content.metadata.name,
        asset.content.metadata.symbol,
        asset.content.links?.image || null,
        0,
      );
    }
    return new this(operator, {
      address,
      owner,
      delegate,
      treeAuth,
      json,
      jsonURI,
      currency,
      collectionMint,
    });
  }

  public static async find(
    operator: Operator,
    owner?: PublicKey,
  ): Promise<CNFT[]> {
    if (!owner) {
      if (!operator.identity) throw new Error("Undefined owner address");
      owner = operator.identity.publicKey;
    }
    const assets = [];

    let maxPages = 1;
    for (let page = 1; page <= maxPages; page++) {
      const result = await operator.rpc.searchAssets({
        limit: 1000,
        page: page,
        ownerAddress: owner.toString(),
        compressed: true,
        burnt: false,
        sortBy: {
          sortBy: AssetSortBy.Created,
          sortDirection: AssetSortDirection.Desc,
        },
      });
      for (const asset of result.items) {
        assets.push(
          await this.init(operator, {
            address: new PublicKey(asset.id),
            asset,
          }),
        );
      }
      if (maxPages === 1 && result.total > result.limit) {
        maxPages = Math.ceil(result.total / result.limit);
      }
    }

    return assets;
  }

  public toString(): string {
    return `<${this.address}: { balance: ${this.balance}, tree: ${this.treeAuth.tree}>`;
  }

  public async send(to: PublicKey): Promise<TransactionResult> {
    const leaf = await this._getLeaf();

    return this.operator.execute(
      Transactions.transferCNFT({
        to,
        leaf,
        tree: this.treeAuth.tree.address,
        auth: this.treeAuth.address,
      }),
    );
  }

  public async burn(): Promise<TransactionResult> {
    const leaf = await this._getLeaf();

    return this.operator.execute(
      Transactions.burnCNFT({
        leaf,
        tree: this.treeAuth.tree.address,
        auth: this.treeAuth.address,
      }),
    );
  }

  public async update(json: JSONMetadata): Promise<TransactionResult> {
    const leaf = await this._getLeaf();

    json = { ...this.json, ...json };
    const jsonURI = await this.operator.storage.upload({ json });

    const result = await this.operator.execute(
      Transactions.updateCNFTMetadata({
        leaf,
        payer: this.operator.identity!.publicKey,
        tree: this.treeAuth.tree.address,
        auth: this.treeAuth.address,
        update: {
          name: json.name,
          symbol: json.symbol,
          uri: jsonURI,
        },
      }),
    );
    this.json = json;
    this.jsonURI = jsonURI;
    this.balance.currency.name = json.name ?? leaf.metadata.name;
    this.balance.currency.symbol = json.symbol ?? leaf.metadata.symbol;
    return result;
  }

  public async uncompress(): Promise<[TokenAccount, TransactionResult]> {
    const leaf = await this._getLeaf();
    const voucher = PDA.CNFTVoucher(this.treeAuth.tree.address, leaf.index);
    const mint = this.address;
    const tokenAccount = PDA.token(this.address, this.owner);
    const metadata = PDA.tokenMetadata(this.address);

    const voucherAccount = await this.operator.connection.getAccountInfo(
      voucher,
    );
    if (voucherAccount === null) {
      await this.operator.execute(
        Transactions.redeemCNFT({
          tree: this.treeAuth.tree.address,
          auth: this.treeAuth.address,
          voucher,
          leaf,
        }),
      );
    }
    const uncompressResult = await this.operator.execute(
      Transactions.uncompressCNFT({
        tree: this.treeAuth.tree.address,
        auth: this.treeAuth.address,
        voucher,
        leaf,
        mint,
        tokenAccount,
        metadata,
      }),
    );

    const account = await TokenAccount.init(
      this.operator,
      tokenAccount,
      uncompressResult.accounts.get(tokenAccount.toString()),
      uncompressResult.accounts.get(mint.toString()),
      uncompressResult.accounts.get(metadata.toString()),
    );

    return [account, uncompressResult];
  }

  protected async _getLeaf(): Promise<{
    id: PublicKey;
    owner: PublicKey;
    delegate: PublicKey;
    index: number;
    nonce: BN;
    rootHash: Uint8Array;
    dataHash: Uint8Array;
    creatorHash: Uint8Array;
    proofPath: PublicKey[];
    metadata: MPLBubblegum.MetadataArgs;
  }> {
    const id = this.address.toString();
    const asset = await this.operator.rpc.getAsset({ id });
    if (!asset.compression) {
      throw new Error("Asset is not compressed");
    }
    const assetProof = await this.operator.rpc.getAssetProof({ id });
    const owner = new PublicKey(asset.ownership.owner);
    const delegate = asset.ownership.delegate
      ? new PublicKey(asset.ownership.delegate)
      : owner;

    let collectionMint: PublicKey | null = null;
    if (asset.grouping) {
      for (const group of asset.grouping) {
        if (group.group_key === "collection") {
          collectionMint = new PublicKey(group.group_value);
          break;
        }
      }
    }
    const creators = [];
    if (asset.creators) {
      for (const creator of asset.creators) {
        creators.push(
          new MPLBubblegum.Creator({
            ...creator,
            address: new PublicKey(creator.address),
          }),
        );
      }
    }
    const proofPath = assetProof.proof
      .slice(0, assetProof.proof.length - this.treeAuth.tree.canopyDepth)
      .map((p) => new PublicKey(p));

    return {
      id: this.address,
      owner,
      delegate,
      index: asset.compression.leaf_id,
      nonce: new BN(asset.compression.leaf_id),
      rootHash: bs58.decode(assetProof.root),
      dataHash: bs58.decode(asset.compression.data_hash.trim()),
      creatorHash: bs58.decode(asset.compression.creator_hash.trim()),
      proofPath,
      metadata: new MPLBubblegum.MetadataArgs({
        name: asset.content?.metadata.name ?? this.balance.currency.name,
        symbol: asset.content?.metadata.symbol ?? this.balance.currency.symbol,
        uri: asset.content?.json_uri ?? this.jsonURI,
        sellerFeeBasisPoints: asset.royalty?.basis_points ?? 0,
        creators: creators,
        tokenProgramVersion: new MPLBubblegum.TokenProgramVersion.Original(),
        tokenStandard: new MPLBubblegum.TokenStandard.NonFungible(),
        uses: null,
        editionNonce: asset.supply?.edition_nonce ?? null,
        collection: collectionMint
          ? new MPLBubblegum.Collection({ key: collectionMint, verified: true })
          : null,
        primarySaleHappened: Boolean(asset.royalty?.primary_sale_happened),
        isMutable: asset.mutable,
      }),
    };
  }
}
