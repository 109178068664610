export const Mail = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4142 6.58333C14.5158 6.5026 14.6668 6.57812 14.6668 6.70573V12.0312C14.6668 12.7214 14.1069 13.2812 13.4168 13.2812H2.5835C1.89339 13.2812 1.3335 12.7214 1.3335 12.0312V6.70833C1.3335 6.57812 1.48193 6.50521 1.5861 6.58594C2.16943 7.03906 2.94287 7.61458 5.59912 9.54427C6.1486 9.94531 7.07568 10.7891 8.00016 10.7839C8.92985 10.7917 9.87516 9.92969 10.4038 9.54427C13.0601 7.61458 13.8309 7.03646 14.4142 6.58333ZM8.00016 9.94792C8.60433 9.95833 9.47412 9.1875 9.91162 8.86979C13.3674 6.36198 13.6304 6.14323 14.4272 5.51823C14.5783 5.40104 14.6668 5.21875 14.6668 5.02604V4.53125C14.6668 3.84115 14.1069 3.28125 13.4168 3.28125H2.5835C1.89339 3.28125 1.3335 3.84115 1.3335 4.53125V5.02604C1.3335 5.21875 1.42204 5.39844 1.57308 5.51823C2.36995 6.14062 2.63298 6.36198 6.0887 8.86979C6.5262 9.1875 7.396 9.95833 8.00016 9.94792Z"
      fill="#FAFAFA"
    />
  </svg>
)
