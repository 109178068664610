import { useState, useRef } from "react"
import ReactPixel from "react-facebook-pixel"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory, useLocation } from "react-router-dom"
import { Base64 } from "js-base64"
import classNames from "classnames"
import {
  CoinbasePay,
  Button,
  Layout,
  Promo,
  SeatMapTevo,
  SeatMap,
} from "../components"
import SeatPreview from "../SeatPreview/SeatPreview"
import "./CheckoutPage.css"
import styles from "./CheckoutPage.module.scss"
import "react-tooltip/dist/react-tooltip.css"
import { useEffect } from "react"
import { clearSelectedEvent, getEventById } from "../reducers/eventSlice"
import {
  getTicketGroupById,
  clearSelectedTicketThumb,
  clearSelectedTicketGroup,
} from "../reducers/ticketSlice"
import { getCoupon } from "../helpers/getTokenAccounts"
import { AngleLeft, Info, Ticket, CheckCircle } from "../css/icons"
import { useWallet } from "@solana/wallet-adapter-react"
import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  PDA,
} from "@captainxyz/solana-core"
import { AuctionHouseAccount } from "@captainxyz/marketplace"
import postToSlack from "../postToSlack"
import { hasSolanaChip } from "../helpers/getTokenAccounts"
import { setUSDCBalance, setUser } from "../reducers/userSlice"
import { getTokenBalance } from "../helpers/getTokenAccounts"

import SignInModal from "../Authentication/SignInModal"
import SelectDeliveryMethod from "./SelectDeliveryMethod"
import PaymentMethodBox from "./PaymentMethodBox"
import StripeStuff from "./StripeStuff"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const {
  PublicKey,
  Connection,
  Transaction,
  VersionedTransaction,
} = require("@solana/web3.js")
const Base58 = require("base58")
const bs58 = require("bs58")
const { formatCents } = require("../helpers/money")

const CheckoutPage = () => {
  const query = new URLSearchParams(window.location.search)
  const _quantity = query.get("quantity")

  const [quantity, setQuantity] = useState(_quantity)
  const [loading, setLoading] = useState(false)
  const [buying, setBuying] = useState(false)
  const [minting, setMinting] = useState(false)
  const [minted, setMinted] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [refreshTimeout, setRefreshTimeout] = useState(null)
  const [showSigninModal, setShowSigninModal] = useState(false)
  const [coupon, setCoupon] = useState(null)
  const [deliveryMethod, setDeliveryMethod] = useState(null)
  const [seatsSelected, setSeatsSelected] = useState(false)
  const [stripeClientSecret, setStripeClientSecret] = useState(null)
  const [paymentIntentId, setPaymentIntentId] = useState(null)

  const addCardRef = useRef()
  const stripeRef = useRef()

  const coinflowEnabled =
    window.location.href.indexOf("localhost") > -1 ||
    window.location.href.indexOf("alpha") > -1
      ? true
      : false

  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const adapter = useWallet()

  const selectedEvent = useSelector((state) => state.events.selectedEvent)
  const user = useSelector((state) => state.user.user)
  const selectedTicketGroup = useSelector(
    (state) => state.tickets.selectedTicketGroup
  )

  const usdcBalance = useSelector((state) => state.user.usdc)

  // TODO verify quantity is valid
  const revision = params.revision
  const ticket_group_id = params.ticket_group_id

  /*
  const checkForCoupon = async () => {
    if (window.checkedForCouponInCheckout) return
    if (!(selectedTicketGroup?.cost * quantity)) {
      setTimeout(() => checkForCoupon(), 100)
      return
    }

    window.checkedForCouponInCheckout = true
    let coupon = await getCoupon(user, selectedTicketGroup?.cost * quantity)
    if (coupon) {
      setCoupon(coupon)
    }
  }
  */

  // todo: add an endpoint that allows you to get a ticket group by id or by mint so that
  // if you reload this page without coming from the event, it can still fetch the correct
  // ticket group data. this probably isn't that relevant for the user but will make development
  // much easier
  useEffect(() => {
    window.scrollTo(0, 0)

    setTimeout(
      () =>
        postToSlack(
          `is on the checkout page ${selectedEvent?.title}`,
          "firehose",
          user
        ),
      1000
    )
    if (!selectedEvent) {
      dispatch(getEventById(params.event_id))
    }

    if (selectedTicketGroup) getPaymentIntentId()

    window.TICKET_GROUP_ID = params.ticket_group_id
    return () => {
      clearTimeout(refreshTimeout)
      dispatch(clearSelectedTicketThumb())
      window.TICKET_GROUP_ID = null
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedTicketGroup?.nft_uuid && !window.updatedPaymentIntentLog) {
      updatePaymentIntentLog()
      window.updatedPaymentIntentLog = true
    }

    if (selectedTicketGroup) {
      checkIfTicketIsAvailable()
      getPaymentIntentId()
    }
  }, [selectedTicketGroup]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let orderId = location?.state?.orderId
    if (orderId && user?.loginMethod !== "phone") {
      checkForRebateToken(orderId)
    }

    return () => {
      // get rid of these when you leave this screen
      dispatch(clearSelectedEvent())
      dispatch(clearSelectedTicketThumb())
      dispatch(clearSelectedTicketGroup())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // mark them authenticated and also note in local storage
    // that they're in a campaign so that if they navigate away
    // they will be reauthenticated

    localStorage.setItem("phantom_browser", "yes")

    if (user && !user.authenticated) {
      let u2 = JSON.parse(JSON.stringify(user))
      u2.authenticated = true
      dispatch(setUser(u2))
    }

    if (user?.publicKey && usdcBalance === null) {
      getUsdcBalance()
    }

    if (user?.loginMethod === "phone" && selectedPaymentMethod === null) {
      setSelectedPaymentMethod("cc")
    }

    /*
    if (user?.publicKey) {
      checkForCoupon()
    }
    */
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if the user wasn't logged in, seats selected will still be set
    // in this case, we want to mint the nft since it wouldn't have been called
    // when the user first clicked it

    if (!user?.publicKey) {
      return
    }

    // but this should not run when the user is already logged in
    if (
      history.location.pathname.includes("/tickets") &&
      seatsSelected &&
      !minting &&
      !minted
    ) {
      mintNFT()
    }
  }, [seatsSelected, user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      window.checkedForCoupon = false
      window.updatedPaymentIntentLog = false
      window.checkedIfAvailable = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      user?.loginMethod !== "phone" &&
      usdcBalance !== null &&
      selectedTicketGroup &&
      !selectedPaymentMethod
    ) {
      if (
        usdcBalance > (selectedTicketGroup.cost / 100) * quantity &&
        !selectedPaymentMethod
      ) {
        setSelectedPaymentMethod("usdc")
      } else {
        setSelectedPaymentMethod("cc")
      }
    }
  }, [user, usdcBalance, selectedTicketGroup]) // eslint-disable-line react-hooks/exhaustive-deps

  const getPaymentIntentId = async () => {
    if (paymentIntentId) return

    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/create-payment-intent`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      total: selectedTicketGroup.cost * quantity,
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    resp = await resp.json()
    setPaymentIntentId(resp.payment_intent_id)
    setStripeClientSecret(resp.client_secret)
  }

  const price = () => {
    return selectedTicketGroup?.cost * quantity
  }

  const updatePaymentIntentLog = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/update-payment-intent-log`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      payment_intent_id: paymentIntentId,
      nft_uuid: selectedTicketGroup.nft_uuid,
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    resp = await resp.json()
  }

  const transferNftAfterCreditCard = async () => {
    if (!selectedTicketGroup?.nft_uuid) {
      while (!selectedTicketGroup?.nft_uuid) {
        await sleep(1000)
      }
    }

    let url = `${process.env.REACT_APP_EXPRESS_API}/api/xferStripeNFT`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      nft_uuid: selectedTicketGroup.nft_uuid,
      wallet: user.publicKey.toString(),
      payment_intent_id: paymentIntentId,
      deliveryMethod: deliveryMethod,
      coupon_mint: coupon?.mint.address.toString(),
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })

      resp = await resp.json()
      return resp.success
    } catch (err) {
      return false
    }
  }

  const checkIfTicketIsAvailable = async () => {
    if (selectedTicketGroup?.vendor !== "tevo") return
    if (window.checkedIfAvailable) return

    window.checkedIfAvailable = true
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/ticket-availability`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      ticket_group_id: selectedTicketGroup.id,
      quantity: quantity,
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    resp = await resp.json()
    if (!resp.available) {
      alert("This ticket is no longer available")
      history.goBack()
    }
  }

  const getUsdcBalance = async () => {
    let balance = await getTokenBalance(
      user,
      "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
    )
    dispatch(setUSDCBalance(balance))
  }

  const pollUsdcBalance = async () => {
    getUsdcBalance()
    setTimeout(() => pollUsdcBalance(), 2000)
  }

  const recordBought = async (address, coinflow_log_id) => {
    let url = `${process.env.REACT_APP_HNGR_API}/stagehand/bought`
    let headers = {
      "Content-Type": "application/json",
    }

    // TODO: backend handle nft_uuid
    let params = {
      sold_to: address,
      nft_uuid: selectedTicketGroup.nft_uuid,
      delivery_method: deliveryMethod,
      email: user?.user?.email,
      first_name: user?.user?.first_name,
      last_name: user?.user?.last_name,
      coupon_mint: coupon?.mint.address.toString(),
      currency: selectedPaymentMethod,
      promo: calculateDiscount(coupon),
      revision: selectedTicketGroup.revision,
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })

      resp = await resp.json()
      if (resp.success) {
        return resp.order_id
      } else {
        alert(
          "An unknown error occurred. If the problem persists, please reach out to support@xp.xyz."
        )
        history.push("/")
      }
    } catch (err) {
      console.log("checkout error", err)
      alert(
        "An unknown error occurred. If the problem persists, please reach out to support@xp.xyz."
      )
      history.push("/")
    }
  }

  const mintNFT = async () => {
    postToSlack(`minting nft ${selectedEvent?.title}`, "firehose", user)

    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/ticketgroup/psuedo-mint-nft`
    let headers = {
      "Content-Type": "application/json",
    }

    let body = {
      event_id: params.event_id,
      ticket_group_id: params.ticket_group_id,
      revision: revision,
      quantity: quantity,
    }

    try {
      setMinting(true)

      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(body),
      })
      resp = await resp.json()
      if (!resp.success) {
        alert(resp.message)
        return
      }
      refresh()
      setMinted(true)
      setMinting(false)
      if (history.location.pathname.includes("tickets")) {
        history.push(
          `/checkout/${params.event_id}/${params.ticket_group_id}/${revision}/delivery`
        )
      }
    } catch (err) {
      let msg = `${user.publicKey.toString()} (${
        user.loginMethod
      }) had a minting error an NFT for ${selectedEvent.title}!`
      alert("Error minting")
    }
  }

  const refresh = async () => {
    if (params.success) return
    if (selectedTicketGroup?.nft_uuid) return
    window.refreshCount += 1
    // every 5 seconds... so three minutes is 36
    if (window.refreshCount > 36) {
      alert(
        "An unexpected error occured while minting your tickets. Please try again"
      )
      //window.location.reload()
    }
    if (
      !window.TICKET_GROUP_ID ||
      window.TICKET_GROUP_ID !== params.ticket_group_id
    ) {
      return
    }

    dispatch(
      getTicketGroupById({
        ticket_group_id: ticket_group_id,
        quantity: quantity,
        revision: revision,
      })
    )

    if (!selectedTicketGroup?.nft_uuid) {
      if (!refreshTimeout) {
        let timeout = setTimeout(() => refresh(), 5000)
        setRefreshTimeout(timeout)
      }
    }
  }

  const updateInstantNft = async () => {
    setLoading(true)
    let data = {
      mint: selectedTicketGroup.mint,
    }

    let url = `${process.env.REACT_APP_EXPRESS_API}/api/fakeUnsealInstantTransfer`

    let headers = {
      "Content-Type": "application/json",
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(data),
    })
    setLoading(false)
    resp = await resp.json()
    if (resp.error) {
      alert("Unknown error.")
    }
    return true
  }

  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2")
  }

  const checkoutWithUSDC = async () => {
    setBuying(true)
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/buyTicketWithUSDC`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      nft_uuid: selectedTicketGroup.nft_uuid,
      wallet: user.publicKey.toString(),
      deliveryMethod: deliveryMethod,
      couponMint: coupon?.mint.address.toString(),
    }

    if (user?.loginMethod === "phone") {
      params["diner_uuid"] = user.diner_uuid
      params["isPhoneWallet"] = true
      try {
        let resp = await fetch(url, {
          method: "post",
          headers: headers,
          body: JSON.stringify(params),
        })
        resp = await resp.json()
        if (resp.success) {
          return true
        } else {
          setBuying(false)
          alert("Error buying with USDC")
          return false
          // TODO else
        }
      } catch (err) {
        alert("Unknown error buying with USDC")
        setBuying(false)
        return false
      }
    } else {
      try {
        let resp = await fetch(url, {
          method: "post",
          headers: headers,
          body: JSON.stringify(params),
        })

        const connection = new Connection(process.env.REACT_APP_RPC) // eslint-disable-line no-unused-vars
        const {
          context: { slot: minContextSlot },
          value: { blockhash, lastValidBlockHeight },
        } = await connection.getLatestBlockhashAndContext()

        const data = await resp.json()
        const txEncoded = data.txn
        const txn = Transaction.from(Base64.toUint8Array(txEncoded))
        const signature = await adapter.sendTransaction(txn, connection, {
          minContextSlot,
        })
        let result = await connection.confirmTransaction(
          {
            blockhash,
            lastValidBlockHeight,
            signature,
          },
          "confirmed"
        )
        if (result) {
          return true
        } else {
          // TODO be smarter here
          alert("Buying may have failed. Check your wallet")
        }
      } catch (err) {
        alert("Unknown error")
        return false
      }
    }
  }

  const [hasRebateToken, setHasRebateToken] = useState(false)

  const checkForRebateToken = async (orderId) => {
    const hasToken = await hasSolanaChip({
      adapter: adapter,
      orderId: orderId,
    })
    setHasRebateToken(hasToken)
  }

  const sleep = (time) =>
    new Promise((res) => setTimeout(res, time, "done sleeping"))

  const checkoutWithStripe = async () => {
    setBuying(true)
    let success = await stripeRef.current.submitPayment()
    if (success) {
      let success = await transferNftAfterCreditCard()
      setBuying(false)
      if (success) {
        return true
      } else {
        alert("Error transferring nft")
        return false
      }
    } else {
      setBuying(false)
    }
  }

  const checkout = async () => {
    let success
    // send zero dollar orders through usdc flow
    if (calculateTotal(coupon) === 0) {
      success = await checkoutWithUSDC()
    } else if (selectedPaymentMethod == "cc") {
      success = await checkoutWithStripe()
    } else {
      success = await checkoutWithUSDC()
    }

    if (success) {
      let orderId = await recordBought(user.publicKey.toString())
      history.push({
        pathname: `/holder/ticket/${selectedTicketGroup?.mint}?success=true&quantity=${quantity}`,
        state: {
          orderId: orderId,
        },
      })
      ReactPixel.track("Purchase", {
        user: user?.publicKey?.toString(),
        ticketGroup: selectedTicketGroup?.id,
        value: price() / 100,
        currency: "USD",
      })
    }
  }

  const getNewCardToken = async () => {
    const { token } = await addCardRef.current.getToken().catch((e) => {
      alert("Something went wrong.")
    })
    return token
  }

  // note we are passing in coupon_ manually and not using the state one
  const calculateDiscount = (coupon) => {
    if (!coupon) return 0
    if (coupon) {
      let discountType = coupon.metadata._json.discount_type
      let discount
      if (discountType === "percent") {
        discount =
          coupon.metadata._json.discount * quantity * selectedTicketGroup?.cost
      }

      if (discountType === "flat") {
        discount = coupon.metadata._json.discount
      }
      return Math.min(discount, price())
    }
  }

  // note we are passing in coupon_ manually and not using the state one
  const calculateTotal = (coupon_) => {
    let subtotal = price()
    let discount = calculateDiscount(coupon_)
    let total = subtotal - discount
    return total
  }

  const stripeOptions = () => {
    if (!stripeClientSecret) return {}
    return {
      clientSecret: stripeClientSecret,
    }
  }

  const removeCoupon = async () => {
    setCoupon(null)
    setLoading(true)

    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/remove-coupon-payment-intent`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      total: calculateTotal(),
      payment_intent_id: paymentIntentId,
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      setLoading(false)
    } catch (err) {
      alert("Unknown error applying coupon")
      setCoupon(null)
      setLoading(false)
    }
  }

  const applyCoupon = async (coupon) => {
    setCoupon(coupon)
    setLoading(true)

    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/apply-coupon-payment-intent`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      total: calculateTotal(coupon),
      payment_intent_id: paymentIntentId,
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      setLoading(false)
      return true
    } catch (err) {
      alert("Unknown error applying coupon")
      setCoupon(null)
      setLoading(false)
      return false
    }
  }

  const renderCheckout = () => {
    return (
      <div className={classNames(styles.container)}>
        {calculateTotal(coupon) === 0 ? (
          <div> </div>
        ) : (
          <>
            <div className={styles.paymentMethodBoxRow}>
              <PaymentMethodBox
                type="cc"
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />
              <PaymentMethodBox
                type="usdc"
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />
            </div>
            {selectedPaymentMethod === "cc" && paymentIntentId && (
              <div className={styles.stripeContainer}>
                <Elements stripe={stripePromise} options={stripeOptions()}>
                  {selectedPaymentMethod === "cc" && (
                    <StripeStuff
                      ref={stripeRef}
                      clientSecret={stripeClientSecret}
                    />
                  )}
                </Elements>
              </div>
            )}
            {selectedPaymentMethod === "usdc" && (
              <>
                <div className={styles.sufficientUSDC}>
                  <div className={styles.walletInfo}>
                    <span>My USDC Balance</span>
                    <span className={styles.walletBalance}>${usdcBalance}</span>
                  </div>
                  {usdcBalance <
                  (quantity * selectedTicketGroup?.cost) / 100 ? (
                    <CoinbasePay
                      onClick={() => pollUsdcBalance()}
                      cost={quantity * selectedTicketGroup?.cost}
                    />
                  ) : (
                    <div className={styles.saveWithUSDC}>
                      <div className={styles.saveWithUSDCContent}>
                        {/* <div className={styles.saveWithUSDCHeader}>
                          3% off with USDC
                        </div> */}
                        <div className={styles.saveWithUSDCSubheader}>
                          Your USDC balance covers this purchase
                        </div>
                      </div>
                      <CheckCircle />
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}

        <div className={styles.help}>
          Need help? <a href="mailto:support@xp.xyz">Contact Support</a>
        </div>

        {/* total/price box thing */}
        <div className={styles.buttonContainer}>
          <Button
            className={styles.checkoutButton}
            loading={loading || buying || !selectedTicketGroup?.nft_uuid}
            fullWidth
            variant="blue"
            onClick={() => {
              checkout()
            }}
          >
            Purchase Tickets
          </Button>
        </div>
      </div>
    )
  }

  // the user has not yet minted the ticket
  // can be logged in or not, will be prompted to log in before continuing
  // this is its own view because the sidebar is different, all others here share
  // one sidebar
  if (
    history.location.pathname.includes("tickets") ||
    (!selectedTicketGroup?.nft_uuid && !minting)
  ) {
    return (
      <>
        <SeatPreview
          updateQuantity={(qty) => {
            let base = history.location.pathname
            history.replace(`${base}?quantity=${qty}`)
            setQuantity(qty)
          }}
          onClick={(q) => {
            postToSlack("has selected seats", "firehose", user)
            setSeatsSelected(true)
            if (!user?.publicKey) {
              setShowSigninModal(true)
            } else {
              if (minted) {
                history.push(
                  `/checkout/${params.event_id}/${params.ticket_group_id}/${revision}/delivery`
                )
              } else {
                mintNFT()
              }
            }
          }}
        />

        {showSigninModal && (
          <SignInModal
            onClose={() => {
              setShowSigninModal(false)
            }}
          />
        )}
      </>
    )
  }

  let mapVendor, mapConfig
  if (selectedEvent?.seatmap_id_3ddv) {
    mapVendor = "3ddv"
    mapConfig = {
      venueId: selectedEvent.seatmap_id_3ddv,
    }
  } else if (
    selectedEvent?.tevo_venue_id &&
    selectedEvent?.tevo_venue_config_id
  ) {
    mapVendor = "tevo"
    mapConfig = {
      venueId: selectedEvent?.tevo_venue_id,
      configurationId: selectedEvent?.tevo_venue_config_id,
    }
  }

  let ticketGroupFormatted = {
    id: selectedTicketGroup?.id,
    section_vendor: selectedTicketGroup?.section_vendor || "",
  }

  const totalForPromo = selectedTicketGroup?.cost * quantity

  return (
    <Layout className={styles.topLevelContainer} noScroll>
      <div className={styles.pageContainerOuter}>
        <div className={styles.leftColumn}>
          <div className={styles.back} onClick={() => history.goBack()}>
            <AngleLeft />
            <span>Back</span>
          </div>
          <div className={styles.showInfo}>
            <img src={selectedEvent?.image} alt={selectedEvent?.title} />
            <div className={styles.showInfoHeader}>
              <div className={styles.showInfoContent}>
                <div className={styles.date}>
                  {selectedEvent?.date_formatted
                    ?.replace(",", " •")
                    .replace("•", " •")}
                </div>
                <div className={styles.title}>
                  {selectedEvent?.short_title || selectedEvent?.title}
                </div>
                <div className={styles.venue}>
                  {selectedEvent?.venue_name}, {selectedEvent?.venue_city},{" "}
                  {selectedEvent?.venue_state}
                  <Info />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.seatInfoContainer}>
            <div className={styles.seatInfo}>
              <div className={styles.section}>
                Section
                <span className={styles.value}>
                  {selectedTicketGroup?.section}
                </span>
              </div>
              <div className={styles.divider} />

              <div className={styles.row}>
                Row
                <span className={styles.value}>{selectedTicketGroup?.row}</span>
              </div>
            </div>
            <div className={styles.ticketInfoRight}>
              <div className={styles.ticketQuantityContainer}>
                <Ticket /> {quantity}
              </div>
              <div className={styles.ticketPrice}>
                <span>${(selectedTicketGroup?.cost / 100).toFixed(2)}</span>
                ea.
              </div>
            </div>
          </div>
          {deliveryMethod && history.location.pathname.includes("payment") && (
            <>
              <Promo
                total={totalForPromo}
                removeCoupon={() => {
                  removeCoupon()
                  // todo remove coupon function for payment intent id
                }}
                setCoupon={applyCoupon}
              />
              <div
                className={classNames(
                  styles.cardBottom,
                  !coupon && styles.noCoupon
                )}
              >
                <div className={styles.cardContent}>
                  <div className={styles.cardTitle}>Subtotal</div>
                  <div className={styles.subtotal}>
                    {quantity} x ${(selectedTicketGroup?.cost / 100).toFixed(2)}
                  </div>
                </div>
                {coupon && (
                  <div className={styles.cardContent}>
                    <div
                      className={classNames(styles.cardTitle, styles.discount)}
                    >
                      Discount
                    </div>
                    <div className={styles.discount}>
                      -{coupon && formatCents(calculateDiscount(coupon))}
                    </div>
                  </div>
                )}
                <div className={styles.cardContent}>
                  <div className={classNames(styles.cardTitle, styles.total)}>
                    Total
                  </div>
                  <div className={styles.total}>
                    {formatCents(calculateTotal(coupon))}{" "}
                  </div>
                </div>
                <div className={styles.taxDisclaimer}>
                  Sales Tax and Applicable Fees included in Total
                </div>
              </div>
              {renderCheckout()}
            </>
          )}
          {history.location.pathname.includes("delivery") && (
            <SelectDeliveryMethod
              setDeliveryMethod={setDeliveryMethod}
              minting={deliveryMethod && minting}
              revision={revision}
            />
          )}
        </div>
        {/* end left sidebar */}
        <div className={styles.checkoutContainer}>
          <div className={styles.seatmap}>
            {mapVendor === "3ddv" && (
              <SeatMap
                config={mapConfig}
                ticketGroups={[selectedTicketGroup]}
                zoomedTicketGroup={selectedTicketGroup}
                highlightedSections={[]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
              />
            )}
            {mapVendor === "tevo" && (
              <SeatMapTevo
                config={mapConfig}
                ticketGroups={[ticketGroupFormatted]}
                zoomedTicketGroup={ticketGroupFormatted}
                highlightedSections={[]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
              />
            )}
          </div>
        </div>
      </div>
      {showSigninModal && (
        <SignInModal
          onClose={() => {
            setShowSigninModal(false)
            mintNFT()
          }}
        />
      )}
    </Layout>
  )
}

export default CheckoutPage
