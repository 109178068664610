import { useEffect, useState } from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import classNames from "classnames"
import { useParams, useHistory } from "react-router-dom"
import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  PDA,
} from "@captainxyz/solana-core"

// DO NOT DELETE THESE OR THE WHOLE THING WON'T WORK FOR SOME REASON
import {
  isTamperproofHolder, // eslint-disable-line no-unused-vars
  isTamperproofOracle, // eslint-disable-line no-unused-vars
} from "@captainxyz/tamperproof"

import { PublicKey } from "@solana/web3.js"
import { Button, Layout } from "../components"
import Loading from "../Loading/Loading"
import ErrorModal from "./ErrorModal"
import UnsealModal from "./UnsealModal"
import { useDispatch, useSelector } from "react-redux"
import styles from "./NFT.module.scss"
import {
  AngleLeft,
  CheckCircleGreenTransparent,
  Send,
  Ticket,
  Warning,
} from "../css/icons"

import postToSlack from "../postToSlack"
import * as Sentry from "@sentry/browser"
import { getEventFromMint } from "../reducers/eventSlice"
import SendToAnotherAttendeeModal from "./SendToAnotherAttendeeModal"
import { formatCents } from "../helpers/money"
import { getTicketGroupById } from "../reducers/ticketSlice"

const NFT = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [token, setToken] = useState(false)
  const [sealed, setSealed] = useState(null)
  const [unsealed, setUnsealed] = useState(null)
  const [showUnsealModal, setShowUnsealModal] = useState(false)
  const [showSendToAnotherAttendeeModal, setShowSendToAnotherAttendeeModal] =
    useState(false)
  const [showUnsealingModal, setShowUnsealingModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [unsealSuccess, setUnsealSuccess] = useState(false)
  const [price, setPrice] = useState(null)
  const [order, setOrder] = useState(null)
  const user = useSelector((state) => state.user.user)
  const selectedEvent = useSelector((state) => state.events.selectedEvent)
  const selectedTicketGroup = useSelector(
    (state) => state.tickets.selectedTicketGroup
  )
  const [email, setEmail] = useState(user?.email || null)

  const { mint } = useParams()
  const adapter = useWallet()
  const dispatch = useDispatch()
  const history = useHistory()

  const mintString = mint.toString().split("?")[0]

  const query = new URLSearchParams(window.location.search)
  const success = query.get("success")

  // on load, check for success param to show success banner
  // on load, fetch event from nft mint

  useEffect(() => {
    window.scrollTo(0, 0)
    getTicketStatus()
    getOrder()
    dispatch(getEventFromMint(mintString))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (order) {
      // checkForRebateToken()
      dispatch(getTicketGroupById({ ticket_group_id: order.ticket_group_id }))
    }
  }, [order]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?.publicKey) {
      getToken()
    }
    if (user?.first_name) {
      setFirstName(user?.first_name)
    }
    if (user?.last_name) {
      setLastName(user?.last_name)
    }
    if (user?.email) {
      setEmail(user?.email)
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (adapter.signMessage) {
      if (adapter.publicKey) {
        postToSlack(
          `${adapter.publicKey.toString()} is viewing NFT ${mintString}`
        )
      }
    }
  }, [adapter.signMessage]) // eslint-disable-line react-hooks/exhaustive-deps

  // const checkForRebateToken = async () => {
  //   if (!adapter?.publicKey) return false
  //   const hasToken = await hasSolanaChip({
  //     adapter: adapter,
  //     orderId: order.id,
  //   })
  //   setHasRebateToken(hasToken)
  // }

  const getTicketStatus = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/ticket-is-pending`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      mint: mintString,
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      resp = await resp.json()
      if (resp.pending) {
        setEmail(resp.email)
        setPrice(resp.price)
      } else {
        setPrice(resp.price)
      }
    } catch (err) {
      console.log(err, "Error in get status")
    }
  }

  const getOrder = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/get-order`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      mint: mintString,
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      resp = await resp.json()
      setOrder(resp)
    } catch (err) {
      console.log(err, "Error in get-order")
      // alert("err")
    }
  }

  const getToken = async () => {
    if (!user?.publicKey) return
    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })
    let operator
    if (user.loginMethod === "wallet") {
      const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
      operator = new Operator("mainnet-beta", walletAdapterIdentity)
    } else {
      operator = new Operator("mainnet-beta")
    }

    let pubKey = new PublicKey(user.publicKey)
    let tokenAddress = PDA.token(new PublicKey(mintString), pubKey)
    let token
    try { 
      token = await TokenAccount.init(operator, tokenAddress)
    } catch (err) {
      console.log("token not available yet...")
      setTimeout(()=>getToken(), 300) 
      return
    }

    if (token.canRequestUnsealing) {
      // eslint-disable-next-line eqeqeq
      if (token.mint.address.toString() == mintString) {
        setToken(token)
        setSealed(true)
        return
      }
    }
    if (token.canDecrypt) {
      // eslint-disable-next-line eqeqeq
      if (token.mint.address.toString() == mintString) {
        setToken(token)
        setUnsealed(true)
        return
      }
    }

    // instant transfer
    if (!token.metadata._json.secret) {
      setToken(token)
      setUnsealed(true)
      return
    }
  }

  const unsealWithAdapter = async (email) => {
    // if we somehow got here with an already unsealed one
    if (token.canDecrypt) {
      setShowUnsealingModal(false)
      return
    }

    let a
    try {
      a = await token?.requestUnsealing()
    } catch (err) {
      Sentry.captureException(err)
      if (err.message && err.message.indexOf("has not been authorized") > -1) {
        alert("There may be an issue with Phantom. Please refresh the page.")
      }
      Sentry.configureScope((scope) => {
        Sentry.captureMessage("Bug with unsealing")
      })
      setShowUnsealingModal(false)
      setShowErrorModal(true)
      return
    }
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/tamperproofRequestReveal`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      wallet: adapter.publicKey.toString(),
      sig: a.signature,
      mint: token.mint.address.toString(),
      oracle_id: token.metadata._json.oracle_id,
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      resp = await resp.json()
      if (resp.success) {
        await getToken()
        setUnsealSuccess(true)
        setShowUnsealingModal(false)
        postToSlack(
          `${adapter.publicKey.toString()} succesfully unsealed for ${mintString}`
        )
        // todo: make these the same variable, because having two is gross
        setUnsealed(true)
        setSealed(false)
        window.scrollTo(0, 0)
        decrypt(email)
      } else {
        postToSlack(
          `${adapter.publicKey.toString()} captured unsealed error for ${mintString}`
        )
        setShowUnsealingModal(false)
        setShowErrorModal(true)
      }
    } catch (err) {
      console.log("Error unsealing", err)
      postToSlack(
        `${adapter.publicKey.toString()} unkown unsealed error for ${mintString}`
      )
      setShowUnsealingModal(false)
      setShowErrorModal(true)
    }
  }

  const unsealServerSide = async (email) => {
    let data = {
      diner_uuid: user.diner_uuid,
      mint: mintString,
    }
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/ticketdexRequestUnseal`

    let headers = {
      "Content-Type": "application/json",
    }

    let resp
    try {
      resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      resp = await resp.json()
    } catch (err) {
      setShowUnsealingModal(false)
      setShowErrorModal(true)
      return
    }
    if (resp.success) {
      setUnsealSuccess(true)
      setShowUnsealingModal(false)
      postToSlack(`${user.publicKey} succesfully unsealed for ${mintString}`)
      setUnsealed(true)
      setSealed(false)
      window.scrollTo(0, 0)
      decrypt(email)
    } else {
      setShowUnsealingModal(false)
      setShowErrorModal(true)
    }
  }

  const unseal = async () => {
    setShowErrorModal(false)
    setShowUnsealModal(false)
    setShowUnsealingModal(true)
    postToSlack(`${user.publicKey} is requesting unseal for ${mintString}`)
    if (user.loginMethod === "wallet") unsealWithAdapter()
    else unsealServerSide()
  }

  const decrypt = async () => {
    let secret
    if (user.loginMethod === "wallet") {
      try {
        secret = await token.decrypt()
      } catch (err) {
        Sentry.configureScope((scope) => {
          Sentry.captureMessage("Bug with decrypting from nft page")
        })
        Sentry.captureException(err)
        if (
          err.message &&
          err.message.indexOf("has not been authorized") > -1
        ) {
          alert("There may be an issue with Phantom. Please refresh the page.")
        }
        return
      }
      postToSlack(
        `${adapter.publicKey.toString()} decrypted from nft page for ${mintString}`
      )
      submitCode(secret, email)
    } else {
      let data = {
        diner_uuid: user.diner_uuid,
        mint: mintString,
      }
      let url = `${process.env.REACT_APP_EXPRESS_API}/api/ticketdexDecrypt`

      let headers = {
        "Content-Type": "application/json",
      }

      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      resp = await resp.json()
      submitCode(resp.secret, email)
    }
  }

  const submitCode = async (code, email) => {
    let url = `${process.env.REACT_APP_HNGR_API}/ticketdex/claim-code`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      code: code,
      email: email,
      mint:mintString,
      first_name: firstName,
      last_name: lastName,
      wallet: user.publicKey.toString(),
    }
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
  }

  // const calculateRebateAmount = () => {
  //   if (!price) return ""
  //   else {
  //     let rebate = 0.8 * price
  //     if (rebate > 200) rebate = 200
  //     return rebate.toFixed(2)
  //   }
  // }

  const venueState =
    token?.metadata?._json.attributes?.find(
      (attr) => attr.trait_type === "Venue State"
    )?.value ||
    token?.metadata?._json.attributes?.find(
      (attr) => attr.trait_type === "State"
    )?.value

  return (
    <>
      {showUnsealModal && (
        <UnsealModal
          close={() => {
            document.body.style.overflow = "auto"
            setShowUnsealModal(false)
          }}
          email={email}
          unseal={(email) => unseal(email)}
          setShowSendToAnotherAttendeeModal={setShowSendToAnotherAttendeeModal}
        />
      )}
      {showSendToAnotherAttendeeModal && (
        <SendToAnotherAttendeeModal
          close={() => setShowSendToAnotherAttendeeModal(false)}
          setShowUnsealModal={setShowUnsealModal}
          unseal={(email) => unseal(email)}
        />
      )}
      {showErrorModal && (
        <ErrorModal
          token={token}
          unseal={() => unseal()}
          close={() => setShowErrorModal(false)}
        />
      )}

      <Layout className={styles.pageContainer}>
        {showUnsealingModal ? (
          <>
            {unsealSuccess ? (
              <div className={styles.unsealLoading}>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/unlock.gif"
                  alt="Unsealed"
                />
              </div>
            ) : (
              <div className={styles.unsealLoading}>
                <img
                  src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
                  className={styles.spinner}
                  alt="spinner"
                />
                <h1>Ticket transfer initiated</h1>
                <p>Please hold on for a moment while we send your ticket.</p>
                <p>This may take up to one minute</p>
              </div>
            )}
          </>
        ) : (
          <>
            {token && (sealed || unsealed) ? (
              <div className={styles.container}>
                {success && (
                  <div className={styles.successBanner}>
                    <h1>Purchase Successful!</h1>
                    <CheckCircleGreenTransparent />
                  </div>
                )}

                <>
                  <div
                    className={styles.back}
                    onClick={() => history.push("/holder/tickets")}
                  >
                    <AngleLeft /> Back
                  </div>
                  {sealed ? (
                    <>
                      <div className={styles.headingWrapper}>
                        <h1
                          className={classNames(
                            styles.pageHeading,
                            styles.sealed
                          )}
                        >
                          Tickets sealed for security
                        </h1>
                        <Warning className={styles.sealed} />
                      </div>
                      <p className={styles.pageSubtitle}>
                        This is not your ticket. Please receive your tickets
                        below to access them or trade on a Solana Marketplace.{" "}
                      </p>
                    </>
                  ) : (
                    <>
                      <div className={styles.headingWrapper}>
                        <h1
                          className={classNames(
                            styles.pageHeading,
                            styles.unsealed
                          )}
                        >
                          Tickets transferred
                        </h1>
                        <Send className={styles.unsealed} />
                      </div>
                      <p className={styles.pageSubtitle}>
                        Your tickets have been transferred to your email.
                      </p>
                    </>
                  )}
                </>

                <div className={styles.contentContainer}>
                  <div className={styles.contentLeft}>
                    <div className={styles.showInfo}>
                      <img
                        className={styles.showImage}
                        src={selectedEvent?.image}
                        alt={selectedEvent?.short_title}
                      />
                      <div className={styles.showDetails}>
                        <div className={styles.showDetailsLeft}>
                          <div className={styles.date}>
                            {selectedEvent?.date_formatted.slice(0, 3)} |{" "}
                            {selectedEvent?.date_formatted.slice(5, 11)} |{" "}
                            {selectedEvent?.date_formatted.split("•")[1]}
                          </div>
                          <div className={styles.title}>
                            {selectedEvent?.short_title || selectedEvent?.title}
                          </div>
                          <div className={styles.venue}>
                            {selectedEvent?.venue_name ||
                              token.metadata._json.attributes?.find(
                                (attr) => attr.trait_type === "Venue Name"
                              )?.value ||
                              token.metadata._json.attributes?.find(
                                (attr) => attr.trait_type === "Venue"
                              )?.value}{" "}
                            •{" "}
                            {selectedEvent?.venue_city ||
                              token.metadata._json.attributes?.find(
                                (attr) => attr.trait_type === "City"
                              )?.value}{" "}
                            • {selectedEvent?.venue_state || venueState}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.seatInfoContainer}>
                      <div className={styles.seatInfo}>
                        <div className={styles.section}>
                          Section
                          <span className={styles.value}>
                            {
                              token.metadata._json.attributes?.find(
                                (attr) => attr.trait_type === "Section"
                              )?.value
                            }{" "}
                          </span>
                        </div>
                        <div className={styles.divider} />

                        <div className={styles.row}>
                          Row
                          <span className={styles.value}>
                            {
                              token.metadata._json.attributes?.find(
                                (attr) => attr.trait_type === "Row"
                              )?.value
                            }{" "}
                          </span>
                        </div>
                      </div>
                      <div className={styles.ticketInfoRight}>
                        <div className={styles.ticketQuantityContainer}>
                          <Ticket /> {order?.quantity}{" "}
                          {window.innerWidth > 960 && (
                            <>{order?.quantity > 1 ? "Tickets" : "Ticket"}</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {window.innerWidth < 960 && (
                    <>
                      {sealed ? (
                        <Button
                          variant="blue"
                          onClick={() => setShowUnsealModal(true)}
                          rightIcon={<Send />}
                          className={styles.receiveButton}
                        >
                          Receive My Tickets
                        </Button>
                      ) : (
                        <Button
                          variant="gray"
                          className={classNames(
                            styles.receiveButton,
                            styles.secondaryButton
                          )}
                          onClick={() => history.push("/events")}
                        >
                          Browse More Events
                        </Button>
                      )}
                    </>
                  )}
                  <div className={styles.contentRight}>
                    <div className={styles.orderMetadataRow}>
                      <span>
                        {new Date(order?.created_at).toLocaleString("en-US", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                        })}
                      </span>
                      <span>Order #{order?.id}</span>
                    </div>
                    <div className={styles.orderPrice}>
                      <div
                        className={classNames(styles.priceRow, styles.subtotal)}
                      >
                        <span>Subtotal</span>
                        <span>
                          {order?.quantity} x{" "}
                          {formatCents(selectedTicketGroup?.cost)}
                        </span>
                      </div>
                      {order?.promo && (
                        <div
                          className={classNames(
                            styles.priceRow,
                            styles.discount
                          )}
                        >
                          <span>Discount</span>
                          <span>- {formatCents(order?.promo)}</span>
                        </div>
                      )}
                      <div
                        className={classNames(styles.priceRow, styles.total)}
                      >
                        <span>Total</span>
                        <span>
                          {order?.promo
                            ? formatCents(order?.price - order?.promo)
                            : formatCents(order?.price)}
                        </span>
                      </div>
                      <div className={styles.disclaimer}>
                        Sales tax and applicable fees included in total
                      </div>
                    </div>
                  </div>
                </div>
                {window.innerWidth > 960 && (
                  <>
                    {sealed ? (
                      <Button
                        variant="blue"
                        onClick={() => setShowUnsealModal(true)}
                        rightIcon={<Send />}
                        className={styles.receiveButton}
                      >
                        Receive My Tickets
                      </Button>
                    ) : (
                      <Button
                        variant="gray"
                        className={classNames(
                          styles.receiveButton,
                          styles.secondaryButton
                        )}
                        onClick={() => history.push("/events")}
                      >
                        Browse More Events
                      </Button>
                    )}
                  </>
                )}
              </div>
            ) : (
              <Loading />
            )}
          </>
        )}
      </Layout>
    </>
  )
}

export default NFT
