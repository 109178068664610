import { Button, Layout, SeatMap, SeatMapTevo, Select } from "../components"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import styles from "./SeatPreview.module.scss"
import { AngleLeft, Info } from "../css/icons"
import { getTicketGroupById } from "../reducers/ticketSlice"
import { getEventById } from "../reducers/eventSlice"
import { getCoupon } from "../helpers/getTokenAccounts"
import classNames from "classnames"

const { formatCents } = require("../helpers/money")

const SeatPreview = ({ onClick, updateQuantity }) => {
  const user = useSelector((state) => state.user.user)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const query = new URLSearchParams(window.location.search)
  const quantity = query.get("quantity")
  const [selectedQuantity, setSelectedQuantity] = useState(quantity || 2)
  const [coupon, setCoupon] = useState(null)

  useEffect(() => {
    dispatch(getEventById(params.event_id))
    dispatch(getTicketGroupById({ ticket_group_id: params.ticket_group_id }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateQuantity(selectedQuantity)
  }, [selectedQuantity]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      checkForCoupon()
    }
  }, [user?.publicKey]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectedEvent = useSelector((state) => state.events.selectedEvent)
  const selectedTicketGroup = useSelector(
    (state) => state.tickets.selectedTicketGroup
  )

  let mapVendor, mapConfig
  if (selectedEvent?.seatmap_id_3ddv) {
    mapVendor = "3ddv"
    mapConfig = {
      venueId: selectedEvent.seatmap_id_3ddv,
    }
  } else if (
    selectedEvent?.tevo_venue_id &&
    selectedEvent?.tevo_venue_config_id
  ) {
    mapVendor = "tevo"
    mapConfig = {
      venueId: selectedEvent?.tevo_venue_id,
      configurationId: selectedEvent?.tevo_venue_config_id,
    }
  }

  const arrayRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    )

  let ticketGroupFormatted = {
    id: selectedTicketGroup?.id,
    section_vendor: selectedTicketGroup?.section_vendor || "",
  }

  const checkForCoupon = async () => {
    if (window.checkedForCouponInSeatPreview) return

    window.checkedForCouponInSeatPreview = true
    let c = await getCoupon(user, 999999999)
    if (c?.coupon) {
      setCoupon(c)
    }
  }

  return (
    <Layout className={styles.container} noScroll>
      <div className={styles.containerInner}>
        <div className={styles.leftColumn}>
          <div className={styles.back} onClick={() => history.goBack()}>
            <AngleLeft />
            <span>Back</span>
          </div>
          <div className={styles.showInfo}>
            <img src={selectedEvent?.image} alt={selectedEvent?.title} />
            <div className={styles.showInfoHeader}>
              <div className={styles.showInfoContent}>
                <div className={styles.date}>
                  {selectedEvent?.date_formatted
                    ?.replace(",", " •")
                    .replace("•", " •")}
                </div>
                <div className={styles.title}>
                  {selectedEvent?.short_title || selectedEvent?.title}
                </div>
                <div className={styles.venue}>
                  {selectedEvent?.venue_name}, {selectedEvent?.venue_city},{" "}
                  {selectedEvent?.venue_state}
                  <Info />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.seatInfoContainer}>
            <div className={styles.seatInfo}>
              <div className={styles.section}>
                Section
                <span className={styles.value}>
                  {selectedTicketGroup?.section}
                </span>
              </div>
              <div className={styles.divider} />

              <div className={styles.row}>
                Row
                <span className={styles.value}>{selectedTicketGroup?.row}</span>
              </div>
            </div>
            <div className={styles.ticketQuantityContainer}>
              <span>Ticket Quantity</span>
              <div className={styles.cardQuantitySelect}>
                <div className={styles.selector}>
                  <Select
                    options={arrayRange(
                      2,
                      selectedTicketGroup?.ticket_quantity,
                      1
                    )}
                    selected={selectedQuantity}
                    setSelected={(value) => setSelectedQuantity(value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {coupon && (
            <div className={styles.couponContainer}>
              <div className={styles.couponHeader}>Reward Detected 💎</div>
              <div>apply it at checkout</div>
            </div>
          )}
          <div
            className={classNames(
              styles.cardBottom,
              !coupon && styles.noCoupon
            )}
          >
            <div className={styles.cardContent}>
              <div className={styles.cardTitle}>Subtotal</div>
              <div className={styles.subtotal}>
                {selectedQuantity} x $
                {(selectedTicketGroup?.cost / 100).toFixed(2)}
              </div>
            </div>
            <div className={styles.cardContent}>
              <div className={classNames(styles.cardTitle, styles.total)}>
                Total
              </div>
              <div className={styles.total}>
                {formatCents(selectedTicketGroup?.cost * selectedQuantity)}
              </div>
            </div>
            <div className={styles.taxDisclaimer}>
              Sales Tax and Applicable Fees included in Total
            </div>
          </div>

          <div className={styles.help}>
            Need help? <a href="mailto:support@xp.xyz">Contact Support</a>
          </div>

          <div className={styles.buttonContainer}>
            <Button
              variant="blue"
              fullWidth
              onClick={() => {
                onClick(selectedQuantity)
                setLoading(true)
              }}
              loading={loading}
              className={styles.button}
            >
              Continue to Checkout
            </Button>
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.venueImage}>
            {mapVendor === "3ddv" && (
              <SeatMap
                config={mapConfig}
                ticketGroups={[selectedTicketGroup]}
                zoomedTicketGroup={selectedTicketGroup}
                highlightedSections={[]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
              />
            )}
            {mapVendor === "tevo" && (
              <SeatMapTevo
                config={mapConfig}
                ticketGroups={[ticketGroupFormatted]}
                zoomedTicketGroup={ticketGroupFormatted}
                highlightedSections={[]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SeatPreview
