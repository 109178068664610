import { useEffect, useState, useRef } from "react"
import classNames from "classnames"
import { Button, Layout } from "../"
import styles from "./LocationPicker.module.scss"
import {
  AngleDown,
  ArrowDown,
  BrowseArrow,
  Calendar,
  CheckMark,
  HamburgerNav,
  LocationArrow,
  RedX,
  Search,
} from "../../css/icons"
import { useHistory, useParams } from "react-router-dom"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"

const LocationPicker = ({nearbyName, updateLocation}) => {
  const history = useHistory()

  const [location, setLocation] = useState("")
  const [results, setResults] = useState([])
  const [locationQuery, setLocationQuery] = useState([])
  const [nearby, setNearby] = useState(false)
  const [showLocationMenu, setShowLocationMenu] = useState(false)

  const locationMenuRef = useRef()
  useDetectClickOutside(locationMenuRef, () => {
    setShowLocationMenu(false)
    setResults([])
    setLocationQuery("")
  })

  useEffect(() => {
    updateLocation(location)
  }, [location])

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      getAutocompleteSuggestions(locationQuery)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [locationQuery])

  const getAutocompleteSuggestions = async (query) => {
    if (query === "") {
      setResults([])
      return
    }

    if (query?.length == 0){
      setResults([])
      return
    }

    try {
      const endpoint = "/api/stagehand/cities"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      setResults(response.cities)
    } catch (err) {
      console.log(err)
    }
  }

  const areaName = () => {
    if(location){
      if(location.city) {
        return `${location.city}, ${location.state}`
      } else if (location.cbsaname){
        return 'waffles'
      }
    } else {
      return nearbyName
    }
  }

  return (
    <div className={styles.menuContainer}>
      <Button
        leftIcon={<LocationArrow />}
        variant="transparent"
        rightIcon={<AngleDown />}
        onClick={() => {
          setShowLocationMenu(!showLocationMenu)
        }}
      >
        {areaName()}
      </Button>
      {showLocationMenu && (
        <div className={styles.locationMenuContainer} ref={locationMenuRef}>
          <div className={styles.inputContainer}>
            <Search />
            <input
              autoFocus
              placeholder="Search by city"
              autoComplete="new-password"
              onChange={(e) => setLocationQuery(e.target.value)}
            />
          </div>
          <div className={styles.locationMenuContent}>
            {results.length > 0 ? (
              <div>
                {results.map((result) => {
                  return (
                    <div
                      className={classNames(
                        styles.locationMenuItem,
                        location &&
                          location === result.cbsaname &&
                          styles.selectedLocation
                      )}
                      onClick={() => {
                        setLocation(result)
                        setNearby(false)
                        setShowLocationMenu(false)
                        setResults([])
                        setLocationQuery("")
                      }}
                    >
                      <span>
                        {result.city}, {result.state}
                      </span>
                      {/*location === result.cbsaname && <CheckMark />*/}
                    </div>
                  )
                })}
              </div>
            ) : (
              <Button
                variant="transparent"
                rightIcon={<LocationArrow />}
                fullWidth
                onClick={() => {
                  setNearby(true)
                  setShowLocationMenu(false)
                  setLocation(null)
                }}
              >
                Use my current location
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default LocationPicker
