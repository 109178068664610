import { useState } from "react"
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"
import { Button, Input, Modal } from "../components"
import { Bell, CloseCircle, Explosion, Phantom } from "../css/icons"
import styles from "./SignInModal.module.scss"
import Verification from "./Verification"
import postToSlack from "../postToSlack"

const Api = require("../api")

const SignInModal = ({ onClose }) => {
  const [phoneSelected, setPhoneSelected] = useState(false)
  const [formattedNumber, setFormattedNumber] = useState("")

  const [number, setNumber] = useState("")
  const [authUUID, setAuthUUID] = useState("")
  const [codeSent, setCodeSent] = useState(false)

  const validateAndSendText = () => {
    let stripped = number.replace(/ /g, "")
    let message = `${stripped} is starting to login`
    postToSlack(message, "firehose")
    Api.callApi({
      endpoint: "/supperclub/get-login-code",
      method: "post",
      data: { phone: stripped, ticketdex: true },
      success: (resp) => {
        if (resp.success) {
          setAuthUUID(resp.auth_uuid)
          setCodeSent(true)
        } else {
          alert("Unknown Error")
        }
      },
      failure: (err) => {
        alert("Unknown Error")
      },
    })
  }

  const closeModal = () => {
    document.body.style.overflow = "auto"
    onClose()
  }

  function phoneFormat(input) {
    if (!input) return
    //returns (###) ###-####
    input = input.replace(/\D/g, "")
    var size = input.length
    if (window.isDeleting) {
      if (size > 1) {
        input = "(" + input
      }
      if (size > 3) {
        input = input.slice(0, 4) + ") " + input.slice(4, 11)
      }
      if (size > 6) {
        input = input.slice(0, 9) + "-" + input.slice(9)
      }
      if (size === 3) {
        input = input.slice(0, 4)
      }
    } else {
      if (size > 0) {
        input = "(" + input
      }
      if (size >= 3) {
        input = input.slice(0, 4) + ") " + input.slice(4, 11)
      }
      if (size >= 6) {
        input = input.slice(0, 9) + "-" + input.slice(9)
      }
    }
    return input
  }

  const handleChange = (e) => {
    let value = e.target.value?.replace(/[()-]/g, "")
    if (value && value.length && value.length - number > 5) {
      setFormattedNumber("")
      return
    }
    if (value.length < 12) {
      setNumber(e.target.value?.replace(/[()-]/g, ""))
      setFormattedNumber(phoneFormat(e.target.value?.replace(/[()-]/g, "")))
    }
  }

  const disabled = number?.length !== 11

  return (
    <Modal
      onClose={() => {
        closeModal()
      }}
      width="medium"
      containerClass={styles.modalContainer}
    >
      {phoneSelected && codeSent && (
        <Verification
          onClose={closeModal}
          phoneNumber={formattedNumber}
          setCodeSent={setCodeSent}
          authUUID={authUUID}
          validateAndSendText={validateAndSendText}
          setPhoneSelected={setPhoneSelected}
        />
      )}
      {!phoneSelected && !codeSent && (
        <div className={styles.signInModal}>
          <div className={styles.modalHeader}>
            <CloseCircle
              onClick={() => {
                document.body.style.overflow = "auto"
                onClose()
              }}
            />
          </div>
          <h1>Create Account or Sign In</h1>
          <label className={styles.label}>Enter your phone number</label>
          <Input
            className={styles.input}
            disableAutocomplete={true}
            placeholder="(555) 555-5555"
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                validateAndSendText()
                setPhoneSelected(true)
              }
            }}
            value={formattedNumber}
            type="search" // disables autofill until we can find a better solution
          ></Input>
          <Button
            fullWidth
            variant="blue"
            className={styles.modalButton}
            disabled={disabled}
            onClick={() => {
              validateAndSendText()
              setPhoneSelected(true)
            }}
          >
            Get My Sign In Code
          </Button>
          <div className={styles.dividerContainer}>
            <div className={styles.divider} />
            <p className={styles.or}>or</p>
            <div className={styles.divider} />
          </div>
          <WalletMultiButton>
            Connect a Solana Wallet
            <div>
              <Phantom />
              <Explosion />
              <Bell />
            </div>
          </WalletMultiButton>
        </div>
      )}
    </Modal>
  )
}

export default SignInModal
