import { useState, useEffect, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import { Helmet } from "react-helmet"
import classNames from "classnames"
import {
  Button,
  CustomDatePicker,
  DateTag,
  Layout,
  SegmentedFilter,
} from "../components"
import styles from "./ArtistPage.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { setSelectedEvent } from "../reducers/eventSlice"
import {
  AngleDown,
  AppleMusic,
  ArrowDown,
  Calendar,
  LocationArrow,
  RedX,
  Spotify,
  XCircle,
} from "../css/icons"
import { useDetectClickOutside } from "../hooks/detectClickOutside"

// const CompactEventCard = ({ event }) => {
//   const history = useHistory()
//   return (
//     <div
//       className={styles.compactEventCard}
//       onClick={() => history.push(`/event/${event.event_id}`)}
//     >
//       <img src={event?.image} alt={event?.title} />
//       <div className={styles.compactCardInfo}>
//         <div className={styles.compactCardTitle}>
//           {event?.short_title || event?.title}
//         </div>
//         <div className={styles.compactCardVenue}>
//           {event?.venue_name} • {event?.venue_city} • {event?.venue_state}
//         </div>
//         <div className={styles.compactCardPrice}>
//           From {formatCents(event?.min_ticket_price)}
//         </div>
//       </div>
//     </div>
//   )
// }

const ConditionalLink = ({ children, to, condition, event }) => {
  const dispatch = useDispatch()

  return condition ? (
    <Link
      className={styles.eventListItem}
      to={to}
      onClick={() => dispatch(setSelectedEvent(event))}
    >
      {children}
    </Link>
  ) : (
    <a
      onClick={() => dispatch(setSelectedEvent(event))}
      className={styles.eventListItem}
      href={to}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )
}

const EventListItem = ({ event }) => {
  const dispatch = useDispatch()
  return (
    <ConditionalLink
      className={styles.eventListItem}
      to={
        event?.min_ticket_price
          ? `/event/${event.event_id}`
          : "https://discord.gg/GYrHjHjybg"
      }
      condition={event?.min_ticket_price}
      onClick={() => dispatch(setSelectedEvent(event))}
    >
      <DateTag event={event} />
      <div className={styles.eventInfo}>
        <div>
          <h3>
            {event?.event_type === "sports"
              ? event.short_title || event.title
              : `${event.venue_city}, ${event.venue_state}`}
          </h3>
          <div className={styles.venueInfo}>
            <p className={styles.venue}>
              {event.venue_name}
              {event?.event_type === "sports" &&
                `, ${event.venue_city}, ${event.venue_state}`}
            </p>
          </div>
        </div>
        <div className={styles.priceContainer}>
          {event?.min_ticket_price ? (
            <div className={styles.priceDescription}>
              <span className={styles.price}>
                From{" "}
                <span className={styles.amt}>
                  ${Math.ceil(event.min_ticket_price / 100)}
                </span>
              </span>
            </div>
          ) : (
            <div className={styles.priceDescription}>
              <span className={classNames(styles.price, styles.discord)}>
                Request in Discord
              </span>
            </div>
          )}
        </div>
      </div>
    </ConditionalLink>
  )
}

const ArtistPage = () => {
  const params = useParams()
  const history = useHistory()
  const locationMenuRef = useRef(null)

  useDetectClickOutside(locationMenuRef, () => {
    setShowLocationMenu(false)
  })

  const preferredLocation = useSelector((state) => state.user.preferredLocation)
  const unfilteredRecommendations = useSelector(
    (state) => state.events.filteredEventList
  )

  const [performer, setPerformer] = useState(null)
  const [nearbyName, setNearbyName] = useState(null)
  const [events, setEvents] = useState([])
  const [nearbyEvents, setNearbyEvents] = useState([])
  const [eventsInYourLocation, setEventsInYourLocation] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [filteredRecommendations, setFilteredRecommendations] = useState([])
  const [numberOfResults, setNumberOfResults] = useState(8)
  const [numHomeResults, setNumHomeResults] = useState(5)
  const [numAwayResults, setNumAwayResults] = useState(5)
  const [selectedSportsFilter, setSelectedSportsFilter] = useState("All")
  const [eventsLoaded, setEventsLoaded] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [showDateMenu, setShowDateMenu] = useState(false)
  const [locations, setLocations] = useState([])
  const [location, setLocation] = useState(null)
  const [showLocationMenu, setShowLocationMenu] = useState(false)

  const performer_id = params.artist_id

  useEffect(() => {
    getPerformer()
    getAllEvents()
    getNearbyEvents()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getPerformer()
    getAllEvents()
    getNearbyEvents()
  }, [params.artist_id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (events?.length) getLocations()
  }, [events]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (events[0]?.event_type.indexOf("sport") > -1) {
      filterSports()
    }
    filterEvents()
  }, [events]) // eslint-disable-line react-hooks/exhaustive-deps

  // when the dates or locations change, update the filtered events
  useEffect(() => {
    getAllEvents()
    getNearbyEvents()
  }, [startDate, endDate, location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterRecommendations(unfilteredRecommendations)
  }, [unfilteredRecommendations]) // eslint-disable-line react-hooks/exhaustive-deps

  const getPerformer = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/performer-by-id`
    let params = { performer_id: performer_id }
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    setPerformer(resp.performer)
  }

  const notNearbyEvents = () => {
    if (!events) return []
    // the all view
    if (nearbyEvents && !location) {
      let nearbyEventIds = []
      for (let i = 0; i < nearbyEvents.length; i++) {
        nearbyEventIds.push(nearbyEvents[i].event_id)
      }
      let notNearby = []
      for (let i = 0; i < events.length; i++) {
        if (nearbyEventIds.indexOf(events[i].event_id) === -1) {
          notNearby.push(events[i])
        }
      }
      return notNearby
    } else if (location) {
      // filter by location
      let events_ = []
      for (let i = 0; i < events.length; i++) {
        if (events[i].venue_city === location.city) {
          events_.push(events[i])
        }
      }
      return events_
    } else {
      return events
    }
  }

  const getAllEvents = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/filters`
    let params = {
      performer_id: performer_id,
      min_date: startDate,
      max_date: endDate,
    }
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    setEvents(resp.events)
    setEventsLoaded(true)
  }

  const getNearbyEvents = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/filters`
    let params = {
      performer_id: performer_id,
      nearby: true,
      min_date: startDate,
      max_date: endDate,
    }
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    setNearbyEvents(resp.events)
    setNearbyName(resp.nearby)
  }

  const getLocations = () => {
    const locations = []
    let citiesSeen = []
    events.forEach((event) => {
      if (event.venue_city && citiesSeen.indexOf(event.venue_city) === -1) {
        citiesSeen.push(event.venue_city)
        locations.push({
          city: event.venue_city,
          state: event.venue_state,
        })
      }
    })

    setLocations(locations)
  }

  const filterEvents = (startDate, endDate, location) => {
    let nearbyEvents = []
    let otherEvents = []

    let eventsToFilter = []

    if (location) {
      eventsToFilter.push(
        ...events.filter((event) => {
          return event.venue_cbsa?.includes(location)
        })
      )
    } else {
      eventsToFilter.push(...events)
    }

    eventsToFilter?.forEach((event) => {
      if (event.min_ticket_price === null) return
      if (event.venue_cbsa?.includes(preferredLocation)) {
        nearbyEvents.push(event)
      } else {
        otherEvents.push(event)
      }
    })

    setEventsInYourLocation(nearbyEvents)
    setFilteredEvents(otherEvents)
    setTimeout(() => {}, 1000)
  }

  const filterRecommendations = () => {
    let recommendations = unfilteredRecommendations?.filter(
      (event) => event.performer_id !== parseInt(params.artist_id)
    )
    setFilteredRecommendations(recommendations)
  }

  const [homeGames, setHomeGames] = useState([])
  const [awayGames, setAwayGames] = useState([])
  const [parking, setParking] = useState([])

  const filterSports = (startDate, endDate) => {
    let homeGames = []
    let awayGames = []
    let parking = []

    events?.forEach((event) => {
      if (event.min_ticket_price === null) return
      if (event.title.includes("PARKING")) {
        parking.push(event)
      } else if (event.role === "headliner") {
        homeGames.push(event)
      } else if (event.role === "supporting act") {
        awayGames.push(event)
      }
    })

    setHomeGames(homeGames)
    setAwayGames(awayGames)
    setParking(parking)
    console.log("parking is", parking)
    setTimeout(() => {
      setEventsLoaded(true)
    }, 1000)
  }

  const metaTitle = `${performer?.name} | XP — Access your favorite shows`
  const metaDescription = `Get tickets to ${performer?.name} and to your other favorite concerts, sports games, & more, with no hidden fees.`
  const shareFallbackImage =
    "https://hngr-icons.s3.amazonaws.com/ticketdex/XP-Social-Share-Image.png"

  const showEndDate =
    startDate &&
    endDate &&
    startDate?.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }) !==
      endDate?.toLocaleDateString("en-us", {
        month: "short",
        day: "numeric",
      })

  const currentLocation = () => {
    if (nearbyName) return nearbyName
  }

  const formatLocationName = (l) => {
    if (!l) return null
    return `${l.city}, ${l.state}`
  }

  const getSportsOptions = () => {
    let options = ["All"]
    if (homeGames.length) options.push("Home")
    if (awayGames.length) options.push("Away")
    if (parking.length) options.push("Parking")
    return options
  }

  return (
    <>
      <Layout>
        <Helmet>
          <title>{metaTitle}</title>
          <meta property="og:title" content={metaTitle} />
          <meta property="twitter:title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          <meta property="og:description" content={metaDescription} />
          <meta property="twitter:description" content={metaDescription} />
          <meta
            property="og:image"
            content={performer?.default_image || shareFallbackImage}
          />
          <meta
            property="twitter:image"
            content={performer?.default_image || shareFallbackImage}
          />
        </Helmet>
        <div className={styles.artistPageContainer}>
          <div className={styles.artistLeftColumn}>
            <div className={styles.artistDescriptionContainer}>
              <div
                className={styles.centerCropped}
                style={
                  performer?.default_img
                    ? {
                        backgroundImage: `url(${performer?.default_img})`,
                      }
                    : {
                        backgroundImage: `url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png)`,
                      }
                }
                alt={performer?.name}
              >
                <h1>{performer?.name}</h1>
                <div className={styles.gradient} />
              </div>
            </div>
            {(performer?.spotify_id || performer?.apple_id) && (
              <div className={styles.streamingLinks}>
                <div className={styles.linkContainer}>
                  {performer?.spotify_id && (
                    <a
                      href={`https://open.spotify.com/artist/${performer?.spotify_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Spotify</span>
                      <Spotify />
                    </a>
                  )}
                </div>
                <div className={styles.linkContainer}>
                  {performer?.apple_id && (
                    <a
                      href={`https://music.apple.com/us/artist/${performer?.apple_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Apple Music</span>
                      <AppleMusic />
                    </a>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={styles.artistEventList}>
            <div className={styles.filterContainer}>
              <div>
                <Button
                  leftIcon={<Calendar className={styles.calendar} />}
                  compact
                  variant="gray"
                  rightIcon={
                    startDate ? (
                      <XCircle className={styles.close} />
                    ) : (
                      <AngleDown className={styles.angleDown} />
                    )
                  }
                  rightIconOnClick={(e) => {
                    e.stopPropagation()
                    setShowDateMenu(false)
                    setStartDate(null)
                    setEndDate(null)
                  }}
                  className={classNames(
                    styles.filterButton,
                    !!startDate ? styles.selected : styles.unselected
                  )}
                  onClick={() => {
                    setShowDateMenu(!showDateMenu)
                  }}
                >
                  {!startDate && "Date"}
                  {startDate &&
                    startDate.toLocaleDateString("en-us", {
                      month: "short",
                      day: "numeric",
                    })}
                  {showEndDate &&
                    ` - ${endDate?.toLocaleDateString("en-us", {
                      month: "short",
                      day: "numeric",
                    })}`}
                </Button>
                {showDateMenu && (
                  <CustomDatePicker
                    className={styles.datePicker}
                    containerStartDate={startDate}
                    setDates={(start, end) => {
                      setStartDate(start)
                      setEndDate(end)
                    }}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    containerEndDate={endDate}
                    onClose={() => setShowDateMenu(false)}
                  />
                )}
              </div>

              {events[0]?.event_type !== "sport" && (
                <div>
                  <Button
                    compact
                    variant="gray"
                    leftIcon={
                      <LocationArrow className={styles.locationArrow} />
                    }
                    rightIcon={
                      location ? (
                        <XCircle className={styles.close} />
                      ) : (
                        <AngleDown className={styles.angleDown} />
                      )
                    }
                    className={styles.filterButton}
                    rightIconOnClick={(e) => {
                      e.stopPropagation()
                      setShowLocationMenu(false)
                      setLocation(null)
                    }}
                    onClick={() => {
                      setShowLocationMenu(!showLocationMenu)
                    }}
                  >
                    {formatLocationName(location) || nearbyName}
                  </Button>
                  {showLocationMenu && (
                    <div
                      className={styles.locationMenuContainer}
                      ref={locationMenuRef}
                    >
                      <div
                        className={styles.locationItem}
                        onClick={() => {
                          setLocation(null)
                          setShowLocationMenu(false)
                        }}
                      >
                        All Cities
                      </div>
                      {locations?.map((location) => (
                        <div
                          className={styles.locationItem}
                          onClick={() => {
                            setLocation(location)
                            setShowLocationMenu(false)
                          }}
                        >
                          {formatLocationName(location)}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            {events[0] && events[0].event_type !== "sport" && (
              <>
                {!location && nearbyName && nearbyEvents.length > 0 && (
                  <div style={{ marginBottom: "36px" }}>
                    <h2>Near Me</h2>
                    {nearbyEvents?.map((event) => (
                      <EventListItem event={event} />
                    ))}
                  </div>
                )}

                {notNearbyEvents()?.length > 0 && (
                  <div>
                    {location ? (
                      <h2>{formatLocationName(location)}</h2>
                    ) : (
                      <h2>All Events</h2>
                    )}
                    {notNearbyEvents()?.map((event, i) => {
                      if (numberOfResults && i >= numberOfResults) return null
                      return <EventListItem event={event} />
                    })}
                  </div>
                )}
                {numberOfResults && events?.length > numberOfResults && (
                  <Button
                    compact
                    rightIcon={<ArrowDown />}
                    className={styles.showMoreButton}
                    onClick={() => setNumberOfResults(null)}
                    variant="beigeOutline"
                  >
                    Show More
                  </Button>
                )}
              </>
            )}
            {eventsLoaded && events?.length === 0 && (
              <div className={styles.noResults}>
                <RedX />
                <h1>No Events Found</h1>
                <p>Try another search or adjust your filters.</p>
                <Button
                  onClick={() => history.push("/events")}
                  variant="beigeOutline"
                  className={styles.explore}
                >
                  Back
                </Button>
              </div>
            )}

            {events[0] && events[0].event_type === "sport" && (
              <>
                <SegmentedFilter
                  options={getSportsOptions()}
                  selected={selectedSportsFilter}
                  onSelect={(option) => setSelectedSportsFilter(option)}
                />
                {(selectedSportsFilter === "All" ||
                  selectedSportsFilter === "Home") && (
                  <>
                    {homeGames?.length > 0 && (
                      <>
                        <h2>Home Games</h2>
                        {homeGames?.map((event, i) => {
                          if (numHomeResults && i >= numHomeResults) return null
                          return <EventListItem event={event} />
                        })}
                      </>
                    )}
                    {numHomeResults && homeGames?.length > numHomeResults && (
                      <Button
                        compact
                        leftIcon={<ArrowDown />}
                        className={styles.showMoreButton}
                        onClick={() => setNumHomeResults(null)}
                        variant="beigeOutline"
                      >
                        Show More
                      </Button>
                    )}
                  </>
                )}
                {(selectedSportsFilter === "All" ||
                  selectedSportsFilter === "Away") && (
                  <>
                    {awayGames?.length > 0 && (
                      <>
                        <h2>Away Games</h2>
                        {awayGames?.map((event, i) => {
                          if (numAwayResults && i >= numAwayResults) return null
                          return <EventListItem event={event} />
                        })}
                      </>
                    )}
                    {numAwayResults && awayGames?.length > numAwayResults && (
                      <Button
                        compact
                        rightIcon={<ArrowDown />}
                        className={styles.showMoreButton}
                        onClick={() => setNumAwayResults(null)}
                        variant="beigeOutline"
                      >
                        Show More
                      </Button>
                    )}
                  </>
                )}
                {(selectedSportsFilter === "All" ||
                  selectedSportsFilter === "Parking") && (
                  <>
                    {parking?.length > 0 && (
                      <>
                        <h2>Parking</h2>
                        {parking?.map((event, i) => (
                          <EventListItem event={event} />
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {/*
        <div className={styles.eventRecommendations}>
          <h2>You may also like:</h2>
          <div className={styles.recommendationsContainer}>
            {filteredRecommendations?.length > 0 ? (
              <>
                {filteredRecommendations?.map((event, i) => {
                  if (i > 3) return null
                  return (
                    <CompactEventCard
                      event={event}
                      key={event.event_id}
                      className={styles.card3w}
                    />
                  )
                })}
              </>
            ) : (
              <>
                <div className={classNames(styles.loader, styles.card3w)} />
                <div className={classNames(styles.loader, styles.card3w)} />
                <div className={classNames(styles.loader, styles.card3w)} />
              </>
            )}
          </div>
        </div>
        */}
      </Layout>
    </>
  )
}

export default ArtistPage
