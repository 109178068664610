import { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { useHistory } from "react-router-dom"
import styles from "./Search.module.scss"
import { Search as SearchIcon } from "../../css/icons/Search"
import { formatLocationName } from "../../helpers/location"
import { AngleRight, City, Microphone, Venue } from "../../css/icons"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"
import Button from "../Button/Button"

const Search = ({ inHeader, isMobile, withButton, close }) => {
  const history = useHistory()
  const [query, setQuery] = useState("")
  const autocompleteRef = useRef()
  const [resultsLoading, setResultsLoading] = useState(false)

  useDetectClickOutside(autocompleteRef, () => {
    setResults([])
  })

  const [results, setResults] = useState([])
  const [performers, setPerformers] = useState([])
  const [events, setEvents] = useState([])
  const [cities, setCities] = useState([])
  const [venues, setVenues] = useState([])

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      getAutocompleteSuggestions(query)
      // Send Axios request here
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [query])

  const getAutocompleteSuggestions = async (query) => {
    if (query === "") {
      setResults([])
      return
    }

    try {
      setResultsLoading(true)
      setResults([])

      const endpoint = "/api/stagehand/dropdown/v2"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })

      let count = 0
      let searchResults = []

      console.log("response", response)

      response.performers &&
        response.performers.forEach((performer) => {
          searchResults.push({ ...performer, type: "artist" })
        })

      response.performers && setPerformers(response.performers)

      response.events &&
        response.events.forEach((event) => {
          searchResults.push({ ...event, type: "event" })
        })

      response.events && setEvents(response.events)

      response.cities &&
        response.cities.forEach((city) => {
          searchResults.push({ ...city, type: "city" })
        })

      response.cities && setCities(response.cities)

      response.venues &&
        response.venues.forEach((venue) => {
          searchResults.push({ ...venue, type: "venue" })
        })

      response.venues && setVenues(response.venues)

      setResults(searchResults)
      setResultsLoading(false)

      // return response.events
    } catch (err) {
      console.log(err)
    }
  }

  const getUniqueKey = (result) => {
    if (result.type === "artist") {
      return result.performer_id
    } else if (result.type === "venue") {
      return result.venue_id
    } else if (result.type === "cbsa") {
      return result.cbsaname
    }
  }

  return (
    <div
      className={classNames(
        styles.searchContainer,
        styles.box,
        isMobile && styles.isMobile,
        withButton && styles.withButton,
        inHeader && styles.inHeader
      )}
    >
      <SearchIcon />
      <input
        autoFocus={isMobile}
        className={styles.search}
        type="text"
        onChange={(e) => setQuery(e.target.value)}
        placeholder={
          window.innerWidth > 960
            ? "Search by artist or city"
            : "Search for events"
        }
        autoComplete="new-password" // weird hack from stack overflow but works
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            history.push(`/events/search/${query}`)
            setResults([]) // close the suggestion dropdown
          }
        }}
      />
      {withButton && (
        <Button
          variant="blue"
          onClick={() => history.push(`/events/search/${query}`)}
        >
          Search
        </Button>
      )}

      {results.length > 0 && (
        <div className={styles.autocomplete} ref={autocompleteRef}>
          {cities.length > 0 && (
            <div className={styles.autocompleteSection}>
              <div className={styles.autocompleteSectionTitle}>Cities</div>
              {cities.map((city) => (
                <div
                  className={styles.autocompleteItem}
                  key={getUniqueKey(city)}
                  onClick={() => {
                    history.push(`/events/city/${city.name}/${city.state}`)
                    setResults([])
                    close && close()
                  }}
                >
                  <div className={styles.autocompleteInfo}>
                    <div className={styles.autocompleteIconContainer}>
                      <City />
                    </div>
                    <p className={styles.autocompleteItemTitle}>
                      {formatLocationName(city.name, city.state)}
                    </p>
                  </div>
                  <div className={styles.autocompleteItemLabel}>
                    <AngleRight className={styles.angle} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {performers.length > 0 && (
            <div className={styles.autocompleteSection}>
              <div className={styles.autocompleteSectionTitle}>Performers</div>
              {performers.map((performer) => (
                <div
                  className={styles.autocompleteItem}
                  key={getUniqueKey(performer)}
                  onClick={() => {
                    history.push(`/artist/${performer.performer_id}`)
                    setResults([])
                    close && close()
                  }}
                >
                  <div className={styles.autocompleteInfo}>
                    <div className={styles.autocompleteIconContainer}>
                      {performer.default_img ? (
                        <img src={performer.default_img} alt={performer.name} />
                      ) : (
                        <Microphone />
                      )}
                    </div>
                    <p className={styles.autocompleteItemTitle}>
                      {performer.name}
                    </p>
                  </div>
                  <div className={styles.autocompleteItemLabel}>
                    <AngleRight className={styles.angle} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {events.length > 0 && (
            <div className={styles.autocompleteSection}>
              <div className={styles.autocompleteSectionTitle}>Events</div>
              {events.map((event) => (
                <div
                  className={styles.autocompleteItem}
                  key={getUniqueKey(event)}
                  onClick={() => {
                    history.push(`/event/${event.event_id}`)
                    setResults([])
                    close && close()
                  }}
                >
                  <div className={styles.autocompleteInfo}>
                    <div
                      className={classNames(
                        styles.autocompleteIconContainer,
                        styles.dateContainer
                      )}
                    >
                      {event?.date_local ? (
                        <>
                          <div className={styles.month}>
                            {new Date(event?.date_local).toLocaleDateString(
                              "en-US",
                              { month: "short" }
                            )}
                          </div>
                          <div className={styles.day}>
                            {new Date(event?.date_local).toLocaleDateString(
                              "en-US",
                              { day: "numeric" }
                            )}
                          </div>
                        </>
                      ) : (
                        <Microphone />
                      )}
                    </div>
                    <p className={styles.autocompleteItemTitle}>{event.name}</p>
                  </div>
                  <div className={styles.autocompleteItemLabel}>
                    <AngleRight className={styles.angle} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {venues.length > 0 && (
            <div className={styles.autocompleteSection}>
              <div className={styles.autocompleteSectionTitle}>Venues</div>
              {venues.map((venue) => (
                <div
                  className={styles.autocompleteItem}
                  key={getUniqueKey(venue?.venue_id)}
                  onClick={() => {
                    history.push(`/venue/${venue.venue_id}`)
                    setResults([])
                    close && close()
                  }}
                >
                  <div className={styles.autocompleteInfo}>
                    <div className={styles.autocompleteIconContainer}>
                      <Venue />
                    </div>
                    <p className={styles.autocompleteItemTitle}>
                      {venue?.name}
                    </p>
                  </div>
                  <div className={styles.autocompleteItemLabel}>
                    <AngleRight className={styles.angle} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {resultsLoading && (
        <div className={styles.loadingContainer}>
          {[...Array(4)].map(() => (
            <div className={styles.autocompleteLoading}>
              <div className={styles.loadingLeft}>
                <img
                  className={styles.loadingImage}
                  src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                  alt="Loading"
                />
                <div>
                  <img
                    className={styles.loadingTitle}
                    src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                    alt="Loading"
                  />
                  <img
                    className={styles.loadingSubtitle}
                    src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                    alt="Loading"
                  />
                </div>
              </div>
              <img
                className={styles.loadingDescription}
                src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                alt="loading"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Search
