import { useHistory, useParams } from "react-router-dom"
import { useEffect, useState, useRef } from "react"
import styles from "./NewVelvet.module.scss"
import { Button, Dispenser, Layout, Search } from "../components"
import {
  AngleRight,
  Mail,
  Minus,
  Plus,
  Search as SearchIcon,
} from "../css/icons"
import HomeCarousels from "./HomeCarousels"
import RedeemLoading from "../Redeem/RedeemLoading"
import classNames from "classnames"
import { useSelector, useDispatch } from "react-redux"
import { getFeaturedEvents } from "../reducers/eventSlice"
import { setUser } from "../reducers/userSlice"
import CityCampaignHero from "./CityCampaignHero"
import MobileSearch from "../components/MobileSearch/MobileSearch"
import { useDetectClickOutside } from "../hooks/detectClickOutside"

const EventCard = ({ event }) => {
  const history = useHistory()

  const title = event.short_title || event.title

  const truncatedTitle =
    title?.length > 16 ? `${title.substring(0, 16)}...` : title

  const truncatedVenue =
    event.venue_name?.length > 28
      ? `${event.venue_name.substring(0, 28)}...`
      : event.venue_name

  return (
    <div
      className={styles.eventCard}
      onClick={() => {
        history.push(`/event/${event.event_id}`)
      }}
    >
      <div className={styles.eventImageContainer}>
        <div
          className={styles.eventImage}
          style={{
            backgroundImage: `url(${event.image})`,
          }}
        />
        <div className={styles.eventDateContainer}>
          <div className={styles.eventMonth}>{event.date_month}</div>
          <div className={styles.eventDay}>{event.date_day}</div>
        </div>
      </div>
      <div className={styles.eventInfo}>
        <div className={styles.eventInfoLeft}>
          <h3>{truncatedTitle}</h3>
          <p>{truncatedVenue}</p>
        </div>
        <div className={styles.eventInfoRight}>
          <p>${(event.min_ticket_price / 100).toFixed(2)}</p>
        </div>
      </div>
    </div>
  )
}

const DefaultCampaignCard = ({ campaign, setShowMobileSearch }) => (
  <div
    className={styles.heroSection}
    style={{
      backgroundImage: `url(${
        window.innerWidth > 960
          ? campaign?.img
          : campaign?.json.campaign.campaign_mobile_bg_image || campaign?.img
      })`,
    }}
  >
    <h1 className={styles.defaultHeroHeader}>
      <>{campaign?.json.campaign.campaign_header}</>
    </h1>
    {campaign?.json.campaign.campaign_description && (
      <p className={styles.heroParagraph}>
        {campaign?.json.campaign.campaign_description}
      </p>
    )}
    {window.innerWidth > 960 ? (
      <Search withButton />
    ) : (
      <div
        className={classNames(styles.searchContainer)}
        onClick={() => setShowMobileSearch(true)}
      >
        <SearchIcon />
        Search for events
      </div>
    )}
  </div>
)

const HeroCarouselCard = ({ params, campaign, setDispenserLoading }) => {
  const history = useHistory()

  return (
    <div
      className={styles.heroSection}
      style={{
        backgroundImage: `url(${
          window.innerWidth > 960
            ? campaign?.img
            : campaign?.json.campaign.campaign_mobile_bg_image || campaign?.img
        })`,
      }}
    >
      {campaign?.json.campaign.campaign_icon && (
        <img src={campaign?.json.campaign.campaign_icon} alt="icon" />
      )}
      <h1 className={styles.heroHeader}>
        {process.env.REACT_APP_SKIN == "ticketnetwork" ? (
          <>You got a guy.</>
        ) : (
          <>{campaign?.json.campaign.campaign_header}</>
        )}
      </h1>
      <p className={styles.heroParagraph}>
        {campaign?.json.campaign.campaign_description}
      </p>
      {campaign?.json.campaign.campaign_cta_action === "dispenser" ? (
        <Dispenser
          campaign={params?.campaign_name}
          setInitialDispenser={true}
          setParentLoading={setDispenserLoading}
          className={styles.heroButton}
          fragment={campaign?.json.campaign.campaign_dispenser_fragment}
          buttonCopy={campaign?.json.campaign.campaign_cta_text}
        />
      ) : (
        <Button
          className={styles.heroButton}
          variant="beigeOutline"
          onClick={() => {
            const link = campaign?.json.campaign.campaign_cta_link
            if (link !== "") {
              if (link.includes("http")) {
                window.open(link, "_blank")
              } else {
                history.push(link)
              }
            }
          }}
        >
          {campaign?.json.campaign.campaign_cta_text}
        </Button>
      )}
      <div className={styles.heroDisclaimerContainer}>
        <p className={styles.heroDisclaimer}>
          Any trademarks seen here belong to their rightful owners, who are not
          affiliated with nor endorse XP.
        </p>
      </div>
    </div>
  )
}

const NewVelvet = () => {
  const [campaigns, setCampaigns] = useState([])
  const [defaultCampaign, setDefaultCampaign] = useState(null)
  const [ticketCount, setTicketCount] = useState(null)
  const [dispenserLoading, setDispenserLoading] = useState(false)
  const [filteredFeaturedEvents, setFilteredFeaturedEvents] = useState([])
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const [showMobileSearch, setShowMobileSearch] = useState(false)
  const ref = useRef()
  useDetectClickOutside(ref, () => setShowMobileSearch(false))

  const user = useSelector((state) => state.user.user)

  useEffect(() => {
    if (user?.publicKey) {
      if (!window.authenticating) {
        auntheticateUser()
      }
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const featuredEvents = useSelector((state) => state.events.featuredEvents)

  useEffect(() => {
    window.scrollTo(0, 0)
    getTicketCount()
    if (params.campaign_name) {
      loadCampaignByName()
      localStorage.setItem("campaign_name", params.campaign_name)
      window.INITIAL_CAMPAIGN = params.campaign_name
    } else {
      loadAllCampaigns()
    }
    dispatch(getFeaturedEvents({ featured: true }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (featuredEvents && featuredEvents.length > 0) {
      filterFeaturedEvents()
    }
  }, [featuredEvents]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // update campaign if you're visiting again
    if (params.campaign_name) {
      loadCampaignByName()
      localStorage.setItem("campaign_name", params.campaign_name)
    }
  }, [params.campaign_name]) // eslint-disable-line react-hooks/exhaustive-deps

  const filterFeaturedEvents = () => {
    const seen = {}
    const filtered = featuredEvents.filter((event) => {
      const duplicate = seen[event.performer_id]
      seen[event.performer_id] = true
      return !duplicate
    })
    setFilteredFeaturedEvents(filtered)
  }

  const getTicketCount = async () => {
    let headers = {
      "Content-Type": "application/json",
    }
    const endpoint = "/api/xp/ticket-count"
    const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

    let response = await fetch(url, {
      method: "get",
      headers,
    })
    response = await response.json()

    setTicketCount(response.count)
  }

  const loadTicketnetworkCampaign = async () => {
    let headers = {
      "Content-Type": "application/json",
    }
    const endpoint = `/api/xp/campaigns/goldcoast`
    const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

    const response = await fetch(url, {
      method: "post",
      headers,
    }).then((res) => res.json())

    const defaultCamp = response.campaign
    setDefaultCampaign(defaultCamp[0])
    return response
  }

  const loadAllCampaigns = async () => {
    if (process.env.REACT_APP_SKIN === "ticketnetwork") {
      return loadTicketnetworkCampaign()
    }

    let headers = {
      "Content-Type": "application/json",
    }
    const endpoint = "/api/xp/campaigns"
    const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

    const response = await fetch(url, {
      method: "post",
      headers,
    }).then((res) => res.json())

    const defaultCamp = response.campaigns.find(
      (campaign) => campaign.is_default
    )
    setDefaultCampaign(defaultCamp)

    return response
  }

  const loadCampaignByName = async () => {
    if (!params.campaign_name) return
    let headers = {
      "Content-Type": "application/json",
    }
    const endpoint = `/api/xp/campaigns/${params.campaign_name}`
    const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

    try {
      const response = await fetch(url, {
        method: "post",
        headers,
      }).then((res) => res.json())
      setDefaultCampaign(response.default[0])

      if (response.campaign) {
        setCampaigns(response.campaign)
      } else {
        localStorage.removeItem("campaign_name")
        history.push("/")
      }

      return response
    } catch (err) {
      localStorage.removeItem("campaign_name")
      history.push("/")
    }
  }

  const authneticatePhone = async () => {
    let data = {
      phone: user.phone,
    }
    let headers = {
      "Content-Type": "application/json",
    }
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/velvet/velvetropephone`
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      resp = await resp.json()
      let u = user
      let u2 = JSON.parse(JSON.stringify(u))
      let msg
      if (resp.valid) {
        u2.authenticated = true
        dispatch(setUser(u2))

        msg = `${u.publicKey.toString()} (${
          u.loginMethod
        }) got through velvet rope`
      } else {
        if (params.campaign_name) {
          u2.authenticated = true // if they're on a campaign let them in anyway
          msg = `${u.publicKey.toString()} (${
            u.loginMethod
          }) got through velvet rope from campaign`
          dispatch(setUser(u2))
        } else {
          msg = `${u.publicKey.toString()} (${
            u.loginMethod
          }) got denied velvet rope`
          u2.authenticated = false
          dispatch(setUser(u2))
        }
      }
    } catch (err) {
      // TODO add sentry alert here
      window.authenticating = false
      console.log("restrictor error", err)
    }
  }

  const auntheticateUser = async () => {
    console.log("authenticating user")

    window.authenticating = true

    if (user.loginMethod === "phone") {
      return await authneticatePhone()
    }

    let headers = {
      "Content-Type": "application/json",
    }
    let urls = [
      `${process.env.REACT_APP_EXPRESS_API}/api/velvet/velvetrope`,
      `${process.env.REACT_APP_EXPRESS_API}/api/velvet/madlads`,
      `${process.env.REACT_APP_EXPRESS_API}/api/velvet/velvetropexpcash`,
      `${process.env.REACT_APP_EXPRESS_API}/api/velvet/velvetropexpchip`,
      `${process.env.REACT_APP_EXPRESS_API}/api/velvet/velvetroperebate`,
      `${process.env.REACT_APP_EXPRESS_API}/api/velvet/velvetropeticket`,
    ]

    let u = user
    let u2 = JSON.parse(JSON.stringify(u))
    for (let url of urls) {
      let data = {
        account: user.publicKey.toString(),
      }
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      resp = await resp.json()

      console.log(resp, "resp")
      if (resp.valid) {
        u2.authenticated = true
        let msg = `${u.publicKey.toString()} (${
          u.loginMethod
        }) got through velvet rope = ${url}`
        dispatch(setUser(u2))
        setTimeout(() => {
          if (params.campaign_name) {
            if (history.location.pathname.includes("campaign")) {
              history.push(`/campaign/${params.campaign_name}`)
            } else {
              history.push(`/c/${params.campaign_name}`)
            }
          } else {
            history.push("/")
          }
        }, 1000)
        return
      }
    }
    let msg
    if (params.campaign_name) {
      u2.authenticated = true // if they're on a campaign let them in anyway
      msg = `${u.publicKey.toString()} (${
        u.loginMethod
      }) got through velvet rope from campaign`
    } else {
      msg = `${u.publicKey.toString()} (${
        u.loginMethod
      }) got denied velvet rope`
      u2.authenticated = false
    }
    dispatch(setUser(u2))
  }

  // if there is one campaign, use that
  // if there are more than one campaign, use the default copy

  const getCopyForField = (section, field) => {
    if (campaigns?.length === 1) {
      return campaigns[0]?.json[section]?.[field]
    } else {
      return defaultCampaign?.json[section][field]
    }
  }

  const scrollTrigger = (selector, options = {}) => {
    let els = document.querySelectorAll(selector)
    els = Array.from(els)
    els.forEach((el) => {
      addObserver(el, options)
    })
  }

  const addObserver = (el, options) => {
    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (options.cb) {
            options.cb(el)
          } else {
            entry.target.classList.add("active")
          }
          observer.unobserve(entry.target)
        }
      })
    }, options)
    observer.observe(el)
  }

  scrollTrigger("#exchange", {
    threshold: 0.5,
  })

  scrollTrigger("#events", {
    threshold: 0.5,
  })

  return (
    <div className={styles.outerContainer}>
      {dispenserLoading && (
        <RedeemLoading
          useOneOffCopy={window.location.pathname.includes("decrypt")}
        />
      )}

      <Layout
        velvetHeader
        className={styles.velvet}
        contentClassName={styles.noPadding}
      >
        {showMobileSearch && (
          <MobileSearch
            className={styles.velvetSearch}
            close={() => setShowMobileSearch(false)}
          />
        )}
        {campaigns?.length === 1 && campaigns[0].is_city_campaign ? (
          <CityCampaignHero campaign={campaigns[0]} />
        ) : (
          <>
            {campaigns?.length === 1 ? (
              <HeroCarouselCard
                params={params}
                campaign={campaigns[0]}
                setDispenserLoading={setDispenserLoading}
              />
            ) : (
              <DefaultCampaignCard
                campaign={defaultCampaign}
                setShowMobileSearch={setShowMobileSearch}
              />
            )}
          </>
        )}

        {/* <div className={styles.banner}>
          <div className={styles.bannerContent}>
            <h1>100,000+ Events</h1>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Globe+Icon+1.png"
              alt="Globe"
            />
            <h1>34M+ Tickets</h1>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Torus+Icon+1.png"
              alt="Torus"
            />
            <h1>See more shows on your terms</h1>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Torus+Icon+1.png"
              alt="Torus"
            />
          </div>
          <div className={styles.bannerContent}>
            <h1>100,000+ Events</h1>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Globe+Icon+1.png"
              alt="Globe"
            />
            <h1>34M+ Tickets</h1>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Torus+Icon+1.png"
              alt="Torus"
            />
            <h1>See more shows on your terms</h1>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Torus+Icon+1.png"
              alt="Torus"
            />
          </div>
        </div> */}

        {!(campaigns?.length === 1 && campaigns[0].is_city_campaign) && (
          <HomeCarousels />
        )}
        {!getCopyForField("value_prop", "hide_value_prop") && (
          <div className={styles.exchangeSection} id="exchange">
            <div className={styles.exchangeContentLeft}>
              <img
                src="https://hngr-icons.s3.amazonaws.com/ticketdex/XPGrayscale.png"
                alt="Tile"
                className={styles.tile}
              />
              <div className={styles.xpHeader}>
                <h2>Welcome to</h2>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/ticketdex/xp_logo_stretched_offwhite_noise+2.png"
                  alt="XP"
                  id="xpImage"
                />
              </div>
            </div>
            <div className={styles.exchangeContentRight} id="exchange-right">
              <h2>{getCopyForField("value_prop", "value_prop_header")}</h2>
              <div
                className={styles.exchangeLink}
                onClick={() => {
                  const link = getCopyForField(
                    "value_prop",
                    "value_prop_cta_link"
                  )
                  if (link !== "") {
                    if (link.includes("http")) {
                      window.open(link, "_blank")
                    } else {
                      history.push(link)
                    }
                  }
                }}
              >
                {getCopyForField("value_prop", "value_prop_cta_text")}{" "}
                <AngleRight />
              </div>
              <img
                src="https://hngr-icons.s3.amazonaws.com/ticketdex/XP+Ticket+Mockup+Flipped+2.png"
                alt="Ticket"
                className={styles.ticket}
                id="ticket"
              />
              <div className={styles.exchangeContent}>
                {defaultCampaign &&
                  getCopyForField("value_prop", "value_prop_description")
                    .split(".")
                    .map((p) => {
                      if (p === "") return null
                      const formatted = (p += ".")
                      return <p key={p}>{formatted}</p>
                    })}
              </div>
            </div>
          </div>
        )}

        {/* {shouldShowContentBlock && (
          <div className={styles.contentBlock}>
            <div className={styles.contentBlockInner}>
              <WhiteBorder className={styles.topRightBorder} />
              <WhiteBorder className={styles.topLeftBorder} />
              <WhiteBorder className={styles.bottomRightBorder} />
              <WhiteBorder className={styles.bottomLeftBorder} />
              <img
                src={getCopyForContentBlock("image")}
                alt="Tiles"
                className={styles.earlyAccessImage}
              />
              <h3>{getCopyForContentBlock("heading")}</h3>
              <p>{getCopyForContentBlock("description")}</p>

              <Button
                variant="beigeOutline"
                onClick={() => {
                  const link = getCopyForContentBlock("cta_link")
                  if (link !== "") {
                    if (link.includes("http")) {
                      window.open(link, "_blank")
                    } else {
                      history.push(link)
                    }
                  }
                }}
              >
                {getCopyForContentBlock("cta_text")}
              </Button>
            </div>
          </div>
        )} */}
        <div className={styles.banner}>
          <div className={styles.bannerContent}>
            <h1>No Hidden Fees</h1>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Globe+Icon+1.png"
              alt="Globe"
            />
            <h1>Upfront Pricing</h1>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Torus+Icon+1.png"
              alt="Torus"
            />
          </div>
          <div className={styles.bannerContent}>
            <h1>No Hidden Fees</h1>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Globe+Icon+1.png"
              alt="Globe"
            />
            <h1>Upfront Pricing</h1>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Torus+Icon+1.png"
              alt="Torus"
            />
          </div>
        </div>
        {!getCopyForField("events", "hide_events") && (
          <div className={styles.eventContainer} id="events">
            <h1>View Over </h1>
            {process.env.REACT_APP_SKIN == "ticketnetwork" ? (
              <h1>{ticketCount} Tickets</h1>
            ) : (
              <h1>49 million Tickets</h1>
            )}
            <Button
              variant="beigeOutline"
              onClick={() => history.push("/events")}
            >
              Browse All
            </Button>
            <div className={styles.eventListContainer}>
              <div className={classNames(styles.eventList, styles.eventList1)}>
                {filteredFeaturedEvents?.map((event, i) => {
                  if (i > 2) return null
                  return (
                    <EventCard
                      key={i}
                      event={event}
                      campaignName={params.campaign_name}
                    />
                  )
                })}
              </div>
              <div className={classNames(styles.eventList, styles.eventList2)}>
                {filteredFeaturedEvents?.map((event, i) => {
                  if (i <= 2 || i > 5) return null
                  return (
                    <EventCard
                      key={i}
                      event={event}
                      campaignName={params.campaign_name}
                    />
                  )
                })}
              </div>
              <div className={classNames(styles.eventList, styles.eventList3)}>
                {filteredFeaturedEvents?.map((event, i) => {
                  if (i <= 5 || i > 8) return null
                  return (
                    <EventCard
                      key={i}
                      event={event}
                      campaignName={params.campaign_name}
                    />
                  )
                })}
              </div>
              <div className={classNames(styles.eventList, styles.eventList4)}>
                {filteredFeaturedEvents?.map((event, i) => {
                  if (i <= 8 || i > 11) return null
                  return (
                    <EventCard
                      key={i}
                      event={event}
                      campaignName={params.campaign_name}
                    />
                  )
                })}
              </div>
              <div className={classNames(styles.eventList, styles.eventList5)}>
                {filteredFeaturedEvents?.map((event, i) => {
                  if (i <= 11 || i > 14) return null
                  return (
                    <EventCard
                      key={i}
                      event={event}
                      campaignName={params.campaign_name}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
        <div className={styles.faqSection}>
          <div className={styles.faqLeft}>
            <h1>Frequently Asked Questions</h1>
            <p>Don't see your question here?</p>
            <div className={styles.buttonContainer}>
              <Button variant="transparent" rightIcon={<Mail />}>
                Email Support
              </Button>
              <a
                href="https://xpsupport.zendesk.com/hc/en-us"
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="transparent" className={styles.telegram}>
                  Help Center
                </Button>
              </a>
            </div>
          </div>
          <div className={styles.faqRight}>
            <FAQCollapsible
              question="Why XP?"
              answer={
                <>
                  <p>
                    We've always got your back. Today it's by giving you the
                    best price on tickets- with no junk fees. So you can go to
                    all the shows & games you deserve.
                  </p>
                  <p>
                    But for the future? We'll have your back on more than
                    tickets. Think epic experiences and perks, all while
                    connecting with folks who are just as into your favorite
                    stuff as you are.
                  </p>
                </>
              }
            />
            <FAQCollapsible
              question="How long does it take to receive my ticket & how do I make sure I get it?"
              answer={
                <p>
                  We guarantee that you will have your ticket at least 48 hours
                  before the show. We are committed to sending you your ticket
                  as soon as possible, so we will email it to you the moment
                  it’s available, but rest assured that you will have it in hand
                  before your event.{" "}
                </p>
              }
            />
            <FAQCollapsible
              question="What is a tradable ticket?"
              answer={
                <>
                  <p>
                    If you want to buy your ticket but leave the option open to
                    sell it via a Web3 marketplace or trade it, you may select
                    to make your ticket treadable instead of receiving it as
                    soon as it's available. Tradable Tickets allow you to easily
                    send your tickets to anyone with a Solana wallet or resell
                    them securely on marketplaces such as Tensor and Magic Eden.
                  </p>
                  <p>
                    Tradable tickets are dynamic, secure, and fully transferable
                    digital assets. They are NFTs on the Solana blockchain that
                    can be traded or sold if your plans change!
                  </p>
                </>
              }
            />
            <FAQCollapsible
              question="How do I get in touch with the team at XP?"
              answer={
                <>
                  For all support questions, please visit our{" "}
                  <a
                    href="https://xpsupport.zendesk.com/hc/en-us/sections/22747741405467-Contact-Us"
                    target="_blank"
                    rel="noreferrer"
                  >
                    help center
                  </a>{" "}
                  and select the option that works best for you. If you want to
                  learn more about XP or chat with us, join our{" "}
                  <a
                    href="https://t.me/+-eyZj-pB7qMwZTdh"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Telegram Group
                  </a>
                  .
                </>
              }
            />
            <FAQCollapsible
              question="Is XP a secondary ticket marketplace? What does that mean?"
              answer={
                <>
                  <p>
                    Yes, XP is a secondary marketplace, which means you are
                    buying tickets from someone who is reselling them. We are
                    not partnered with any team, venue, or artist unless
                    specifically stated.
                  </p>
                  <p>
                    The price you see on the ticket is the price you will pay at
                    checkout. We are committed to charging you no junk fees, and
                    offering the best possible price to your favorite shows.
                  </p>
                </>
              }
            />
            <FAQCollapsible
              question="Why is XP built on the blockchain?"
              answer={
                <>
                  <p>
                    One of our main values at XP is innovation, and that means
                    staying at the forefront of new technologies that unlock
                    greater value for fans. That's why our tech infrastructure
                    is built on the Solana blockchain.
                  </p>
                  <p>
                    Today this tech allows us offer the best pricing as well as
                    secure tickets that you can resell or trade. But in the
                    future we believe it will unlock epic experiences for fans.
                  </p>
                  <p>
                    But remember, you don't need to be a blockchain expert or
                    know anything about it to use XP - it's just the powerhouse
                    tech working for you behind the scenes.
                  </p>
                </>
              }
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default NewVelvet

const FAQCollapsible = ({ question, answer }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={styles.faqCollapsible}>
      <div
        className={classNames(styles.faqQuestion, open && styles.questionOpen)}
        onClick={() => setOpen(!open)}
        aria-expanded={open}
      >
        <h3>{question}</h3>
        {open ? <Minus /> : <Plus />}
      </div>
      {open && (
        <div
          className={classNames(styles.faqAnswer, {
            [styles.faqAnswerOpen]: open,
          })}
        >
          {answer}
        </div>
      )}
    </div>
  )
}
