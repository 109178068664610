import classNames from "classnames"
import styles from "./CloseCircle.module.scss"

export const CloseCircle = ({ className, onClick }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(styles.closeCircle, className)}
    onClick={() => onClick && onClick()}
  >
    <rect
      y="0.5"
      width="24"
      height="24"
      rx="12"
      fill="#85D5FF"
      fillOpacity="0.15"
    />
    <path
      d="M14.5274 12.4997L18.318 8.70914C18.7831 8.24399 18.7831 7.48983 18.318 7.0243L17.4755 6.18187C17.0104 5.71672 16.2562 5.71672 15.7907 6.18187L12.0002 9.9724L8.20963 6.18187C7.74448 5.71672 6.99031 5.71672 6.52478 6.18187L5.68236 7.0243C5.21721 7.48945 5.21721 8.24361 5.68236 8.70914L9.47289 12.4997L5.68236 16.2902C5.21721 16.7554 5.21721 17.5095 5.68236 17.9751L6.52478 18.8175C6.98994 19.2826 7.74448 19.2826 8.20963 18.8175L12.0002 15.0269L15.7907 18.8175C16.2558 19.2826 17.0104 19.2826 17.4755 18.8175L18.318 17.9751C18.7831 17.5099 18.7831 16.7557 18.318 16.2902L14.5274 12.4997Z"
      fill="#85D5FF"
    />
  </svg>
)