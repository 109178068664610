import { forwardRef, useImperativeHandle } from "react"
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"
import { useSelector } from "react-redux"
import { CardElement } from "@stripe/react-stripe-js"

const INPUT_STYLE = {
  base: {
    fontSize: "20px",
    paddingTop: "7px",
    textAlign: "left",
    fontFamily: "sans-serif",
    color: "white",
    "::placeholder": {
      color: "#aab7c4",
      fontWeight: 100,
      fontSize: "20px",
    },
  },
}

const StripeStuff = forwardRef((props, ref) => {
  const user = useSelector((state) => state.user.user)
  const stripe = useStripe()
  const elements = useElements()

  useImperativeHandle(ref, () => ({
    submitPayment,
  }))

  const submitPayment = async (event) => {

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    if (event) {
      event.preventDefault()
    }

    if (!stripe || !elements) {
      return false
    }

    var cardElement = elements.getElement("card")

    let billingDetails = {}
    if(user?.user?.first_name){
      billingDetails.name = user?.user?.first_name + " " + user?.user?.last_name
    }
    billingDetails.email = user?.user?.email

    const result = await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: billingDetails,
      },
    })
    //`Elements` instance that was used to create the Payment Element

    if (result.error) {
      alert(result.error.message)
      return false
    } else {
      return true
    }
  }

  return (
    <div>
      <form>
        <CardElement
          options={{
            style: INPUT_STYLE,
            classes: {
              base: "card-number-input",
            },
          }}
        />
      </form>
    </div>
  )
})

export default StripeStuff
