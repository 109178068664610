export const Send = ({ className }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.2284 1.9181L2.16052 8.88024C1.68926 9.15102 1.74915 9.80714 2.2178 10.005L4.98547 11.1662L12.4657 4.57382C12.6089 4.44624 12.812 4.64151 12.6897 4.78992L6.41748 12.4316V14.5275C6.41748 15.142 7.15952 15.3841 7.52403 14.9389L9.17734 12.9263L12.4215 14.2854C12.7912 14.4416 13.213 14.2099 13.2807 13.8115L15.1553 2.5638C15.2438 2.03787 14.6788 1.65774 14.2284 1.9181Z"
      fill="#181A1E"
    />
  </svg>
)
