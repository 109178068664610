import { useEffect, useState, useRef } from "react"
import classNames from "classnames"
import { Button, Layout, LocationPicker } from "../components"
import styles from "./EventList.module.scss"
import {
  AngleDown,
  ArrowDown,
  BrowseArrow,
  Calendar,
  CheckMark,
  HamburgerNav,
  LocationArrow,
  RedX,
  Search,
} from "../css/icons"
import { CustomDatePicker, EventCard } from "../components"

import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import {
  clearSelectedTicketGroup,
  clearSelectedTicketThumb,
  clearTicketGroups,
} from "../reducers/ticketSlice"
import { useDetectClickOutside } from "../hooks/detectClickOutside"

const { cbsaToCityState } = require("../helpers/locationToCBSAName")

const EventList = () => {
  const [loaded, setLoaded] = useState(false)

  const history = useHistory()
  const routeParams = useParams()

  // const [selectedFilters, setSelectedFilters] = useState([])
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })

  const [location, setLocation] = useState("")
  const [nearbyName, setNearbyName] = useState("")
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [nearby, setNearby] = useState(null)
  const [venue, setVenue] = useState({ venueId: null, venueName: null })
  const [query, setQuery] = useState("")
  const [numberOfResults, setNumberOfResults] = useState(14)
  const [featured, setFeatured] = useState(false)
  const [justAdded, setJustAdded] = useState(false)
  const [maxPrice, setMaxPrice] = useState(null)
  const [league, setLeague] = useState(null)
  const [genre, setGenre] = useState(null)
  const [showEventTypeMenu, setShowEventTypeMenu] = useState(false)
  const [eventType, setEventType] = useState(null)
  const [showLocationMenu, setShowLocationMenu] = useState(false)
  const [showDateMenu, setShowDateMenu] = useState(false)
  const [date, setDate] = useState("All Dates")
  const [locationQuery, setLocationQuery] = useState("")
  const [results, setResults] = useState([])
  const [events, setEvents] = useState([])

  const eventTypes = ["concerts", "sports", "theater"]
  const dates = ["All Dates", "This Week", "This Month"]

  const eventTypeRef = useRef()
  useDetectClickOutside(eventTypeRef, (event) => {
    if (event.target && event.target?.id !== "EventListDropdownButton") {
      setShowEventTypeMenu(false)
    }
  })

  const dateMenuRef = useRef()
  useDetectClickOutside(dateMenuRef, (event) => {
    if (event.target && event.target?.id !== "DateDropdownButton") {
      setShowDateMenu(false)
    }
  })

  const locationMenuRef = useRef()
  useDetectClickOutside(locationMenuRef, () => {
    setShowLocationMenu(false)
    setResults([])
    setLocationQuery("")
  })

  useEffect(() => {
    window.EVENT_GET_KEY = 0
    window.scrollTo(0, 0)
    applyUrlParams()
    return () => (window.searchedNearby = false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoaded(true)
  }, [events]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    history.listen((location) => {
      applyUrlParams()
    })
  }, [history])

  useEffect(() => {
    setLoaded(true)
  }, [events]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (routeParams?.query) {
      setQuery(routeParams.query)
    }
    
    if (routeParams?.city) {
      let city = routeParams.city
      let state = routeParams.state
      setLocation({city:city, state:state})
      setNearbyName(`${city}, ${state}`)
    }

  }, [routeParams]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (query) {
      setLeague(null)
      setEventType(null)
      setGenre(null)
    }
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.EVENT_GET_KEY += 1
    getEvents(window.EVENT_GET_KEY)
  }, [maxPrice, date, eventType, league, featured, nearby, location, query, genre]) // eslint-disable-line react-hooks/exhaustive-deps

  const applyUrlParams = () => {
    let params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    console.log("applying url params")
    if (params.eventType) {
      setEventType(params.eventType)
    }
    if (params.nearby) {
      setNearby(true)
    }

    if (params.cbsa) {
      setLocation({
        cbsaname:params.cbsa
      })
      setNearbyName(cbsaToCityState(params.cbsa))
    }

    if (params.featured) {
      setFeatured(true)
    }

    if (params.league) {
      setLeague(params.league)
      setEventType(null)
      setMaxPrice(null)
    }

    if (params.venue_id) {
      setVenue({ venueId: params.venue_id, venueName: params.venue_name })
      setMaxPrice(null)
    }

    if (params.justAdded) {
      setJustAdded(true)
    }

    if (routeParams?.query) {
      setQuery(routeParams.query)
    }

    if (params.featured) {
      setFeatured(true)
    }

    if (params.max_price) {
      setMaxPrice(params.max_price)
    }

    if (params.maxPrice) {
      setMaxPrice(params.maxPrice)
    }
  }

  const getEvents = async (EVENT_GET_KEY) => {
    setLoaded(false)
    const url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/filters`
    let params = {}

    // make sure location and nearby arent both true
    if (location) {
      if (location.cbsaname) {
        params["cbsa"] = location.cbsaname
      } else {
        params["city"] = location.city
        params["state"] = location.state
      }
    } else {
      params["nearby"] = true
    }
    if (query) {
      params["query"] = query
    } else {
      if (featured) {
        params["featured"] = true
      }
      if (genre) {
        params["genre"] = genre
      }
      if (league) {
        params["league"] = league
      } else if (eventType) {
        params["event_type"] = eventType
      }
    }

    if (maxPrice) {
      params['max_price'] = maxPrice
    }

    params["date_bucket"] = date

    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    if (EVENT_GET_KEY == window.EVENT_GET_KEY) {
      setEvents(resp.events)
      if(resp.nearby){
        setNearbyName(resp.nearby)
      }
    } else {
      console.log("keys dont match", EVENT_GET_KEY, window.EVENT_GET_KEY)
    }
  }

  const clearFilters = () => {
    setStartDate(null)
    setEndDate(null)
    setLocation(null)
    setEventType(null)
    setFeatured(false)
    history.push("/events")
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      getAutocompleteSuggestions(locationQuery)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [locationQuery])

  const getAutocompleteSuggestions = async (query) => {
    if (query === "") {
      setResults([])
      return
    }

    try {
      const endpoint = "/api/stagehand/cities"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      setResults(response.cities)
    } catch (err) {
      console.log(err)
    }
  }

  const updateEventType = (type) => {
    history.replace("/events")
    window.scrollTo(0, 0)
    setEventType(type)
    setLeague(null)
    setGenre(null)
    setQuery(null)
    setMaxPrice(null)
  }

  const dropdownText = () => {
    if (league) {
      return `ALL ${league} EVENTS`
    } else if (query) {
      return query
    } else if (genre) {
      return genre
    } else if (eventType) {
      return eventType
    } else if (maxPrice) { 
      return `Events under $${maxPrice}`
    } else return "ALL EVENTS"
  }

  const renderBottomEvents = () => {
    if (!events) return null

    if (genre || league || eventType === "sports") {
      return (
        <div className={styles.bottomEventSection}>
          {events.map((event, i) => {
            if (event.min_ticket_price === null) {
              return null
            }
            if (i < 6 || i > 50) return null
            if (window.innerWidth > 960) {
              return (
                <CompactEventCard
                  key={event.event_id}
                  className={classNames(styles.eventListCard)}
                  event={event}
                />
              )
            } else {
              return <MobileEventCard key={event.event_id} event={event} />
            }
          })}
        </div>
      )
    }

    if (!league) {
      let key = "event_type"
      if (eventType) key = "genre"

      console.log("key is", key)

      // organize events by category
      let categories = {}
      for (let i = 6; i < events.length; i++) {
        let e = events[i]
        if (!(e[key] in categories)) {
          categories[e[key]] = []
        }
        categories[e[key]].push(e)
      }
      console.log("categories", categories)
      let category_length = {}
      for (let category in categories) {
        console.log("category", category)
        category_length[category] = categories[category].length
      }

      let sortable = []
      for (var category in category_length) {
        if (category !== "null") {
          sortable.push([category, category_length[category]])
        }
      }

      sortable.sort(function (a, b) {
        return b[1] - a[1]
      })
      let sortedCategories = []
      for (let i = 0; i < sortable.length; i++) {
        sortedCategories.push(sortable[i][0])
      }

      // sortedCategories is now like "concert", "theatre"
      return sortedCategories.map((category) => (
        <div className={styles.bottomEventSection}>
          <div className={styles.eventTypeHeaderSection}>
            <h2>{category}</h2>
            <div
              className={classNames(styles.browseMoreButton)}
              onClick={() => {
                if (key === "event_type") {
                  updateEventType(category)
                } else {
                  setGenre(category)
                  window.scrollTo(0, 0)
                }
              }}
              variant="blue"
              fullWidth
            >
              <div>BROWSE {category}</div>
              <BrowseArrow />
            </div>
          </div>
          {categories[category].map((event, i) => {
            if (event.min_ticket_price === null) {
              return null
            }
            if (i > 10) return null
            if (window.innerWidth > 960) {
              return (
                <CompactEventCard
                  key={event.event_id}
                  className={classNames(styles.eventListCard)}
                  event={event}
                />
              )
            } else {
              return <MobileEventCard key={event.event_id} event={event} />
            }
          })}
        </div>
      ))
    }
  }

  return (
    <Layout contentClassName={styles.eventListLayout}>
      <div className={styles.eventListHeader}>
        <div className={styles.eventListHeroContainer}>
          <div className={styles.eventTagContainer}>
            <div className={classNames(styles.tagRow, styles.topRow)}>
              <div className={styles.menuContainer}>
                <Button
                  id="EventListDropdownButton"
                  variant="transparent"
                  rightIcon={<AngleDown />}
                  leftIcon={<HamburgerNav />}
                  onClick={() => {
                    if (showEventTypeMenu) {
                      setShowEventTypeMenu(false)
                    } else {
                      setShowEventTypeMenu(true)
                    }
                  }}
                >
                  {dropdownText()}
                </Button>
                {showEventTypeMenu && (
                  <div
                    className={styles.eventTypeMenuContainer}
                    ref={eventTypeRef}
                  >
                    <div
                      className={classNames(
                        styles.eventTypeMenuItem,
                        !eventType && styles.selectedEventType
                      )}
                      onClick={() => {
                        updateEventType(null)
                        setShowEventTypeMenu(false)
                      }}
                    >
                      <span>All Events</span>
                      {!eventType && <CheckMark />}
                    </div>
                    {eventTypes.map((type) => {
                      if (type === "concert") {
                        return (
                          <div
                            className={classNames(
                              styles.eventTypeMenuItem,
                              eventType === type && styles.selectedEventType
                            )}
                            onClick={() => {
                              updateEventType(type)
                              setShowEventTypeMenu(false)
                            }}
                          >
                            <span>Concerts</span>
                            {eventType === type && <CheckMark />}
                          </div>
                        )
                      } else
                        return (
                          <div
                            className={classNames(
                              styles.eventTypeMenuItem,
                              eventType === type && styles.selectedEventType
                            )}
                            onClick={() => {
                              updateEventType(type)
                              setShowEventTypeMenu(false)
                            }}
                          >
                            <span>{type}</span>
                            {eventType === type && <CheckMark />}
                          </div>
                        )
                    })}
                  </div>
                )}
              </div>
              <h2 className={classNames(styles.topRow, styles.near)}>Near</h2>
              <LocationPicker
                nearbyName={nearbyName}
                updateLocation={setLocation}
              />
              <div
                className={classNames(
                  styles.menuContainer,
                  styles.dateDropdown
                )}
              >
                <Button
                  id="DateDropdownButton"
                  leftIcon={<Calendar />}
                  variant="transparent"
                  rightIcon={<AngleDown />}
                  onClick={() => {
                    setShowDateMenu(!showDateMenu)
                  }}
                >
                  {date}
                </Button>
                {showDateMenu && (
                  <div
                    className={styles.eventTypeMenuContainer}
                    ref={dateMenuRef}
                  >
                    {dates.map((d) => {
                      return (
                        <div
                          className={classNames(
                            styles.eventTypeMenuItem,
                            date === d && styles.selectedEventType
                          )}
                          onClick={() => {
                            setDate(d)
                            setShowDateMenu(false)
                          }}
                        >
                          <span>{d}</span>
                          {date === d && <CheckMark />}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.eventListBody}>
        {loaded ? (
          <>
            {events?.length > 0 ? (
              <div className={styles.eventContainer}>
                {events.map((event, i) => {
                  if (i >= 6) return null
                  // don't show events that don't have prices / ticket groups
                  if (event.min_ticket_price === null) {
                    return null
                  }
                  return (
                    <EventCard
                      key={event.event_id}
                      className={classNames(
                        styles.card3w,
                        styles.eventListCard
                      )}
                      event={event}
                    />
                  )
                })}

                {renderBottomEvents()}

                {events?.length > 6 && (
                  <div>
                    <h2>More Results</h2>
                    {events.map((event, i) => {
                      if (i < 6) return null
                      if (i >= numberOfResults) return null
                      if (event.min_ticket_price === null) {
                        return null
                      }
                      if (window.innerWidth > 960) {
                        return (
                          <CompactEventCard
                            key={event.event_id}
                            className={classNames(styles.eventListCard)}
                            event={event}
                          />
                        )
                      } else {
                        return (
                          <MobileEventCard key={event.event_id} event={event} />
                        )
                      }
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.emptyState}>
                <RedX />
                <h1>No Events Found</h1>
                <p>
                  Don't worry, there are always new events popping up! Try using
                  other filters to uncover the perfect experience for you.
                </p>
                <Button variant="beige" onClick={() => clearFilters()}>
                  Back
                </Button>
              </div>
            )}

            {/*events?.length >= numberOfResults && (
              <Button
                rightIcon={<ArrowDown />}
                variant="beigeOutline"
                className={styles.showMoreButton}
                onClick={() => setNumberOfResults(numberOfResults + 24)}
              >
                Show More
              </Button>
            )*/}
          </>
        ) : (
          <div className={styles.loadingStateContainer}>
            {[...Array(6)].map(() => (
              <div className={styles.loading}>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                  alt="Loading"
                />
                <div className={styles.loadingTitle}>
                  <img
                    src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                    alt="Loading"
                  />
                  <img
                    src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                    alt="Loading"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default EventList

const CompactEventCard = ({ event }) => {
  const history = useHistory()
  return (
    <div
      className={styles.compactEventCard}
      onClick={() => history.push(`/event/${event.event_id}`)}
    >
      <div className={styles.compactEventTitle}>
        <img
          src={
            event?.image
              ? event.image
              : "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png"
          }
          alt={event?.title}
        />
        <div className={styles.compactLeftContent}>
          <h3>{event?.title}</h3>
          <p>
            <span>{event?.venue_name}</span> • <span>{event?.venue_city}</span>{" "}
            • <span>{event?.venue_state}</span>
          </p>
        </div>
      </div>
      <div className={styles.compactRightContent}>
        <div className={styles.compactEventDate}>
          <h3>
            {event?.date_month} {event?.date_day}
          </h3>
          <p>
            {event?.date_local.split(",")[0]} •{" "}
            {event?.date_formatted.split(" • ")[1]}
          </p>
        </div>
        <div className={styles.compactPrice}>
          <span>from</span>
          <p className={styles.priceDescription}>
            {(event?.min_ticket_price / 100).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  )
}

const MobileEventCard = ({ event }) => {
  const history = useHistory()

  return (
    <div
      className={styles.mobileEventCard}
      onClick={() => history.push(`/event/${event?.event_id}`)}
    >
      <div className={styles.mobileEventDate}>
        <span className={styles.mobileMonth}>{event?.date_month}</span>
        <span className={styles.mobileDay}>{event?.date_day}</span>
        <span className={styles.mobileTime}>
          {event?.date_local.split(",")[0]} •{" "}
          {event?.date_formatted.split(" • ")[1]}
        </span>
      </div>
      <div className={styles.mobileRightContent}>
        <h3>{event?.title}</h3>
        <p>
          <span>{event?.venue_name}</span> • <span>{event?.venue_city}</span> •{" "}
          <span>{event?.venue_state}</span>
        </p>
        <p className={styles.mobileTicketPrice}>
          {" "}
          from {(event?.min_ticket_price / 100).toFixed(2)}
        </p>
      </div>
    </div>
  )
}
