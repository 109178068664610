import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialState = {
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  usdc: null,
  numTickets: null,
  numRewards: null,
  cbsa: null,
}

export const updateUser = createAsyncThunk("users/updateUser", async (user) => {
  let url = `${process.env.REACT_APP_HNGR_API}/api/xp/update-user`
  let headers = {
    "Content-Type": "application/json",
  }
  try {
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(user),
    }).then((resp) => resp.json())
    return resp
  } catch (err) {
    console.log("error recording user", err)
  }
})

export const getUserCbsa = createAsyncThunk(
  "users/getUserLocation",
  async (user) => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/get-cbsa`
    let headers = {
      "Content-Type": "application/json",
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(user),
      }).then((resp) => resp.json())
      return resp
    } catch (err) {
      console.log("error getting cbsa", err)
    }
  }
)

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
        // anything else we need to add about the user here
      }
    },
    signOut: (state) => {
      window.authenticating = false
      localStorage.removeItem("publicKey")
      localStorage.removeItem("user")
      state.user = null
      window.location.href = "/"
    },
    setUSDCBalance: (state, action) => {
      state.usdc = action.payload
    },
    setNumTickets: (state, action) => {
      state.numTickets = action.payload
    },
    setNumRewards: (state, action) => {
      state.numRewards = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user.user = action.payload
    })
    builder.addCase(getUserCbsa.fulfilled, (state, action) => {
      state.cbsa = action.payload
    })
  },
})

export const {
  setPreferredLocation,
  setUser,
  signOut,
  setUSDCBalance,
  setNumTickets,
  setNumRewards,
} = userSlice.actions

export default userSlice.reducer
