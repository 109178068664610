export const Eye = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5862 7.66215C13.3308 5.21284 10.8456 3.55566 8.00004 3.55566C5.15443 3.55566 2.66855 5.214 1.41392 7.66239C1.36096 7.76713 1.33337 7.88286 1.33337 8.00023C1.33337 8.1176 1.36096 8.23333 1.41392 8.33808C2.66924 10.7874 5.15443 12.4446 8.00004 12.4446C10.8456 12.4446 13.3315 10.7862 14.5862 8.33785C14.6391 8.2331 14.6667 8.11737 14.6667 8C14.6667 7.88263 14.6391 7.7669 14.5862 7.66215ZM8.00004 11.3335C7.34077 11.3335 6.6963 11.138 6.14814 10.7717C5.59997 10.4054 5.17273 9.88482 4.92044 9.27573C4.66814 8.66664 4.60213 7.99642 4.73075 7.34982C4.85937 6.70321 5.17684 6.10927 5.64301 5.64309C6.10919 5.17691 6.70313 4.85944 7.34974 4.73083C7.99634 4.60221 8.66657 4.66822 9.27566 4.92051C9.88474 5.17281 10.4053 5.60005 10.7716 6.14821C11.1379 6.69638 11.3334 7.34084 11.3334 8.00012C11.3336 8.43792 11.2475 8.87147 11.0801 9.27598C10.9126 9.6805 10.6671 10.048 10.3575 10.3576C10.048 10.6672 9.68042 10.9127 9.27591 11.0802C8.87139 11.2476 8.43784 11.3337 8.00004 11.3335ZM8.00004 5.77789C7.80169 5.78066 7.60462 5.81017 7.41416 5.86562C7.57116 6.07897 7.64649 6.34152 7.62651 6.60566C7.60653 6.86979 7.49255 7.11802 7.30525 7.30532C7.11794 7.49263 6.86972 7.60661 6.60558 7.62659C6.34145 7.64657 6.0789 7.57123 5.86555 7.41424C5.74406 7.86183 5.76599 8.33625 5.92825 8.77072C6.09051 9.20519 6.38494 9.57785 6.77009 9.83622C7.15523 10.0946 7.61171 10.2257 8.07526 10.211C8.53882 10.1964 8.98611 10.0368 9.35418 9.75459C9.72225 9.47242 9.99257 9.08193 10.1271 8.63808C10.2616 8.19423 10.2535 7.71937 10.104 7.28034C9.95455 6.84131 9.67114 6.46022 9.29371 6.19069C8.91628 5.92117 8.46383 5.7768 8.00004 5.77789Z"
      fill="#FDFCEA"
    />
  </svg>
)
