import classNames from "classnames"
import styles from "./DateTag.module.scss"

const DateTag = ({ className, event }) => (
  <div className={classNames(styles.cardDateTag, className)}>
    <div className={styles.month}>{event?.date_month}</div>
    <div className={styles.day}>{event?.date_day}</div>
    <div className={styles.dayName}>
      {event?.date_local.split(",")[0]}
      {" • "}
      {event?.date_formatted.split("•")[1].trim()}
    </div>
  </div>
)



export default DateTag
