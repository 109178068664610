import classNames from "classnames"
import { Tooltip } from "react-tooltip"
import { useState } from "react"
import { SeatMap, SeatMapTevo } from "../"
import { Eye } from "../../css/icons"
import styles from "./TokenSidebar.module.scss"
const { formatCents } = require("../../helpers/money")

const TokenSidebar = ({
  cost,
  ccFee,
  discount,
  date,
  image,
  mapVenueId,
  row,
  section,
  seatFrom,
  seatThru,
  title,
  ticketGroupThumbnail,
  ticketQuantity,
  total,
  sectionVendor,
  selectedEvent,
  selectedPaymentMethod,
  selectedTicketGroup,
  venueName,
  venueCity,
  venueState,
}) => {
  const [showSeatMap, setShowSeatMap] = useState(!ticketGroupThumbnail)

  let mapVendor, mapConfig
  // if (selectedEvent?.seatmap_id_3ddv) {
  //   mapVendor = "3ddv"
  //   mapConfig = {
  //     venueId: selectedEvent.seatmap_id_3ddv,
  //   }
  // } else
  if (selectedEvent?.tevo_venue_id && selectedEvent?.tevo_venue_config_id) {
    mapVendor = "tevo"
    mapConfig = {
      venueId: selectedEvent?.tevo_venue_id,
      configurationId: selectedEvent?.tevo_venue_config_id,
    }
  }


  let ticketGroupFormatted = {
    id: selectedTicketGroup,
    section_vendor: sectionVendor || "",
  }
  // if (!selectedTicketGroup?.section_vendor) {
  // ticketGroupFormatted.section_vendor = sectionVendor || ""
  // }

  console.log(
    mapVendor,
    mapConfig,
    "map info",
    selectedTicketGroup,
    ticketGroupFormatted
  )

  return (
    <div className={styles.leftColumn}>
      <div className={styles.showInfo}>
        <img src={image} alt={title} />
        <div className={styles.showInfoHeader}>
          <div className={styles.showInfoContent}>
            <div className={styles.date}>{date}</div>
            <div className={styles.title}>{title}</div>
            <div className={styles.venue}>
              {venueName}, {venueCity}, {venueState}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.showImageContainer}>
        {showSeatMap ? (
          <div className={styles.venueImage}>
            {mapVendor === "3ddv" && (
              <SeatMap
                config={mapConfig}
                ticketGroups={[selectedTicketGroup]}
                zoomedTicketGroup={selectedTicketGroup}
                highlightedSections={[]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
              />
            )}

            {mapVendor === "tevo" && (
              <SeatMapTevo
                config={mapConfig}
                ticketGroups={[ticketGroupFormatted]}
                zoomedTicketGroup={ticketGroupFormatted}
                highlightedSections={[]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
              />
            )}
            {ticketGroupThumbnail && (
              <div
                className={styles.seatmapToggle}
                onClick={() => setShowSeatMap(false)}
              >
                <Eye />
                Seatmap
              </div>
            )}
          </div>
        ) : (
          <img
            className={styles.showImage}
            alt={title}
            src={
              ticketGroupThumbnail ||
              "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/seatmap.png"
            }
          />
        )}
      </div>
      <div className={styles.ticketInfo}>
        <div className={styles.ticketCount}>
          <span className={styles.ticketLabel}>Tickets</span>
          <span className={styles.ticketInformation}>{ticketQuantity}</span>
        </div>
        <div className={styles.sectionCount}>
          <div className={styles.sectionItem}>
            <span className={styles.sectionLabel}>Section</span>
            <span className={styles.sectionInformation}>{section}</span>
          </div>
          <div className={styles.sectionItem}>
            <span className={styles.sectionLabel}>Row</span>
            <span className={styles.sectionInformation}>{row}</span>
          </div>
          {seatFrom && seatThru && (
            <div className={styles.sectionItem}>
              <span className={styles.sectionLabel}>Seat</span>
              <span className={styles.sectionInformation}>
                {seatFrom} - {seatThru}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.priceInfo}>
        {/* we don't have cost in the nft view of this component */}
        {cost && (
          <div className={styles.metadataRow}>
            <div className={styles.metadataItemDetail}>Subtotal</div>
            <div className={styles.metadataItemDetail}>
              {ticketQuantity} x {formatCents(cost)}
            </div>
          </div>
        )}

        {selectedPaymentMethod === "cc" && false && (
          <div className={styles.metadataRow}>
            <Tooltip id="my-tooltip" />
            <div
              className={classNames(styles.metadataItemValue)}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Avoid the Credit Card Processing Fee by paying with USDC"
            >
              CC Fee ⓘ
            </div>
            <div className={classNames(styles.metadataItemValue)}>${ccFee}</div>
          </div>
        )}

        {discount && (
          <div className={styles.metadataRow}>
            <div className={classNames(styles.metadataItemValue)}>Discount</div>
            <div className={classNames(styles.metadataItemValue)}>
              -{discount}
            </div>
          </div>
        )}
        <div className={styles.metadataRow}>
          <div
            className={classNames(
              styles.metadataItemValue,
              styles.metadataLarge
            )}
          >
            Total
          </div>
          <div
            className={classNames(
              styles.metadataItemValue,
              styles.metadataLarge
            )}
          >
            {total}
          </div>
        </div>
        <div className={classNames(styles.disclaimer, styles.tax)}>
          Sales Tax and Applicable Fees included in Total
        </div>
      </div>
    </div>
  )
}

export default TokenSidebar
