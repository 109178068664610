import classNames from "classnames"
import styles from "./ArtistCard.module.scss"
import { useHistory } from "react-router-dom"

const ArtistCard = ({ className, event }) => {
  const history = useHistory()

  if (!event) {
    return <div className={classNames(styles.card, className, styles.loader)} />
  }

  return (
    <div className={classNames(styles.cardContainer, className)}>
      <div
        className={styles.card}
        style={
          event?.image
            ? { backgroundImage: `url(${event.image})` }
            : {
                backgroundImage: `url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png)`,
              }
        }
        onClick={() => {
          history.push(`/artist/${event.performer_id}`)
        }}
      >
        <div className={styles.cardBottom}>
          <div className={styles.titleContainer}>
            <h3>{event?.performer_name}</h3>
          </div>

          <div className={styles.priceContainer}>
            <span>from</span>
            <p className={styles.priceDescription}>
              <span className={styles.price}>
                ${Math.ceil(event?.min_ticket_price / 100)}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArtistCard
