import { useEffect, useRef } from "react"
import { nanoid } from "nanoid"
import Tevomaps from "@ticketevolution/seatmaps-client"

const SeatMapTevo = ({
  config,
  ticketGroups,
  highlightedSections,
  zoomedTicketGroup,
  handleSectionHighlight,
  handleSectionThumbLoaded,
}) => {
  const containerId = useRef(`viewer-${nanoid()}`)
  let seatmap = useRef(null)
  let seatmapApi = useRef(null)
  let highlightedSection = useRef(null)
  let selectedSections = useRef([])

  useEffect(() => {
    // create a new seatmap
    if (!seatmap.current && ticketGroups?.length > 0) {
      seatmap.current = new Tevomaps({
        venueId: config.venueId,
        configurationId: config.configurationId,
        showControls: false,
        showLegend: false,
        mapFontFamily: "helvetica",
        sectionPercentiles: {
          0.2: "#A8CEFC",
          0.4: "#A8CEFC",
          0.6: "#A8CEFC",
          0.8: "#A8CEFC",
          1: "#A8CEFC",
        },
        onSelection: (sectionIds) => {
          if (sectionIds.length > selectedSections.current.length) {
            // section was added
            const tg = ticketGroups.find((tg) => {
              return tg.section_vendor === sectionIds[sectionIds.length - 1]
            })
            handleSectionHighlight(tg.section)
            selectedSections.current.push(sectionIds[sectionIds.length - 1])
          } else {
            // section was removed
            const section_vendor = selectedSections.current.find((sv) => {
              return !sectionIds.includes(sv)
            })
            const tg = ticketGroups.find((tg) => {
              return tg.section_vendor === section_vendor
            })
            handleSectionHighlight(tg.section)
            selectedSections.current = selectedSections.current.filter((s) => {
              return s !== section_vendor
            })
          }
        },
        ticketGroups: ticketGroups.map((tg) => {
          return {
            retail_price: (tg?.cost / 100).toFixed(2),
            tevo_section_name: tg?.section_vendor,
          }
        }),
      })

      // turn element with ID of 'my-map' into a seatmap for config 1046
      seatmapApi.current = seatmap.current.build(containerId.current)
    }

    //seatmapApi.current.selectSection("lower 111");
  }, [config])

  useEffect(() => {
    if (highlightedSection.current)
      seatmapApi.current?.unhighlightSection(highlightedSection.current)
    if (zoomedTicketGroup) {
      //console.log(`You're hovering over section ${zoomedTicketGroup.section} `);
      highlightedSection.current = zoomedTicketGroup.section_vendor
      seatmapApi.current.highlightSection(highlightedSection.current)
    } else {
      if (highlightedSection.current) {
        highlightedSection.current = null
      }
    }
  }, [zoomedTicketGroup])

  useEffect(() => {
    if (highlightedSections?.length === 0) {
      selectedSections.current.forEach((section) => {
        seatmapApi.current.deselectSection(section)
      })
    }
  }, [highlightedSections])

  useEffect(() => {
    if (ticketGroups?.length > 0) {
      seatmapApi.current.updateTicketGroups(
        ticketGroups.map((tg) => {
          return {
            retail_price: (tg?.cost / 100).toFixed(2),
            tevo_section_name: tg?.section_vendor,
          }
        })
      )
    }
  }, [ticketGroups])

  return (
    <>
      <div style={{ height: "100%" }} id={containerId.current}></div>
    </>
  )
}

export default SeatMapTevo
