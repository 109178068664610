import styles from "./EventCarousel.module.scss"
import { useHistory } from "react-router-dom"
import { EventCard, Button, ArtistCard } from "../"
import { ArrowUpRight } from "../../css/icons"

const EventCarousel = ({ buttonCta, title, events, type, link }) => {
  const history = useHistory()

  const seen = {}

  const filterEvents = (events) => {
    return events.filter((event) => {
      if (!event.min_ticket_price) return false

      if (event.performer_id in seen) {
        seen[event.performer_id] += 1
        return false
      }
      if (!(event.performer_id in seen)) {
        seen[event.performer_id] = 0
        return true
      }
      return false
    })
  }

  return (
    <div className={styles.homeDiscoveryRow}>
      <div className={styles.discoverySectionHeader}>
        <h2>{title}</h2>
        <Button
          compact
          className={styles.button}
          variant="transparent"
          onClick={() => history.push(link)}
          rightIcon={<ArrowUpRight />}
        >
          {window.innerWidth > 960 && buttonCta}
        </Button>
      </div>
      <div className={styles.discoverySectionList}>
        {type === "big" &&
          filterEvents(events)?.map((event, i) => {
            if (i > 1) return null
            return (
              <EventCard
                key={event.name}
                className={styles.card2w}
                event={event}
              />
            )
          })}

        {type !== "big" &&
          filterEvents(events).map((event, i) => {
            if (i > 6) return null
            if (seen[event.performer_id] > 1) {
              return <ArtistCard event={event} key={event.event_id} />
            } else {
              return (
                <EventCard
                  event={event}
                  className={styles.card4w}
                  key={event.name}
                />
              )
            }
          })}
      </div>
    </div>
  )
}
export default EventCarousel
