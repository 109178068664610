import React from "react"
import { createRoot } from "react-dom/client"
import { Provider as ReduxProvider } from "react-redux"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import ReactPixel from "react-facebook-pixel"
import WalletContext from "./contexts/WalletContext"
import { store } from "./store"
import "./css/global.scss"

// routes
import VelvetRope from "./Home/NewVelvet"

import HolderNFTs from "./Holder/NFTs"
import HolderNFT from "./Holder/NFT"
import CoinbasePayTest from "./CoinbasePayTest"

import EventList from "./Events/EventList"
import EventPage from "./Events/EventPage"
import ArtistPage from "./Artist/ArtistPage"
import VenuePage from "./Venue/VenuePage"
import CheckoutPage from "./Checkout/CheckoutPage"
import AccountPage from "./Account/AccountPage"
import RewardPage from "./Account/RewardPage"
import NoMatch from "./Home/NoMatch"
import CCTPPage from "./Account/CCTPPage"
import Redeem from "./Redeem/Redeem"

import * as Sentry from "@sentry/react"
import LogRocket from "logrocket"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

var originalFetch = window.fetch
window.fetch = function (input, init) {
  if (
    input &&
    input.indexOf(process.env.REACT_APP_HNGR_API) === -1 &&
    input.indexOf(process.env.REACT_APP_EXPRESS_API) === -1
  ) {
    return originalFetch(input, init)
  }
  if (!init) {
    init = {}
  }
  if (!init.headers) {
    init.headers = new Headers()
  }

  if (process.env.REACT_APP_SKIN) {
    if (init.headers instanceof Headers) {
      init.headers.append("XP-SKIN", process.env.REACT_APP_SKIN)
    } else if (init.headers instanceof Array) {
      init.headers.push(["XP-SKIN", process.env.REACT_APP_SKIN])
    } else {
      init.headers["XP-SKIN"] = process.env.REACT_APP_SKIN
    }
  }

  return originalFetch(input, init)
}

if (process.env.REACT_APP_HNGR_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_HNGR_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

if (process.env.REACT_APP_LOGROCKET) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET)
}

const App = () => {
  let CheckoutComponent = CheckoutPage

  const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  }

  ReactPixel.init("1514233069351079", advancedMatching, options)

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Switch>
        <Route path="/" exact component={VelvetRope} />

        <Route path="/mike" exact>
          <Redirect to="/campaign/saunders3000" />
        </Route>

        <Route path="/campaign/:campaign_name" exact component={VelvetRope} />
        <Route path="/c/:campaign_name" exact component={VelvetRope} />

        <Route path="/holder/tickets" exact component={HolderNFTs} />

        <Route path="/holder/ticket/:mint" exact component={HolderNFT} />

        <Route path="/events" exact component={EventList} />

        <Route path="/events/cbsa/:cbsa" exact component={EventList} />

        <Route path="/events/search/:query" exact component={EventList} />
        <Route path="/events/city/:city/:state" exact component={EventList} />

        <Route path="/event/:event_id" exact component={EventPage} />

        <Route
          path="/checkout/:event_id/:ticket_group_id/:revision"
          exact
          component={CheckoutComponent}
        />

        <Route
          path="/checkout/:event_id/:ticket_group_id/:revision/tickets"
          exact
          component={CheckoutComponent}
        />

        <Route
          path="/checkout/:event_id/:ticket_group_id/:revision/delivery"
          exact
          component={CheckoutComponent}
        />

        <Route
          path="/checkout/:event_id/:ticket_group_id/:revision/payment"
          exact
          component={CheckoutComponent}
        />

        <Route
          path="/checkout/:event_id/:ticket_group_id/:revision/success"
          exact
          component={CheckoutComponent}
        />

        <Route path="/artist/:artist_id" exact component={ArtistPage} />
        <Route path="/venue/:venue_id" exact component={VenuePage} />

        <Route path="/account" exact component={AccountPage} />
        <Route path="/account/rewards" exact component={RewardPage} />

        <Route path="/account/cctp" exact component={CCTPPage} />

        <Route path="/coinbase" exact component={CoinbasePayTest} />
        <Route path="/redeem" exact component={Redeem} />

        <Route path="*" component={NoMatch} />
      </Switch>
    </div>
  )
}

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <WalletContext>
        <App />
      </WalletContext>
    </BrowserRouter>
  </ReduxProvider>
)
