import { useHistory } from "react-router-dom"
import classNames from "classnames"
import { useEffect, useState } from "react"
import styles from "./NFTListItem.module.scss"
import { Ticket } from "../css/icons"

const NFTListItem = ({ nft }) => {
  const history = useHistory()
  const sealed = nft.metadata._json.secret?.state === "sealed"
  const [order, setOrder] = useState(null)

  const dateAttr = nft.metadata._json.attributes?.find(
    (attr) => attr.trait_type === "Date"
  )?.value

  const monthDate = new Date(dateAttr + "T00:00:00").toLocaleString("en-US", {
    month: "short",
    day: "numeric",
  })

  const day = new Date(dateAttr + "T00:00:00").toLocaleString("en-US", {
    weekday: "short",
  })

  console.log(monthDate, day, "the date")

  const timeAttr = nft.metadata._json.attributes?.find(
    (attr) => attr.trait_type === "Showtime"
  )?.value

  const venueName =
    nft.metadata._json.attributes?.find(
      (attr) => attr.trait_type === "Venue Name"
    )?.value ||
    nft.metadata._json.attributes?.find((attr) => attr.trait_type === "Venue")
      ?.value

  const venueCity =
    nft.metadata._json.attributes?.find((attr) => attr.trait_type === "City")
      ?.value ||
    nft.metadata._json.attributes?.find(
      (attr) => attr.trait_type === "Venue City"
    )?.value

  const getOrder = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/get-order`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      mint: nft.mint.address.toString(),
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      resp = await resp.json()
      setOrder(resp)
    } catch (err) {
      console.log(err, "Error in get-order")
      // alert("err")
    }
  }

  useEffect(() => {
    if (nft.mint.address) {
      getOrder()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={styles.nftListItem}
      onClick={() => {
        let mint = nft.mint.address.toString()
        history.push(`/holder/ticket/${mint}`)
      }}
    >
      <div
        className={classNames(
          styles.banner,
          sealed ? styles.actionRequired : styles.transferred
        )}
      >
        {sealed ? "Action Required" : "Transferred"}
      </div>
      <div className={styles.nftInfo}>
        <img
          alt="img"
          className={styles.nftImage}
          src={nft.metadata._json.image}
        />

        <div className={styles.metadata}>
          <div className={styles.titleContainer}>
            <div className={styles.nftTitle}>
              {
                nft.metadata._json.attributes?.find(
                  (attr) => attr.trait_type === "Artist"
                )?.value
              }
            </div>
            <div className={styles.nftVenue}>
              {venueName} • {venueCity}
            </div>
          </div>{" "}
          <div className={styles.rightContent}>
            <div className={styles.ticketQuantity}>
              <Ticket /> {order?.quantity}{" "}
              {order?.quantity > 1 ? "Tickets" : "Ticket"}
            </div>
            <div className={styles.nftDate}>
              <span className={styles.monthDate}>{monthDate}</span>
              <span className={styles.dayTime}>
                {day} • {timeAttr}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default NFTListItem
