import { Button, Modal } from "../components"
import styles from "./DispenserSuccess.module.scss"
import { X } from "../css/icons"
import { useHistory } from "react-router-dom"

const DispenserSuccess = ({ discount, onClose, minimum, expiration }) => {
  const history = useHistory()
  return (
    <Modal
      className={styles.blackBackground}
      width="medium"
      onClose={() => onClose()}
      containerClass={styles.modalContainer}
    >
      <div className={styles.dispenserSuccessModal}>
        <X className={styles.close} onClick={() => onClose()} />
        <h1>Your reward has been linked to your account</h1>
        <p>
          Remember to apply it at checkout for great savings on your next event!
        </p>
        <div className={styles.imageContainer}>
          <img
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Redemption+Image.png"
            alt="discount"
          />
          <div className={styles.discountAmount}>{discount}</div>
        </div>
        <div className={styles.discountDetails}>
          {/* {expiration && (
            <div className={styles.detail}>
              <span>Expiration</span>
              <span>{expiration}</span>
            </div>
          )} */}
          {minimum && (
            <div className={styles.detail}>
              <span>Minimum Spend</span>
              <span>{minimum}</span>
            </div>
          )}
        </div>
        <div className={styles.buttonRow}>
          <Button
            className={styles.button}
            variant="gray"
            onClick={() => onClose()}
            fullWidth
          >
            Not Now
          </Button>
          <Button
            fullWidth
            variant="blue"
            onClick={() => history.push("/events")}
          >
            Browse Events
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default DispenserSuccess
