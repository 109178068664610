import classNames from "classnames"
import styles from "./EventCard.module.scss"
import { useHistory } from "react-router-dom"

const EventCard = ({ className, event }) => {
  const history = useHistory()

  if (!event) {
    return <div className={classNames(styles.card, className, styles.loader)} />
  }

  return (
    <div className={classNames(styles.cardContainer, className)}>
      <div
        className={styles.card}
        style={
          event?.image
            ? { backgroundImage: `url(${event.image})` }
            : {
                backgroundImage: `url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png)`,
              }
        }
        onClick={() => {
          history.push(`/event/${event.event_id}`)
        }}
      >
        <div className={styles.cardTop}>
          <div className={styles.date}>
            {event?.date_local.split(",")[0]}, {event?.date_month}{" "}
            {event?.date_day}
          </div>
          <div className={styles.time}>
            {event?.date_formatted.split(" • ")[1]}
          </div>
        </div>
        <div className={styles.cardBottom}>
          <div className={styles.titleContainer}>
            <h3>{event?.short_title || event?.title}</h3>
            <p>
              <span>{event?.venue_name}</span>•<span>{event?.venue_city}</span>•
              <span>{event?.venue_state}</span>
            </p>
          </div>

          <div className={styles.priceContainer}>
            <span>from</span>
            <p className={styles.priceDescription}>
              <span className={styles.price}>
                ${event?.min_ticket_price / 100}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventCard
