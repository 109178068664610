import { useHistory } from "react-router-dom"
import { Button } from "../components"
import styles from "./NoNfts.module.scss"
import { Unavailable } from "../css/icons"

const NoNFTs = () => {
  const history = useHistory()
  return (
    <div className={styles.container}>
      <div className={styles.new}>
        <Unavailable />
        <h2>No tickets yet...</h2>
        <h2>Ready to unlock an experience?</h2>
        <p>With XP you can get the best price on tickets without junk fees.</p>
        <div className={styles.tileContainer}>
          <Button
            variant="blue"
            square
            fullWidth
            onClick={() => history.push("/events")}
          >
            Find Your Next Event
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NoNFTs
