import { useDispatch, useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import { useEffect, useState, useRef } from "react"
import { useParams, useHistory } from "react-router-dom"
import classNames from "classnames"
import { Button, Layout, Tag, SeatMap, SeatMapTevo } from "../components"
import {
  AngleDown,
  AngleLeft,
  CheckMark,
  Dollar,
  Eye,
  EyeClosed,
  Info,
  Lightning,
  Person,
  RedX,
  Swap,
  X,
} from "../css/icons"
import { getEventById } from "../reducers/eventSlice"
import styles from "./EventPage.module.scss"
import {
  setSelectedTicketGroup,
  setSelectedTicketThumb,
} from "../reducers/ticketSlice"
import TicketSelectorModal from "./TicketSelectorModal"
import ShowInfoModal from "./ShowInfoModal"
import { useDetectClickOutside } from "../hooks/detectClickOutside"
import PriceRangeModal from "./PriceRangeModal"

const EventPage = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const event = useSelector((state) => state.events.selectedEvent)
  const eventLoading = useSelector((state) => state.events.eventLoading)
  const [ticketGroups, setTicketGroups] = useState([])
  const ticketGroupsLoading = useSelector(
    (state) => state.tickets.ticketGroupsLoading
  )
  const [loading, setLoading] = useState(true)
  const [availableQuantities, setAvailableQuantities] = useState([])
  const [selectedQuantity, setSelectedQuantity] = useState(2)
  const [hasSelectedQuantity, setHasSelectedQuantity] = useState(true)
  const [filteredTicketGroups, setFilteredTicketGroups] = useState([])
  const [zoomedTicketGroup, setZoomedTicketGroup] = useState()
  const [highlightedSections, setHighlightedSections] = useState([])
  const highlightedSectionsRef = useRef(highlightedSections)
  const [sectionThumbnails, setSectionThumbnails] = useState([])
  const [showSeatmap, setShowSeatmap] = useState(true)
  const dateRef = useRef()
  const [showTicketSelectorModal, setShowTicketSelectorModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [sort, setSort] = useState("Low to High")
  const [showSortMenu, setShowSortMenu] = useState(false)
  const [showPriceRangeModal, setShowPriceRangeModal] = useState(false)

  const [apiFilteredTicketGroups, setApiFilteredTicketGroups] = useState([])

  const [priceRange, setPriceRange] = useState({
    minPrice: 0,
    maxPrice: 100000,
  })
  const [initialMaxAvailable, setInitialMaxAvailable] = useState(0)
  const [initialMinAvailable, setInitialMinAvailable] = useState(0)

  const prevMinPriceRef = useRef()
  const prevMaxPriceRef = useRef()

  const sortMenuRef = useRef()
  const ticketListRef = useRef()

  const scrollToTopOfList = () => {
    ticketListRef.current.scroll({
      top: 0,
    })
  }

  useEffect(() => {
    if (!eventLoading && event) {
      setInitialMaxAvailable(event?.max_ticket_price / 100)
      setInitialMinAvailable(event?.min_ticket_price / 100)
      setPriceRange({
        minPrice: event?.min_ticket_price / 100,
        maxPrice: event?.max_ticket_price / 100,
      })
    }
  }, [eventLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useDetectClickOutside(sortMenuRef, () => {
    setShowSortMenu(false)
  })

  useEffect(() => {
    document.body.style.overflow = "auto" // reset the overflow
    dispatch(getEventById(params.event_id))
    // call this twice to set all ticket groups and filtered ticket groups with no filters set
    getAllTicketGroups({ eventId: params.event_id, withFilter: false })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (apiFilteredTicketGroups?.length) {
      filterTicketGroups(selectedQuantity)
    }

    if (availableQuantities.length == 0 && ticketGroups?.length) {
      calculateAvailableQuantities()
    }
  }, [apiFilteredTicketGroups, sort]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedQuantity && filteredTicketGroups.length == 0) {
      filterTicketGroups(selectedQuantity)
    }
  }, [selectedQuantity]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    highlightedSectionsRef.current = highlightedSections
  }, [highlightedSections])

  useEffect(() => {
    if (
      prevMinPriceRef.current !== priceRange?.minPrice ||
      prevMaxPriceRef.current !== priceRange?.maxPrice
    ) {
      getAllTicketGroups({
        eventId: params.event_id,
        sortOrder: sort,
        priceRange,
        withFilter: true,
      })
      return
    }
  }, [priceRange]) // eslint-disable-line react-hooks/exhaustive-deps

  const getAllTicketGroups = async ({
    eventId,
    sortOrder,
    priceRange,
    withFilter,
  }) => {
    try {
      let headers = {
        "Content-Type": "application/json",
      }
      const endpoint = "/api/stagehand/event/tickets"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`
      const getSort = () => {
        if (sortOrder === "High to Low") return "desc"
        if (sortOrder === "Low to High") return "asc"
        if (sortOrder === "Section") return "asc"
        return sortOrder
      }
      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({
          event_id: eventId,
          order_by: getSort(),
          min_price: priceRange?.minPrice * 100,
          max_price: priceRange?.maxPrice * 100,
        }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })

      if (withFilter) {
        setApiFilteredTicketGroups(response.ticket_groups)
      } else {
        setTicketGroups(response.ticket_groups)
        setApiFilteredTicketGroups(response.ticket_groups)
      }

      return response.ticket_groups
    } catch (err) {
      console.error(err)
    }
  }

  const filterTicketGroups = (quantity) => {
    // let's do the filtering right here..... each ticket group
    // needs to have quantity and
    let filteredGroups = []
    for (let i in apiFilteredTicketGroups) {
      let tg = apiFilteredTicketGroups[i]
      if (tg.ticket_quantity < quantity) {
        continue
        // eslint-disable-next-line eqeqeq
      } else if (tg.ticket_quantity == quantity) {
        filteredGroups.push(tg)
      } else if (tg.ticket_quantity > quantity) {
        // tevo
        if (tg.splits) {
          if (tg.splits.includes(quantity)) {
            filteredGroups.push(tg)
          }
        } else {
          if (tg.split_type === "ANY") {
            filteredGroups.push(tg)
          } else if (tg.split_type === "NEVERLEAVEONE") {
            if (tg.ticket_quantity - 1 > quantity) {
              filteredGroups.push(tg)
            }
          }
        }
      } else {
        console.log("Bug in logic. Tell Scott")
      }
    }

    setFilteredTicketGroups(filteredGroups)
  }

  const calculateAvailableQuantities = () => {
    let quantities = []
    for (let i in ticketGroups) {
      let tg = ticketGroups[i]
      if (tg.splits) {
        quantities = quantities.concat(tg.splits)
      } else {
        if (tg.split_type === "ANY") {
          for (let j = 1; j < tg.ticket_quantity; j++) {
            if (quantities.indexOf(j) !== -1) {
              quantities.push(j)
            }
          }
        } else if (tg.split_type === "NEVERLEAVEONE") {
          // any quantity except for one less than the current quantity
          // if the current quantity is 2, push 2
          // if the current quantity is 4, push 1, 2, 4
          for (let j = 1; j <= tg.ticket_quantity - 1; j++) {
            if (tg.ticket_quantity !== 2 && !quantities.includes(j)) {
              quantities.push(j)
            }
          }

          if (!quantities.includes(tg.ticket_quantity)) {
            quantities.push(tg.ticket_quantity)
          }
        }
      }
    }

    quantities.sort((a, b) => a - b)
    setLoading(false)
    setAvailableQuantities(quantities)
    if (quantities.indexOf(2) > -1) {
      setSelectedQuantity(2)
    } else {
      setSelectedQuantity(quantities[0])
    }
  }

  const zoomMap = (ticketGroup) => {
    setZoomedTicketGroup(ticketGroup)
  }

  const handleSectionHighlight = (sections) => {
    if (typeof sections === "string") {
      sections = new Set([sections])
    }
    let currentSections = new Set(highlightedSectionsRef.current)

    function symmetricDifference(setA, setB) {
      let aMinusB = new Set([...setA].filter((x) => !setB.has(x)))
      let bMinusA = new Set([...setB].filter((x) => !setA.has(x)))
      return new Set([...aMinusB, ...bMinusA])
    }
    setHighlightedSections(
      Array.from(symmetricDifference(currentSections, sections))
    )
    //setHighlightedSections(
    //  Array.from(currentSections.symmetricDifference(sections))
    //)
    setSort("Section")
  }

  const handleSectionThumbLoaded = (thumbnails) => {
    setSectionThumbnails(thumbnails)
  }

  // todo: make a better loading screen
  if (eventLoading) return <div>Loading...</div>

  const showInstantTags = false // turn off until we have data since they'll all be tradable

  let mapVendor, mapConfig
  if (event?.seatmap_id_3ddv) {
    mapVendor = "3ddv"
    mapConfig = {
      venueId: event.seatmap_id_3ddv,
    }
  } else if (event?.tevo_venue_id && event?.tevo_venue_config_id) {
    mapVendor = "tevo"
    mapConfig = {
      venueId: event?.tevo_venue_id,
      configurationId: event?.tevo_venue_config_id,
    }
  }

  const title = event?.short_title || event?.title
  const shortTitle =
    title?.length > 35 ? `${title?.substring(0, 35)}...` : title

  const metaTitle = `${shortTitle} | ${event?.venue_city}, ${event?.venue_state} | 
  ${event?.date_formatted} | XP — Access your favorite shows`
  const metaDescription = `Get tickets to ${
    event?.performer_name || shortTitle
  } and to your other favorite concerts, sports games, & more, with no hidden fees.`
  const shareFallbackImage =
    "https://hngr-icons.s3.amazonaws.com/ticketdex/XP-Social-Share-Image.png"

  return (
    <Layout
      className={classNames(
        styles.eventPage,
        !hasSelectedQuantity && styles.shouldScrollOuter
      )}
      showHeader={window.innerWidth > 960}
      noScroll
    >
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
        <meta property="twitter:title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta property="twitter:description" content={metaDescription} />
        <meta
          property="og:image"
          content={event?.image || shareFallbackImage}
        />
        <meta
          property="twitter:image"
          content={event?.image || shareFallbackImage}
        />
      </Helmet>
      {showTicketSelectorModal && (
        <TicketSelectorModal
          availableQuantities={availableQuantities}
          dateRef={dateRef}
          onClose={() => {
            setShowTicketSelectorModal(false)
            document.body.style.overflow = "unset"
          }}
          selectedQuantity={selectedQuantity}
          setSelectedQuantity={setSelectedQuantity}
          filterTicketGroups={filterTicketGroups}
          ticketGroupsLoading={ticketGroupsLoading}
          setHasSelectedQuantity={setHasSelectedQuantity}
        />
      )}
      {showInfoModal && (
        <ShowInfoModal event={event} onClose={() => setShowInfoModal(false)} />
      )}
      <div
        className={classNames(
          styles.eventPageContainer,
          !hasSelectedQuantity && styles.shouldScroll
        )}
      >
        <div className={styles.ticketListContainer}>
          <div className={styles.ticketListHeader}>
            {window.innerWidth < 960 && (
              <AngleLeft
                onClick={() => history.goBack()}
                className={styles.back}
              />
            )}
            <img
              src={
                event?.image ||
                "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png"
              }
              alt={event?.title}
            />
            <div className={styles.eventInfo}>
              <div className={styles.date} ref={dateRef}>
                {event?.date_formatted?.replace(",", " |").replace("•", "|")}
              </div>
              <h1>{window.innerWidth < 960 ? shortTitle : title}</h1>
              <div className={styles.venue}>
                {event?.venue_name}, {event?.venue_city}, {event?.venue_state}{" "}
                <Info
                  onClick={() => setShowInfoModal(true)}
                  className={styles.desktopInfo}
                />
              </div>
            </div>
          </div>

          <div
            className={classNames(
              styles.mobileVenueImageContainer,
              showSeatmap && styles.seatmapOpen,
              !showSeatmap && styles.zeroHeight
            )}
          >
            {showSeatmap && (
              <>
                {mapVendor ? (
                  <div className={styles.venueImage}>
                    {mapVendor === "3ddv" && (
                      <SeatMap
                        config={mapConfig}
                        ticketGroups={filteredTicketGroups}
                        zoomedTicketGroup={zoomedTicketGroup}
                        highlightedSections={highlightedSections}
                        handleSectionHighlight={handleSectionHighlight}
                        handleSectionThumbLoaded={handleSectionThumbLoaded}
                        translationRules={event?.translations_3ddv}
                      />
                    )}
                    {mapVendor === "tevo" && (
                      <SeatMapTevo
                        config={mapConfig}
                        ticketGroups={filteredTicketGroups}
                        zoomedTicketGroup={zoomedTicketGroup}
                        highlightedSections={highlightedSections}
                        handleSectionHighlight={handleSectionHighlight}
                        handleSectionThumbLoaded={handleSectionThumbLoaded}
                      />
                    )}
                  </div>
                ) : (
                  <img
                    src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/seatmap.png"
                    alt="Placeholder "
                    className={styles.venueImage}
                  />
                )}
              </>
            )}
          </div>

          <div className={styles.filters}>
            <div className={styles.optionsContainer}>
              <div
                className={styles.select}
                onClick={() => {
                  document.body.style.overflow = "hidden"
                  setShowTicketSelectorModal(true)
                }}
              >
                {selectedQuantity}{" "}
                {window.innerWidth > 960 && (
                  <>{selectedQuantity === 1 ? "ticket" : "tickets"}</>
                )}
                <Person />
              </div>
            </div>
            <div className={styles.priceRangeContainer}>
              <Button
                className={styles.filterButton}
                leftIcon={<Dollar />}
                onClick={() => setShowPriceRangeModal(true)}
                variant="transparent"
                compact
                loading={eventLoading}
              >
                {prevMaxPriceRef.current
                  ? `${priceRange.minPrice} - ${priceRange.maxPrice}`
                  : `Price Range`}
              </Button>
              {showPriceRangeModal && (
                <PriceRangeModal
                  onClose={() => setShowPriceRangeModal(false)}
                  priceRange={priceRange}
                  setPriceRange={(min, max) => {
                    prevMinPriceRef.current = priceRange?.minPrice
                    prevMaxPriceRef.current = priceRange?.maxPrice
                    setPriceRange({ minPrice: min, maxPrice: max })
                  }}
                  maxAvailable={initialMaxAvailable}
                  minAvailable={initialMinAvailable}
                  reset={() => {
                    prevMaxPriceRef.current = null
                    prevMinPriceRef.current = null
                    setPriceRange({
                      minPrice: initialMinAvailable,
                      maxPrice: initialMaxAvailable,
                    })
                    getAllTicketGroups({
                      eventId: params.event_id,
                      withFilter: true,
                    })
                  }}
                />
              )}
            </div>
            {window.innerWidth < 960 && (
              <Button
                variant="transparent"
                className={styles.filterButton}
                compact
                onClick={() => setShowSeatmap(!showSeatmap)}
                rightIcon={showSeatmap ? <EyeClosed /> : <Eye />}
              >
                Map
              </Button>
            )}
          </div>
          <div className={styles.sortRow}>
            <div className={styles.listingCount}>
              {filteredTicketGroups.length} Listings
            </div>
            <div className={styles.sortButtonsContainer}>
              <div className={styles.sortMenuContainer}>
                <div
                  className={styles.sortButton}
                  onClick={() => {
                    setShowSortMenu(true)
                  }}
                >
                  Sort: {sort} <AngleDown />
                </div>
                {showSortMenu && (
                  <div className={styles.sortMenu} ref={sortMenuRef}>
                    <div
                      className={classNames(
                        styles.sortOption,
                        sort === "Low to High" && styles.selectedSortOption
                      )}
                      onClick={() => {
                        setSort("Low to High")
                        getAllTicketGroups({
                          eventId: params.event_id,
                          sortOrder: "asc",
                          priceRange,
                          withFilter: true,
                        })
                        setShowSortMenu(false)
                        scrollToTopOfList()
                      }}
                    >
                      Price: Low to High
                      {sort === "Low to High" && <CheckMark />}
                    </div>
                    <div
                      className={classNames(
                        styles.sortOption,
                        sort === "High to Low" && styles.selectedSortOption
                      )}
                      onClick={() => {
                        setSort("High to Low")
                        getAllTicketGroups({
                          eventId: params.event_id,
                          sortOrder: "desc",
                          priceRange,
                          withFilter: true,
                        })
                        setShowSortMenu(false)
                        scrollToTopOfList()
                      }}
                    >
                      Price: High to Low
                      {sort === "High to Low" && <CheckMark />}
                    </div>
                    <div
                      className={classNames(
                        styles.sortOption,
                        sort === "Section" && styles.selectedSortOption
                      )}
                      onClick={() => {
                        setSort("Section")
                        getAllTicketGroups({
                          eventId: params.event_id,
                          sort: "asc",
                          priceRange,
                          withFilter: true,
                        })
                        setShowSortMenu(false)
                        scrollToTopOfList()
                      }}
                    >
                      <span>Sort by Section</span>
                      {sort === "Section" && <CheckMark />}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={styles.clear}
                onClick={() => {
                  setHighlightedSections([]) // reset sections
                  setSort("Section")
                  prevMaxPriceRef.current = null
                  prevMinPriceRef.current = null
                  setPriceRange({
                    minPrice: initialMinAvailable,
                    maxPrice: initialMaxAvailable,
                  })
                  getAllTicketGroups({
                    eventId: params.event_id,
                    sortOrder: "asc",
                    withFilter: true,
                  })
                  scrollToTopOfList()
                }}
              >
                Clear <X />
              </div>
            </div>
          </div>

          {loading && (
            <div className={styles.ticketSelector}>
              <h2>Loading Tickets... </h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <img
                  style={{ height: "50px", width: "50px" }}
                  src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/loading-gif.gif"
                  alt="loading"
                />
              </div>
            </div>
          )}

          {hasSelectedQuantity && (
            <>
              <div
                className={classNames(
                  styles.ticketGroupList,
                  window.innerWidth < 960 &&
                    !showSeatmap &&
                    styles.fullHeightList
                )}
                ref={ticketListRef}
              >
                {filteredTicketGroups?.length === 0 && (
                  <div className={styles.noTickets}>
                    <RedX />
                    <div>No tickets found</div>
                    <p>Try another search or adjust your filters</p>
                  </div>
                )}
                {filteredTicketGroups
                  ?.filter((ticketGroup) => {
                    return (
                      highlightedSections?.length == 0 ||
                      highlightedSections.includes(ticketGroup.section)
                    )
                  })
                  .map((ticketGroup) => (
                    <div
                      className={styles.ticketInfoListItem}
                      key={ticketGroup.id}
                      onClick={() => {
                        dispatch(setSelectedTicketGroup(ticketGroup))
                        dispatch(
                          setSelectedTicketThumb(
                            sectionThumbnails[ticketGroup.section]
                          )
                        )
                        history.push(
                          `/checkout/${params.event_id}/${ticketGroup.id}/${ticketGroup.revision}/tickets?quantity=${selectedQuantity}`
                        )
                      }}
                      onMouseOver={(e) => {
                        zoomMap(ticketGroup)
                      }}
                      onMouseLeave={(e) => {
                        zoomMap()
                      }}
                    >
                      <div className={styles.ticketDetails}>
                        <div className={styles.ticketType}>
                          {ticketGroup?.section && (
                            <div className={styles.section}>
                              <span>Section</span>
                              <span className={styles.sectionNumber}>
                                {ticketGroup?.section}
                              </span>
                            </div>
                          )}
                          <div className={styles.divider} />
                          {ticketGroup?.row && (
                            <div className={styles.row}>
                              <span>Row</span>
                              <span className={styles.sectionNumber}>
                                {ticketGroup?.row}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.ticketInfo}>
                        <div className={styles.ticketActions}>
                          <p className={styles.priceDescription}>
                            <span className={styles.price}>
                              ${(ticketGroup.cost / 100).toFixed(2)}
                            </span>
                            ea.
                          </p>
                          {showInstantTags && (
                            <>
                              {ticketGroup?.poseventid ? (
                                <Tag
                                  className={styles.tag}
                                  variant="neutral"
                                  content="Tradable"
                                  icon={<Swap />}
                                />
                              ) : (
                                <Tag
                                  className={styles.tag}
                                  variant="secondary"
                                  content="Instant"
                                  icon={<Lightning />}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        <div className={styles.imageContainer}>
          {mapVendor ? (
            <div className={styles.venueImage}>
              {mapVendor === "3ddv" && (
                <SeatMap
                  config={mapConfig}
                  ticketGroups={filteredTicketGroups}
                  zoomedTicketGroup={zoomedTicketGroup}
                  highlightedSections={highlightedSections}
                  handleSectionHighlight={handleSectionHighlight}
                  handleSectionThumbLoaded={handleSectionThumbLoaded}
                  translationRules={event?.translations_3ddv}
                />
              )}
              {mapVendor === "tevo" && (
                <SeatMapTevo
                  config={mapConfig}
                  ticketGroups={filteredTicketGroups}
                  zoomedTicketGroup={zoomedTicketGroup}
                  highlightedSections={highlightedSections}
                  handleSectionHighlight={handleSectionHighlight}
                  handleSectionThumbLoaded={handleSectionThumbLoaded}
                />
              )}
            </div>
          ) : (
            <img
              src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/seatmap.png"
              alt="Placeholder "
              className={styles.venueImage}
            />
          )}
        </div>
      </div>
      <div className={styles.thumbnailContainer}></div>
    </Layout>
  )
}

export default EventPage
