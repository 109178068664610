import { Button, Input, Layout, Modal, CoinbasePay } from "../components"
import { useState, useEffect } from "react"
import { CloseCircle, Edit } from "../css/icons"
import styles from "./AccountPage.module.scss"
import { useWallet } from "@solana/wallet-adapter-react"
import { useSelector, useDispatch } from "react-redux"
import { updateUser, signOut } from "../reducers/userSlice"
import AccountSidebar from "./AccountSidebar"

const AccountPage = () => {
  const user = useSelector((state) => state.user?.user)
  let firstName = useSelector((state) => state.user?.user?.first_name)
  let lastName = useSelector((state) => state.user?.user?.last_name)
  let phone = useSelector((state) => state.user?.user?.phone)
  let email = useSelector((state) => state.user?.user?.email)
  const adapter = useWallet()
  const [first, setFirst] = useState("")
  const [last, setLast] = useState("")
  const [newPhone, setNewPhone] = useState("")
  const [newEmail, setNewEmail] = useState("")
  const [showDisconnectModal, setShowDisconnectModal] = useState(false)
  const [editing, setEditing] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (user?.firstName && !firstName) {
      firstName = user.firstName
    } else {
    }
  }, [user])

  const handleSubmit = () => {
    dispatch(
      updateUser({
        first_name: first || firstName,
        last_name: last || lastName,
        phone: newPhone || phone,
        email: newEmail || email,
        wallet: user?.publicKey?.toString(),
      })
    )

    setEditing(false)
  }

  return (
    <Layout>
      <div className={styles.pageContainer}>
        <AccountSidebar />
        <div className={styles.container}>
          <h1>Manage Account</h1>
          <div className={styles.contactInfoContainer}>
            <div className={styles.contactInfoHeader}>
              <div>
                <h2>Contact Info</h2>
                <p>
                  Ensure your information is accurate and matches your ID. This
                  ensures secure entry into events.
                </p>
              </div>
              {editing ? (
                <Button variant="blue" compact onClick={() => handleSubmit()}>
                  Save
                </Button>
              ) : (
                <Button
                  variant="gray"
                  rightIcon={<Edit />}
                  onClick={() => setEditing(true)}
                  compact
                >
                  Edit
                </Button>
              )}
            </div>
            <div className={styles.inputRow}>
              <div className={styles.inputContainer}>
                <div className={styles.inputLabel}>First Name</div>
                <Input
                  disabled={!editing}
                  name="firstName"
                  value={first || firstName}
                  onChange={(e) => setFirst(e.target.value)}
                  placeholder="First Name"
                />
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.inputLabel}>Last Name</div>
                <Input
                  disabled={!editing}
                  name="lastName"
                  value={last || lastName}
                  onChange={(e) => setLast(e.target.value)}
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.inputLabel}>Phone Number</div>
              <Input
                disabled={!editing || user?.loginMethod === "phone"}
                name="phone"
                value={newPhone || phone}
                onChange={(e) => setNewPhone(e.target.value)}
                placeholder="Enter your phone number"
              />
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.inputLabel}>Email Address</div>
              <Input
                disabled={!editing}
                name="email"
                value={newEmail || email}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Enter your email address"
              />
            </div>
          </div>
          <div className={styles.walletInfoContainer}>
            {(window.location.href.indexOf("ngrok") > -1 ||
              window.location.href.indexOf("alpha") > -1) && (
              <CoinbasePay
                destination={user?.publicKey}
                onSuccess={() => alert("wooop")}
              />
            )}

            <h2>My Wallet Address</h2>
            <div className={styles.walletAddress}>
              <span>{user?.publicKey?.toString()}</span>
              <Button
                variant="red"
                compact
                onClick={() => setShowDisconnectModal(true)}
              >
                Disconnect
              </Button>
            </div>
          </div>
        </div>
        {showDisconnectModal && (
          <Modal
            onClose={() => setShowDisconnectModal(false)}
            width="medium"
            containerClass={styles.modalContainer}
          >
            <div className={styles.disconnectModal}>
              <div className={styles.disconnectModalHeader}>
                <CloseCircle />
              </div>
              <h2>Disconnect your wallet?</h2>
              <p>
                Connect your wallet to securely store and manage your tickets
              </p>
              <p className={styles.box}>
                To access and receive purchased tickets you will need to
                reconnect your wallet.
              </p>
              <div className={styles.disconnectModalButtons}>
                <Button
                  variant="gray"
                  onClick={() => setShowDisconnectModal(false)}
                >
                  Close
                </Button>
                <Button
                  variant="red"
                  onClick={() => {
                    adapter?.disconnect()
                    setShowDisconnectModal(false)
                    if (user?.loginMethod === "phone") {
                      dispatch(signOut())
                    }
                  }}
                >
                  Disconnect Wallet
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </Layout>
  )
}

export default AccountPage
