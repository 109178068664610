import { useSelector } from "react-redux"
import classNames from "classnames"
import { useState, useEffect } from "react"
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"
import { Button, Input, Modal } from "../"
import RedeemLoading from "../../Redeem/RedeemLoading"
import { Bell, CloseCircle, Explosion, Phantom } from "../../css/icons"
import { Connection, Transaction } from "@solana/web3.js"
import styles from "./RedeemModal.module.scss"
import { Base64 } from "js-base64"
import { useWallet } from "@solana/wallet-adapter-react"
import {
  setConfig,
  Operator,
  TokenAccount,
  PDA,
  CNFT,
} from "@captainxyz/solana-core"
import { PublicKey } from "@solana/web3.js"
import postToSlack from "../../postToSlack"

const { formatCents } = require("../../helpers/money")

const RedeemModal = ({ onRedeemSucces, onClose }) => {
  const [keycode, setKeycode] = useState(null)
  const [error, setError] = useState(null)
  const [fragments, setFragments] = useState(null)
  const [loading, setLoading] = useState(null)
  const [txn, setTxn] = useState(null)

  const user = useSelector((state) => state.user.user)
  const { sendTransaction } = useWallet()

  useEffect(() => {
    getFragments()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getFragments = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/promo-dispensers`
    let resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    resp = await resp.json()
    setFragments(resp.fragments)
    console.log("Fragments", resp.fragments)
  }

  const getTxn = async () => {
    let params = {
      account: user.publicKey.toString(),
      claimKey: keycode,
    }
    for (let i = 0; i < fragments.length; i++) {
      let FRAGMENT = fragments[i]
      let url = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/${FRAGMENT}`
      try {
        const res = await fetch(url, {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            "Content-Type": "application/json",
          },
        })

        const connection = new Connection(process.env.REACT_APP_RPC) // eslint-disable-line no-unused-vars
        const data = await res.json()
        const txEncoded = data.transaction
        const tx = Transaction.from(Base64.toUint8Array(txEncoded))
        window.FRAGMENT = FRAGMENT
        return tx
      } catch (err) {
        console.log("error", err)
      }
    }
    return false
  }

  const recordKeycodeUsed = async () => {
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/${window.FRAGMENT}/redeemed`
    let params = {
      claimKey: keycode,
      account: user.publicKey.toString(),
    }
    let resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    })
    resp = await resp.json()
    let mint = new PublicKey(resp.address)

    let address = PDA.token(
      mint,
      new PublicKey(user.publicKey.toString()) //oracle
    )

    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })

    const operator = new Operator("mainnet-beta")

    const sleep = (time) =>
      new Promise((res) => setTimeout(res, time, "done sleeping"))

    let loadToken = async () => {
      try{
        try {
          let token = await TokenAccount.init(operator, address)
          return token
        } catch (err) {
          let cnft = await CNFT.init(operator, {
            address: mint,
            owner: user.publicKey,
          })
          token = {
            pk: cnft.address.toString(),
            mint: {
              address: cnft.address,
            },
            metadata: {
              _json: cnft.json,
              json: cnft.json,
            },
          }
          return token
        }
      } catch (err) {
        return false
      }
    }



    let token
    for (let i = 0; i < 20; i++) {
      token = await loadToken()
      if (token) break
      else await sleep(1000)
    }

    let discount = token.metadata._json.discount
    //setDiscount(formatCents(discount))
    //setExpiration(token.metadata._json.expiration)
    //setMinimum(formatCents(token.metadata._json.minimum))
  }

  const phoneDispenser = async () => {
    setLoading(true)
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/couponDispenser`
    let params = {
      diner_uuid: user.diner_uuid,
      claimKey: keycode,
    }

    for (let i = 0; i < fragments.length; i++) {
      let FRAGMENT = fragments[i]
      let dispenserUrl = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/${FRAGMENT}`
      try {
        params["url"] = dispenserUrl
        let resp = await fetch(url, {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            "Content-Type": "application/json",
          },
        })
        resp = await resp.json()
        if (resp.success) {
          let msg = `had success with keycode kc:${keycode} on ${FRAGMENT}`
          postToSlack(msg, "dispenser", user)
          //setLoading(false)
          //setShowDispenserSuccess(true)
          window.FRAGMENT = FRAGMENT
          recordKeycodeUsed()
          onRedeemSucces()
          return
        } else {
          setError(true)
        }
      } catch (err) {
        setError(true)
      }
    }
    setError(true)
    setLoading(false)
    let msg = `Had a fail or invalid keycode  with keycode kc:${keycode} on ${window.FRAGMENT}`
    postToSlack(msg, "dispenser", user)
  }

  const dispenser = async () => {
    setError(false)
    let msg = `is trying kc:${keycode}`
    postToSlack(msg, "dispenser", user)

    if (user?.loginMethod === "phone") {
      return phoneDispenser()
    }

    setLoading(true)
    let txn
    try {
      txn = await getTxn()
      if (!txn) {
        setError(true)
        setLoading(false)
        return
      }
    } catch (err) {
      setError(true)
      setLoading(false)
      return
    }

    const connection = new Connection(process.env.REACT_APP_RPC)

    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext()

    try {
      const signature = await sendTransaction(txn, connection, {
        minContextSlot,
      })
      let result = await connection.confirmTransaction(
        {
          blockhash,
          lastValidBlockHeight,
          signature,
        },
        "confirmed"
      )
      //setLoading(false)
      //setShowDispenserSuccess(true)
      recordKeycodeUsed()
      onRedeemSucces()
      msg = `had success with kc:${keycode} on ${window.FRAGMENT}`
      postToSlack(msg, "dispenser", user)
    } catch (err) {
      setLoading(false)
      msg = `had an error or deny with kc:${keycode} on ${
        window.FRAGMENT
      } - ${err.toString()}`
      postToSlack(msg, "dispenser", user)
      console.log("Dispenser or wallet error", err)
      setError(true)
    }
  }

  const closeModal = () => {
    document.body.style.overflow = "auto"
    onClose()
  }

  console.log("error is", error)

  return (
    <Modal
      onClose={() => {
        closeModal()
      }}
      width="medium"
      containerClass={styles.modalContainer}
    >
      <div className={styles.signInModal}>
        {loading ? (
          <RedeemLoading
            useOneOffCopy={window.location.pathname.includes("decrypt")}
          />
        ) : (
          <>
            <div className={styles.modalHeader}>
              <CloseCircle
                onClick={() => {
                  document.body.style.overflow = "auto"
                  onClose()
                }}
              />
            </div>
            <h1>apply a rewARd</h1>

            {error ? (
              <div
                className={classNames(styles.subHeader, styles.subHeaderError)}
              >
                Invalid code. Check your code and try entering it again.
              </div>
            ) : (
              <div className={styles.subHeader}>
                Add your promo code below or apply a reward already in your
                wallet.
              </div>
            )}

            <div className={styles.label}>ADD PROMO CoDE</div>
            <Input
              className={styles.input}
              disableAutocomplete={true}
              placeholder="Enter Code"
              value={keycode}
              onChange={(e) => setKeycode(e.target.value)}
            ></Input>
            <div>Invalid Keycode</div>
            <Button
              fullWidth
              variant="blue"
              disabled={!(keycode && keycode.length >= 6 && fragments.length)}
              className={styles.modalButton}
              onClick={dispenser}
            >
              USE PROMO CODE
            </Button>
          </>
        )}
      </div>
    </Modal>
  )
}

export default RedeemModal
