import styles from "./ToggleSwitch.module.scss"
import classNames from "classnames"

const ToggleSwitch = ({ onChange, value }) => (
  <label class={styles.switch}>
    <input type="checkbox" value={value} onChange={() => onChange()} />
    <span
      class={classNames(styles.slider, value && styles.sliderActive)}
    ></span>
  </label>
)

export default ToggleSwitch
