import { useEffect, useState } from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Button, Layout, NFTListItem, SegmentedFilter } from "../components"
import { Unavailable } from "../css/icons"
import NoNFTs from "./NoNFTs"
import styles from "./NFTs.module.scss"
import { getTokenAccountsForUser } from "../helpers/getTokenAccounts"

import AccountSidebar from "../Account/AccountSidebar"

require("@solana/wallet-adapter-react-ui/styles.css")

const HolderNFTs = () => {
  const [loading, setLoading] = useState(false)
  const [canDecryptList, setCanDecryptList] = useState([])
  const [canDecryptListPast, setCanDecryptListPast] = useState([])
  const [selected, setSelected] = useState("Upcoming")
  const [hasNoNfts, setHasNoNFTs] = useState(false)
  const adapter = useWallet()
  const user = useSelector((state) => state.user.user)

  useEffect(() => {
    window.GETTING_ACCOUNTS = false
    if (adapter.publicKey) {
      getTokenAccounts()
    }

    if (user?.publicKey) {
      getTokenAccounts()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (adapter.publicKey) {
      getTokenAccounts()
    }
  }, [adapter]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?.publicKey) {
      getTokenAccounts()
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const getTokenAccounts = async () => {
    if (window.GETTING_ACCOUNTS) return
    window.GETTING_ACCOUNTS = true
    setLoading(true)
    await getTokenAccountsForUser(adapter, user).then(
      ({ decryptList, decryptListPast }) => {
        setCanDecryptList(decryptList)
        setCanDecryptListPast(decryptListPast)
        window.GETTING_ACCOUNTS = false
        setLoading(false)
        if (!decryptList.length && !decryptListPast.length) {
          setHasNoNFTs(true)
        }
      }
    )
  }

  return (
    <>
      <Layout>
        <div className={styles.pageContainer}>
          {/* reused with account page */}
          <AccountSidebar />
          {loading ? (
            <div className={styles.loadingContainer}>
              <img
                src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
                className={styles.spinner}
                alt="spinner"
              />
              <div
                style={{ marginTop: "32px" }}
                className={styles.loadingSubtext}
              >
                Scanning your wallet for Tamperproof NFTs
              </div>
              <div
                style={{ marginTop: "8px" }}
                className={styles.loadingSubtext}
              >
                If you have a large NFT collection, this will take a moment
              </div>
            </div>
          ) : (
            <div className={styles.container}>
              <div className={styles.header}>
                <h1>My Tickets</h1>
              </div>

              <div>
                {hasNoNfts ? (
                  <NoNFTs />
                ) : (
                  <>
                    <SegmentedFilter
                      fullWidth
                      options={["Upcoming", "History"]}
                      selected={selected}
                      onSelect={(option) => setSelected(option)}
                    />
                    {selected === "Upcoming" ? (
                      <>
                        <div className={styles.nftContainer}>
                          {canDecryptList.length > 0 ? (
                            <>
                              {canDecryptList.map((nft, i) => (
                                <NFTListItem
                                  key={nft.mint.address.toString()}
                                  nft={nft}
                                />
                              ))}
                            </>
                          ) : (
                            <div className={styles.noUpcoming}>
                              <Unavailable />
                              <p>You have no upcoming events</p>
                              <Link to="/events">
                                <Button variant="beige">
                                  Explore Concerts
                                </Button>
                              </Link>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.nftContainer}>
                          {canDecryptListPast.map((nft, i) => (
                            <NFTListItem
                              key={nft.mint.address.toString()}
                              nft={nft}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  )
}

export default HolderNFTs
