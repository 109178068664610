import classNames from "classnames"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import styles from "./Layout.module.scss"

const Layout = ({
  children,
  className,
  velvetHeader,
  contentClassName,
  noScroll,
  showHeader = true,
}) => (
  <div className={classNames(styles.pageContainer, className)}>
    <div className={styles.pageBackground} />
    <div>
      {showHeader && <Header velvetHeader={velvetHeader} />}
      <div
        className={classNames(
          styles.contentContainer,
          contentClassName,
          noScroll && styles.noScroll
        )}
        id="layoutContainer"
      >
        {children}
        <Footer />
      </div>
    </div>
  </div>
)

export default Layout
